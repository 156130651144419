import { getLocalStorageUserData, isAutoDashboard } from "./commonUtils";

const checkDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

export const openDB = () => {
  if (!checkDB) {
    return false;
  }
  const request = checkDB.open("myDatabase", 1);

  request.onerror = (event) => {
    console.error("Database error:", event.target.error);
  };

  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    db.createObjectStore("myStore", { keyPath: "id" });
  };

  request.onsuccess = () => {
    // const db = event.target.result;
    // console.log("Database opened successfully", db);
  };
  return true;
};

export const addData = (data) => {
  if (isAutoDashboard()) {
    const userData = getLocalStorageUserData();
    const key = userData?.currentUser?.id;
    const request = checkDB.open("myDatabase", 1);

    request.onerror = (event) => {
      console.log("Add data error:", event.target.error);
    };
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction(["myStore"], "readwrite");
      const store = transaction.objectStore("myStore");
      const addDataToDB = store.put({ id: key, data });
      addDataToDB.onsuccess = () => {
        transaction.oncomplete = () => {
          db.close();
        };
      };
    };
  }
};

export const addDataToDb = (key, data) => {
  const userData = getLocalStorageUserData();
  const id = userData?.currentUser?.id;
  const request = checkDB.open("myDatabase", 1);

  request.onerror = (event) => {
    console.log("Add data error:", event.target.error);
  };
  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction(["myStore"], "readwrite");
    const store = transaction.objectStore("myStore");

    // Get the existing data associated with the key
    const getRequest = store.get(id);

    getRequest.onsuccess = () => {
      const existingData = getRequest.result || {}; // Default to an empty object if no data found
      existingData[key] = data; // Append or update the data
      const addDataToDB = store.put({ id, ...existingData });
      addDataToDB.onsuccess = () => {
        transaction.oncomplete = () => {
          db.close();
        };
      };
    };
  };
};

export const getDataFromDb = (key, stateFunction) => {
  const request = checkDB.open("myDatabase", 1);
  const userData = getLocalStorageUserData();
  const id = userData?.currentUser?.id;
  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction(["myStore"], "readonly");
    const store = transaction.objectStore("myStore");

    const getRequest = store.get(id);
    getRequest.onerror = (event) => {
      console.error("Get data error:", event.target.error);
    };
    getRequest.onsuccess = (event) => {
      const data = event.target.result;
      stateFunction(data?.[key]);
      transaction.oncomplete = () => {
        db.close();
      };
    };
  };
  request.onerror = (event) => {
    console.error("Database error:", event.target.error);
  };
};

export const getData = (key, stateFunction) => {
  const request = checkDB.open("myDatabase", 1);
  request.onsuccess = () => {
    const db = request.result;
    const transaction = db.transaction(["myStore"], "readonly");
    const store = transaction.objectStore("myStore");

    const getRequest = store.get(key);
    getRequest.onerror = (event) => {
      console.error("Get data error:", event.target.error);
    };
    getRequest.onsuccess = (event) => {
      const data = event.target.result;
      let flag = false;
      data?.data?.forEach((element) => {
        if (element?.value?.length > 0) {
          flag = true;
        }
      });
      stateFunction(flag ? data?.data : null);
      transaction.oncomplete = () => {
        db.close();
      };
    };
  };
  request.onerror = (event) => {
    console.error("Database error:", event.target.error);
  };
};

// export const deleteWholeDatabase = () => {
//   const request = checkDB.deleteDatabase("myDatabase");
//   request.onerror = (event) => {
//     console.error("Error deleting database:", event.target.error);
//   };

//   request.onsuccess = () => {
//     console.log("Database deleted successfully");
//   };
//   // };
// };

export const deleteData = () => {
  if (isAutoDashboard()) {
    const userData = getLocalStorageUserData();
    const key = userData?.currentUser?.id;
    const request = checkDB.open("myDatabase", 1);
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction(["myStore"], "readwrite");
      const store = transaction.objectStore("myStore");
      const getRequest = store.delete(key);
      getRequest.onerror = () => {
        console.error("Get data error:");
      };
      getRequest.onsuccess = () => {
        transaction.oncomplete = () => {
          db.close();
        };
      };
    };
    request.onerror = (event) => {
      console.error("Delete data error:", event.target.error);
    };
  }
};
