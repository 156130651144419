/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useRef } from "react";
import FormControl from "@material-ui/core/FormControl";
import { ReactComponent as FilterIcon } from "../../../Assets/images/FilterIcon.svg";
import { ReactComponent as AppliedFilterIcon } from "../../../Assets/images/applied-filter.svg";
import "./style.scss";
import useDetectOutsideClick from "../../../CustomHooks/useDetectOutsideClick";
import { Infoicon } from "../../../Constants/icons";
import { removeDuplicates } from "../../../Utils/commonUtils";
import useFilterModalPosition from "../../../CustomHooks/useGetelementPosition";
import FilterModalPortal from "./filterportals";

function MultipleSelect(props) {
  const {
    options,
    filterSelected,
    filterSelectedVariableName,
    dataName,
    dataNameCopy,
    filtersVariable,
    changeHandlerFIlter,
    cancelFilter,
    filterData,
    onIncludeChange,
    filteredDataVariableName,
    name,
    filterId = "",
    showClearFilterSeparately = false,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedListFilter, setselectedListFilter] = useState([]);
  const [optionsList, setOptionsList] = useState([]);
  const [filterModalPosition, setFilterModalPosition] = useState(170);
  const [filterModalPositionX, setFilterModalPositionX] = useState(0);

  const [selectedList, setselectedList] = useState([]);
  const deepCopy = (arr) => JSON.parse(JSON.stringify(arr));

  const ref = useRef();

  const onCancelHandler = () => {
    setOpen(false);
    cancelFilter(deepCopy(selectedList), filterSelectedVariableName, filtersVariable);
    setselectedListFilter(deepCopy(selectedList)[0].value);
    setSearchText("");
    setOptionsList(options);
  };

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    if (open) {
      onCancelHandler();
    }
  });

  const getSelectedArrayFirst = (searchedOption, selectedOption) => {
    const array1 = [...searchedOption];
    const array2 = [...selectedOption];

    const newArray = [
      ...array1.filter((value) => array2.includes(value)),
      ...array1.filter((item) => !array2?.includes(item)),
    ];
    setOptionsList(newArray);
  };

  useEffect(() => {
    if (!filterSelected[0]?.value?.length === selectedList[0]?.value.length) {
      setselectedList(deepCopy(filterSelected));
    }
    if (filterSelected[0]?.value?.length === 0) {
      setselectedListFilter([]);
      setselectedList(JSON.parse(JSON.stringify(filterSelected)));
    }
  }, [filterSelected]);

  useEffect(() => {
    const newData = deepCopy(filterSelected);
    setselectedList(newData);
    getSelectedArrayFirst(options, selectedList);
  }, [open]);

  useEffect(() => {
    setselectedList(deepCopy(filterSelected));
  }, [filterSelected[0]?.filterKey]);

  const handleChecked = (option, list = []) => {
    if (list[0]?.value && list[0]?.value.includes(option)) {
      return true;
    }
    return false;
  };

  const handleInputChange = (event) => {
    const {
      target: { value },
    } = event;
    setSearchText(value);
    const updatedList = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setOptionsList(updatedList);
    getSelectedArrayFirst(updatedList, selectedListFilter);
  };

  const handleOpen = (event) => {
    setOpen(true);

    const { positionX, positionY } = useFilterModalPosition(event, filterId);
    setFilterModalPosition(positionY);
    setFilterModalPositionX(positionX);
  };

  const arraysHaveSameItems = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }
    return array1.every((element) => array2.includes(element));
  };

  const renderSelectedItem = (item, index) => {
    if (index <= 1) {
      return <span className="tags">{item}</span>;
    }
    if (index === 2) {
      return <span className="tags_length">{`+${filterSelected[0].value.length - 2}`}</span>;
    }
    return null;
  };

  const checkboxClickHandler = (event) => {
    const {
      target: { value, checked },
    } = event;

    if (value === "select all") {
      let selectedArray = [...optionsList];

      if (arraysHaveSameItems(selectedListFilter, optionsList) || (searchText !== "" && !checked)) {
        const updatedSelectedArray = removeDuplicates([...selectedListFilter, ...optionsList]);
        selectedArray = updatedSelectedArray.filter(
          (optionItem) => !selectedArray.includes(optionItem)
        );
      } else {
        selectedArray = removeDuplicates([...optionsList, ...selectedListFilter]);
      }

      setselectedListFilter(selectedArray);
      changeHandlerFIlter(
        selectedArray,
        name,
        dataName,
        filterSelectedVariableName,
        filtersVariable,
        dataNameCopy,
        searchText
      );
    } else if (selectedListFilter.filter((item) => item === value).length) {
      const selectedArray = [...selectedListFilter];
      selectedArray.splice(selectedArray.indexOf(value), 1);
      setselectedListFilter(selectedArray);
      changeHandlerFIlter(
        selectedArray,
        name,
        dataName,
        filterSelectedVariableName,
        filtersVariable,
        dataNameCopy,
        searchText
      );
      getSelectedArrayFirst(optionsList, selectedArray);
    } else {
      const selectedArray = [...selectedListFilter, value];
      setselectedListFilter(selectedArray);
      changeHandlerFIlter(
        selectedArray,
        name,
        dataName,
        filterSelectedVariableName,
        filtersVariable,
        dataNameCopy,
        searchText
      );
      getSelectedArrayFirst(optionsList, selectedArray);
    }
  };

  const onApplyHandler = () => {
    setOpen(false);
    setselectedList(deepCopy(filterSelected));
    filterData(
      null,
      null,
      dataName,
      dataNameCopy,
      filtersVariable,
      filterSelectedVariableName,
      dataNameCopy,
      searchText
    );
    setSearchText("");
    setOptionsList(options);
  };

  const onSelectChange = (e) => {
    const {
      target: { value },
    } = e;
    setOpen(true);
    onIncludeChange(
      name,
      value,
      dataName,
      filterSelectedVariableName,
      filtersVariable,
      dataNameCopy,
      filteredDataVariableName
    );
  };

  const handleDisabled = (option, selected) =>
    option.every((optionItem) => selected.includes(optionItem));

  return (
    <FormControl size="small">
      {open ? (
        <>
          {selectedListFilter.length && open ? (
            <AppliedFilterIcon className="filter_cursor" />
          ) : (
            <FilterIcon className="filter_cursor" />
          )}
          <FilterModalPortal>
            <div className="overlay" />

            <div
              ref={ref}
              style={{
                top: `${filterModalPosition + 5}px` || "170px",
                left: `${filterModalPositionX - 20}px` || "0px",
              }}
              className={`${open ? "active_filter_multiselect" : ""} open_filters`}
            >
              {filterSelected[0]?.value.length ? (
                <div className="Selected_list">
                  {filterSelected[0]?.value
                    ?.slice(0, 3)
                    .map((item, index) => renderSelectedItem(item, index))}
                </div>
              ) : null}
              <div className="filterInputContainer">
                <input
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  id="inputfiltered"
                  value={searchText}
                  defaultValue={searchText}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  className="search_filter"
                  placeholder="Search..."
                />
              </div>
              <div
                className={`select_box_container ${
                  showClearFilterSeparately && !!filterSelected[0]?.value.length
                    ? "ck-filter-mode-selector--wrapper"
                    : ""
                }`}
              >
                {showClearFilterSeparately && !!filterSelected[0]?.value.length && (
                  <button
                    className="ck-filter--clear"
                    type="button"
                    value="Clear Filter"
                    onClick={(e) => {
                      onSelectChange(e);
                      onApplyHandler();
                    }}
                  >
                    Clear Filter
                  </button>
                )}
                {optionsList.length ? (
                  <select
                    onChange={(e) => {
                      onSelectChange(e);
                    }}
                    value={filterSelected[0]?.filterKey}
                    disabled={options.length === filterSelected[0]?.value.length}
                  >
                    {!showClearFilterSeparately && (
                      <option value="Clear Filter">Clear Filter</option>
                    )}
                    <option value="Include Only">Include Only</option>
                    <option value="Exclude Only">Exclude Only</option>
                  </select>
                ) : null}
              </div>
              <div className="scrollable_section">
                {optionsList.length ? (
                  <label className="checkbox_container" key="select all">
                    <p className="checkbox_label">Select All</p>
                    <input
                      type="checkbox"
                      checked={handleDisabled(optionsList, selectedListFilter)}
                      value="select all"
                      name={name}
                      onChange={(e) => {
                        checkboxClickHandler(e);
                      }}
                    />
                    <span className="checkmark" />
                  </label>
                ) : null}
                {optionsList.length ? (
                  optionsList.map((option) => (
                    <label className="checkbox_container" key={option}>
                      <p className="checkbox_label" title={option}>
                        {option}
                      </p>
                      <input
                        type="checkbox"
                        checked={handleChecked(option, filterSelected)}
                        value={option}
                        name={name}
                        onChange={(e) => {
                          checkboxClickHandler(e);
                        }}
                      />
                      <span className="checkmark" />
                    </label>
                  ))
                ) : (
                  <div className="filter_no_data_wrapper">
                    <div className="info_icon">
                      <Infoicon />
                      <span className="no-data-label text-[13px] mb-4 text-[#0A3CA2]">
                        No Data Found
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {optionsList.length ? (
                <div className="filters_options">
                  <button
                    type="button"
                    className="button outline-primary medium ml-[14px] rounded"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="button primary medium ml-[8px] rounded"
                    onClick={() => onApplyHandler()}
                  >
                    Apply
                  </button>
                </div>
              ) : null}
            </div>
          </FilterModalPortal>
        </>
      ) : (
        <div onClick={(e) => handleOpen(e)} aria-hidden>
          {filterSelected[0]?.value?.length ? <AppliedFilterIcon /> : <FilterIcon />}
        </div>
      )}
    </FormControl>
  );
}

export default MultipleSelect;
