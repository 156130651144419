/* eslint-disable import/no-cycle */
import moment from "moment";
import React from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import cloneDeep from "lodash.clonedeep";
import { Checkbox } from "@mui/material";
import ListPopup from "../../Common/ListPopup";
import noImage from "../../../Assets/icons/no-image.png";

// import MavPopup from "./MavPopup";
import TextEditModal from "../../CK-Lens/Common/TextEditModal";
import Routes from "../../../Config/Routes";
import Tag, { InUse } from "../../Common/Tag";
import {
  getCurrencySymbol,
  getLocalStorageUserData,
  // getLocalStorageUserData,
  isAutoDashboard,
  isBilldeskModule,
  // isBilldeskRO,
} from "../../../Utils/commonUtils";
import HoverText from "../../CK-Auto/Common/HoverText";
import Journey from "../../CK-Auto/Common/Journey";
import { descriptionTooltip, CustomerStatusHistoryTooltip } from "../ConfigDiscounts/util";
import TooltipV2 from "../../Common/TooltipV2";
import { renderEdpDescriptionTooltip } from "../CustomerManagement/CreateCustomer/utils";
import { ReactComponent as EyeIcon } from "../../../Assets/icons/eye-active.svg";

// import commonService from "../../../Services/commonService";
import store from "../../../store";

const actions = [
  {
    actionType: "toggle",
    class: "switch-icon",
    disabled: false,
    props: {
      size: "small",
      defaultChecked: true,
    },
  },
  {
    actionType: "delete",
    class: "delete-icon",
  },
  {
    actionType: "edit",
    class: "edit-icon",
  },
  {
    actionType: "preview",
    class: "edit-icon",
  },
  {
    actionType: "editRevamp",
    class: "edit-icon",
  },
  {
    actionType: "clone",
    class: "edit-icon",
  },
  {
    actionType: "promote",
    class: "promote-icon",
    disabled: false,
  },
  {
    actionType: "promoteRevamp",
    class: "promote-icon",
    disabled: false,
  },
  {
    actionType: "resendVerification",
    class: "resend-verification-icon",
    disabled: false,
  },
  {
    actionType: "resendVerificationRevamp",
    class: "resend-verification-icon",
    disabled: false,
  },
  {
    actionType: "changePlan",
    class: "resend-verification-icon !ml-[7px]",
    disabled: false,
    overwritePermissions: true,
  },
  {
    actionType: "showAgreement",
    class: "resend-verification-icon",
    disabled: false,
  },
  {
    actionType: "refresh",
    class: "resend-verification-icon refresh-btn ml-[10px]",
    disabled: false,
    text: "Data Refresh",
  },
  {
    actionType: "apply",
    class: "apply-btn",
    disabled: false,
    text: "Apply",
  },
  {
    actionType: "details",
    class: "edit-icon",
  },
];

export const getActions = (showToggle, item, showAction) =>
  actions
    .map((action) => {
      if (action.actionType === "toggle") {
        if (item?.userRoleType?.toUpperCase() === "OWNER") {
          const actionClone = JSON.parse(JSON.stringify(action));
          actionClone.disabled = true;
          return actionClone;
        }
        if (showToggle) {
          const actionClone = JSON.parse(JSON.stringify(action));
          actionClone.props.defaultChecked = item.enable;
          return actionClone;
        }
        return null;
      }

      if (action.actionType === "promote" || action.actionType === "promoteRevamp") {
        if (item?.userRoleType?.toUpperCase() === "OWNER") {
          const actionClone = JSON.parse(JSON.stringify(action));
          actionClone.disabled = true;
          return actionClone;
        }
        return action;
      }
      return action;
    })
    .filter((action) => !!showAction.includes(action?.actionType));
const getSpecifiedActions = (item) => {
  if (!item.systemGenerated) return ["delete", "edit"];
  return [];
};

export const isPromoteButtonAvailable = () => {
  const additionalButtons = store.getState()?.sidebarReducer.additionalButtons;
  const isPromoteButton = additionalButtons?.find((button) => button?.key === "PROMOTE_USER");
  return isPromoteButton;
};

export const isAddCustomerButtonAvailable = () => {
  const additionalButtons = store.getState()?.sidebarReducer.additionalButtons;
  const isAddCustomerButton = additionalButtons?.find(
    (button) => button?.key === "ADD_NEW_CUSTOMER"
  );
  return isAddCustomerButton;
};

const populateActions = (
  apiResponse,
  showToggle,
  S3_BASE_URL,
  logoAccessor,
  logoNameKey,
  tableName
) => {
  const userData = getLocalStorageUserData();
  const role = userData?.currentUser?.userRoleType;
  const isPromoteButton = isPromoteButtonAvailable();
  apiResponse.forEach((item) => {
    const clone = item;
    if (S3_BASE_URL) {
      clone[logoNameKey] = (
        <div className="loadingAnimation animating">
          <img
            className={`logo-image hide ${!clone[logoAccessor] && "!h-[32px]"}`}
            alt="partner_logo"
            data-loaded={item.loaded}
            src={
              clone[logoAccessor]
                ? `${S3_BASE_URL + clone[logoAccessor]}?timestamp=${new Date().getTime()}`
                : noImage
            }
            onLoad={(event) => {
              event.target.parentNode.classList.remove("animating");
              event.target.classList.remove("hide");
            }}
          />
        </div>
      );
    }

    let actionsClone = [];
    if (tableName === "mavTable") {
      actionsClone = getSpecifiedActions(item);
    } else if (tableName === "userPreference" || tableName === "userManagement") {
      let editIcon = "edit";
      let promoteIcon = "promote";
      let resendIcon = "resendVerification";

      if (tableName === "userPreference") {
        editIcon = "editRevamp";
        promoteIcon = "promoteRevamp";
        resendIcon = "resendVerificationRevamp";
      }

      if (isPromoteButton) {
        actionsClone = [editIcon, "toggle", promoteIcon, resendIcon];
      } else {
        actionsClone = [editIcon, "toggle", resendIcon];
      }

      if (role.toLowerCase() !== "owner" && item.userRoleType?.toLowerCase() === "owner") {
        actionsClone = [];
      }
    } else if (tableName === "customerTable") {
      actionsClone = ["edit", "delete"];
    } else {
      actionsClone = ["edit", "delete", "toggle"];
    }
    clone.actions = getActions(showToggle, clone, actionsClone);
  });
};

const convertDateRole = (apiResponse, changeRole = false, userType = "CUSTOMER") =>
  apiResponse.map((item) => {
    const itemClone = item;

    if (changeRole) {
      itemClone.role = item?.roles?.map((_) => _.name);
      /** showing user tags roles
       * only in partner and cln
       * */
      if (userType !== "CUSTOMER") {
        itemClone.role = [
          ...new Set([
            ...(itemClone.role || []),
            ...(item?.tags?.map((tag) => tag?.roles?.map((_) => _.name))?.flat() || []),
          ]),
        ];
      } else {
        itemClone.role = item?.roles?.[0]?.name || "";
      }
    }

    if (itemClone.lastLoginTime)
      itemClone.lastLoginTime = moment(itemClone.lastLoginTime).format("DD/MM/YYYY HH:mm A");
    else {
      itemClone.lastLoginTime = "";
    }

    if (itemClone.accountCreationTime)
      itemClone.accountCreationTime = moment(itemClone.accountCreationTime).format(
        "DD/MM/YYYY HH:mm A"
      );
    else {
      itemClone.accountCreationTime = "";
    }
    return itemClone;
  });

const getUserRoleConfig = (userType) => {
  if (userType === "CUSTOMER") {
    return {
      Header: "Role",
      accessor: "role",
      sortedIcon: true,
    };
  }

  return {
    Header: "Roles",
    accessor: "role",
    bodyClass: "!font-medium !text-[12px] !text-left",
    headerClass: "!text-left",
    component: ListPopup,
    array: true,
    props: {
      showTags: true,
      V2: true,
      dataCount: 3,
    },
  };
};

export const getUserTable = (onActionClickHandler, apiResponse, isPreferences, userType) => {
  populateActions(
    apiResponse,
    true,
    null,
    null,
    null,
    isPreferences ? "userPreference" : "userManagement"
  );
  const headers = [
    {
      Header: "First Name",
      accessor: "firstName",
      sortedIcon: true,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortedIcon: true,
    },
    {
      Header: "Email ID",
      accessor: "email",
      bodyClass: "email",
      sortedIcon: true,
    },
    getUserRoleConfig(userType),
    {
      Header: "Last Login",
      accessor: "lastLoginTime",
      hideFilters: true,
      sortedIcon: true,
      bodyClass: "lastLoginTime",
      headerClass: "w-[140px]",
    },
  ];

  if (isPreferences) {
    headers.push({
      Header: "Multi Account View",
      accessor: "mavNames",
      component: ListPopup,
      array: true,
      bodyClass: "!text-[13px] !font-medium tracking-[.14px]",
      props: {
        showTags: true,
        V2: true,
        dataCount: 3,
      },
    });
  }
  headers.push({
    Header: "Actions",
    accessor: "actions",
    icons: true,
  });

  return {
    onIconClick: onActionClickHandler,
    headers,
    data: convertDateRole(apiResponse, true, userType),
  };
};
export const getCustomerUserTable = (onActionClickHandler, apiResponse) => {
  const headers = [
    {
      Header: "First Name",
      accessor: "firstName",
      sortedIcon: true,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      sortedIcon: true,
    },
    {
      Header: "Email",
      accessor: "email",
      bodyClass: "email",
      sortedIcon: true,
    },
    {
      Header: "Customer Name",
      accessor: "customerName",
      sortedIcon: true,
    },
    // {
    //   Header: "Customer Payer Account ID",
    //   accessor: "payerAccountId",
    //   component: ListPopup,
    //   array: true,
    //   sortedIcon: true,
    //   bodyClass: "!text-left",
    // },
    {
      Header: "Role",
      accessor: "role",
      sortedIcon: true,
    },
    {
      Header: "Creation Time",
      accessor: "accountCreationTime",
      sortedIcon: true,
    },
    {
      Header: "Current Status",
      accessor: "lastActivityType",
      sortedIcon: true,
      bodyClass: "lastLoginTime",
    },
    {
      Header: "Last Login Time",
      accessor: "lastLoginTime",
      sortedIcon: true,
      hideFilters: true,
      bodyClass: "lastLoginTime",
      headerClass: "w-[140px]",
    },
  ];

  return {
    onIconClick: onActionClickHandler,
    headers,
    data: convertDateRole(apiResponse, true),
  };
};

export const getPartnerTable = (onActionClickHandler, apiResponse, S3_BASE_URL) => {
  const apiResponseClone = apiResponse;
  apiResponse.forEach((item) => {
    const itemClone = item;
    itemClone.cloudonomicsDomainClone = itemClone.cloudonomicsDomain;
  });
  populateActions(apiResponseClone, false, S3_BASE_URL, "logoName", "logo");

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Logo",
        accessor: "logo",
        headerClass: "!text-center",
      },
      {
        Header: "Partner Name",
        accessor: "name",
        bodyClass: "!font-medium !text-[12px]",
        sortedIcon: true,
      },
      {
        Header: "URL",
        accessor: "cloudonomicsDomainClone",
        bodyClass: "email !font-medium !text-[12px]",
        sortedIcon: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponseClone,
  };
};

export const getPermissionsTable = (onActionClickHandler, apiResponse, S3_BASE_URL) => {
  const apiResponseClone = apiResponse;
  populateActions(apiResponseClone, false, S3_BASE_URL, null, null, "permission_listing");

  if (window?.location?.pathname.includes("preferences")) {
    return {
      onIconClick: onActionClickHandler,
      headers: [
        {
          Header: "Permission Name",
          accessor: "name",
          bodyClass: "email !font-medium !text-[12px]",
          sortedIcon: true,
        },
        {
          Header: "Access Level",
          accessor: "accessLevel",
          bodyClass: "!font-medium !text-[12px]",
          sortedIcon: true,
        },
        {
          Header: "Actions",
          accessor: "actions",
          icons: true,
        },
      ],
      data: apiResponseClone,
    };
  }

  if (window?.location?.pathname.includes("preferences")) {
    return {
      onIconClick: onActionClickHandler,
      headers: [
        {
          Header: "Permission Name",
          accessor: "name",
          bodyClass: "email !font-medium !text-[12px]",
          sortedIcon: true,
        },
        {
          Header: "Access Level",
          accessor: "accessLevel",
          bodyClass: "!font-medium !text-[12px]",
          sortedIcon: true,
        },
        {
          Header: "Actions",
          accessor: "actions",
          icons: true,
        },
      ],
      data: apiResponseClone,
    };
  }

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Permission Name",
        accessor: "name",
        bodyClass: "email !font-medium !text-[12px]",
        sortedIcon: true,
      },
      {
        Header: "Access Level",
        accessor: "accessLevel",
        bodyClass: "!font-medium !text-[12px]",
        sortedIcon: true,
      },
      {
        Header: "Flow Type",
        accessor: "flowType",
        bodyClass: "!font-medium !text-[12px]",
        sortedIcon: true,
      },
      {
        Header: "Switch Access",
        accessor: "switchAccess",
        bodyClass: "!font-medium !text-[12px]",
        sortedIcon: true,
      },
      // {
      //   Header: "Modules Assigned",
      //   accessor: "name",
      //   bodyClass: "email !font-medium !text-[12px]",
      //   sortedIcon: true,
      // },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponseClone,
  };
};

export const getPayerTable = (onActionClickHandler, apiResponse) => {
  populateActions(apiResponse);

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Payer Name",
        accessor: "name",
        sortedIcon: true,
      },
      {
        Header: "Payer Account ID",
        accessor: "payerId",
        sortedIcon: true,
        bodyClass: "whitespace-nowrap normal-case",
        headerClass: "whitespace-nowrap",
      },
      {
        Header: "S3 Bucket",
        accessor: "s3Bucket",
        sortedIcon: true,
        bodyClass: "whitespace-nowrap normal-case",
        headerClass: "whitespace-nowrap",
        noRender: isBilldeskModule(),
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        sortedIcon: true,
        hideFilters: true,
        noRender: !isBilldeskModule(),
        bodyClass: "whitespace-nowrap normal-case",
        headerClass: "whitespace-nowrap",
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        sortedIcon: true,
        bodyClass: "whitespace-nowrap normal-case",
        headerClass: "whitespace-nowrap",
        noRender: !isBilldeskModule(),
      },
      {
        Header: "Last Updated At",
        accessor: "lastUpdatedAt",
        sortedIcon: true,
        hideFilters: true,
        noRender: !isBilldeskModule(),
        bodyClass: "whitespace-nowrap normal-case",
        headerClass: "whitespace-nowrap",
      },
      {
        Header: "Last Updated By",
        accessor: "lastUpdatedBy",
        sortedIcon: true,
        bodyClass: "whitespace-nowrap normal-case",
        headerClass: "whitespace-nowrap",
        noRender: !isBilldeskModule(),
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponse,
  };
};

export const createAccountIds = (apiResponse, isAutoModule) => {
  apiResponse.forEach((customer) => {
    const { accounts = [] } = customer;

    const customerClone = customer;
    customerClone.accountFamilies = accounts.map((item) => item.accountId);

    if (isAutoModule) {
      customerClone.signUpTimeStamp = new Date(customerClone.signUpTimeStamp);
      customerClone.lastLoginTimeStamp = new Date(customerClone.lastLoginTimeStamp);
      customerClone.lastMonthBillingAmountSort = customerClone.lastMonthBillingAmount;
      customerClone.monthlyPotentialSavingsSort = customerClone.monthlyPotentialSavings;
      customerClone.ec2PotentialSavingsSort = customerClone.ec2PotentialSavings;
      customerClone.averageCostSort = customerClone.averageCost;
    }
  });
};

const populateCustomerActions = (apiResponse, isAutoModule, hasWritePermission) => {
  apiResponse.forEach((item) => {
    const clone = item;
    let actionsClone = [];
    if (isBilldeskModule()) {
      // hide preview in case user have write permission in billdesk
      actionsClone = hasWritePermission ? ["edit", "delete"] : ["edit", "delete", "preview"];
    } else {
      if (isAutoModule) {
        actionsClone = ["edit", "delete", "changePlan"];
      } else {
        actionsClone = ["edit", "delete"];
      }
      if (item?.showAgreement) {
        actionsClone.push("showAgreement");
      }
      if (isAutoModule && item?.name && item?.accounts?.[0]?.accountId) {
        actionsClone.push("refresh");
      }
    }
    const getActionsClone = cloneDeep(getActions(false, clone, actionsClone));
    clone.actions = getActionsClone;
  });
};
const getCustomerAutoHeader = [
  {
    Header: "Customer Name",
    accessor: "name",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap !sticky left-[0px] bg-white z-[9]",
    headerClass: "whitespace-nowrap !sticky left-[0px] !z-10 !text-left ",
  },
  {
    Header: "Payer Account ID",
    accessor: "accountFamilies",
    component: ListPopup,
    array: true,
    // sortedIcon: true,
    bodyClass: "whitespace-nowrap",
    headerClass: "whitespace-nowrap",
  },
  {
    Header: "Last Month Bill",
    accessor: "lastMonthBillingAmount",
    component: HoverText,
    sortKey: "lastMonthBillingAmountSort",
    props: {
      showHover: false,
      prefix: `${getCurrencySymbol()}`,
    },
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
  },
  {
    Header: "Last 3 Month Average",
    accessor: "averageCost",
    sortKey: "averageCostSort",
    component: HoverText,
    props: {
      showHover: false,
      prefix: `${getCurrencySymbol()}`,
    },
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
  },

  {
    Header: "Monthly Potential Savings",
    accessor: "monthlyPotentialSavings",
    sortKey: "monthlyPotentialSavingsSort",
    component: HoverText,
    props: {
      showHover: false,
      prefix: `${getCurrencySymbol()}`,
    },
    sortedIcon: true,
    headerClass: "whitespace-nowrap",
  },
  {
    Header: "Savings Delivered",
    accessor: "savingsDelivered",
    sortedIcon: true,
    // bodyClass: "whitespace-nowrap",
    headerClass: "whitespace-nowrap",
    component: HoverText,
    props: {
      showHover: false,
      prefix: `${getCurrencySymbol()}`,
    },
  },
  {
    Header: "Source",
    accessor: "source",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap",
  },
  {
    Header: "Active Plan",
    accessor: "activePlan",
    sortedIcon: true,
  },
  {
    Header: "Account Status",
    accessor: "accountStatus",
    component: Journey,
    props: {
      infoIcon: true,
      tooltipKey: "accountStatus",
    },
    sortedIcon: true,
    bodyClass: "whitespace-nowrap justify-end",
  },
  {
    Header: "Sign Up Date",
    accessor: "signUpDate",
    sortedIcon: true,
    sortKey: "signUpTimeStamp",
    bodyClass: "whitespace-nowrap",
    headerClass: "whitespace-nowrap",
  },
  {
    Header: "Last Status Changed At",
    accessor: "lastStatusChangedAt",
    sortedIcon: true,
    sortKey: "lastStatusChangedAtTimeStamp",
    bodyClass: "whitespace-nowrap",
    headerClass: "whitespace-nowrap",
  },
  {
    Header: "Last Login",
    accessor: "lastLogin",
    sortKey: "lastLoginTimeStamp",
    component: HoverText,
    props: {
      showHover: true,
      tooltipKey: "email",
    },
    sortedIcon: true,
    bodyClass: "whitespace-nowrap",
  },
  {
    Header: "Last Refreshed At (Manual)",
    accessor: "lastRefreshedBy",
    sortKey: "lastRefreshedTimeStamp",
    component: HoverText,
    props: {
      showHover: true,
      tooltipKey: "lastRefreshedEmail",
    },
    sortedIcon: true,
    bodyClass: "whitespace-nowrap",
    headerClass: "whitespace-nowrap",
  },
  {
    Header: "Actions",
    accessor: "actions",
    headerClass: "!w-auto !sticky right-[0px] !z-10",
    icons: true,
    overwritePermissions: true,
    bodyClass: "whitespace-nowrap !sticky right-[0px]",
  },
];

const getCustomerHeaders = (isAutoModule, isBilldask) => {
  let headers = [];
  if (isAutoModule) {
    headers = [...getCustomerAutoHeader];
  } else {
    headers = [
      {
        Header: "Customer Name",
        accessor: "name",
        sortedIcon: true,
      },
      {
        Header: "Customer Type",
        accessor: "status",
        render: (item) =>
          item?.status?.type && (
            <TooltipV2
              parentClass="Edp_Tooltip_term_level_summary_V2"
              displayText={() => (
                <div className="Edp_TooltipV2_Header">{item?.status?.type || ""}</div>
              )}
            >
              {item?.customerTypeHistory?.length ? (
                CustomerStatusHistoryTooltip(item)
              ) : (
                <p className="text-[11px]">No Data</p>
              )}
            </TooltipV2>
          ),
        sortedIcon: true,
      },
      {
        Header: "Linked Account ID’s",
        accessor: "accountFamilies",
        component: ListPopup,
        array: true,
        // sortedIcon: true,
        bodyClass: "!text-left",
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ];
    if (!isBilldask) {
      headers?.splice(1, 1);
    }
  }
  return headers;
};

export const getCustomerTable = (onActionClickHandler, apiResponse, hasWritePermission) => {
  const isAutoModule = isAutoDashboard();
  const isBilldask = isBilldeskModule();
  populateCustomerActions(apiResponse, isAutoModule, hasWritePermission);
  createAccountIds(apiResponse, isAutoModule);
  return {
    onIconClick: onActionClickHandler,
    headers: getCustomerHeaders(isAutoModule, isBilldask),
    data: convertDateRole(apiResponse),
  };
};

export const createDiscountTemplatesActions = (apiResponse, action) => {
  apiResponse.forEach((customer) => {
    const customerClone = customer;
    if (customerClone?.templateType?.toLowerCase() === "custom") {
      customerClone.actions = [
        {
          actionType: "delete",
          class: "delete-icon",
        },
        {
          actionType: "preview",
          class: "preview-icon",
        },
        {
          actionType: "clone",
          class: "clone-icon",
        },
      ];
      if (customerClone?.inUse?.toLowerCase() === "yes") {
        customerClone?.actions?.forEach((item) => {
          const actionItem = item;
          if (item?.actionType === "delete") {
            actionItem.disabled = true;
          }
        });
      }
    } else customerClone.actions = action;
  });
};

export const getDiscountTemplatesTable = (onActionClickHandler, apiResponse) => {
  createDiscountTemplatesActions(apiResponse, [
    {
      actionType: "preview",
      class: "preview-icon",
    },
    {
      actionType: "clone",
      class: "clone-icon",
    },
  ]);

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Template Name",
        accessor: "name",
        sortedIcon: true,
        bodyClass: "!text-[13px] !font-medium",
        render: (item) => descriptionTooltip(item),
      },
      {
        Header: "Description",
        accessor: "description",
        sortedIcon: true,
      },
      {
        Header: "Discount Type",
        accessor: "discountType",
        sortedIcon: true,
      },
      {
        Header: "Template Type",
        accessor: "templateType",
        sortedIcon: true,
        component: Tag,
      },
      {
        Header: "Configuration",
        accessor: "configuration",
        sortedIcon: true,
        component: ListPopup,
        props: {
          dataCount: 1,
          shouldSort: false,
        },
      },
      {
        Header: "Created by",
        accessor: "createdBy",
        sortedIcon: true,
      },
      {
        Header: "Creation Date",
        accessor: "date",
        sortedIcon: true,
      },
      {
        Header: "In Use",
        accessor: "inUse",
        sortedIcon: true,
        component: InUse,
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
        bodyClass: "!text-[12px]",
        headerClass: "action_data",
      },
    ],
    data: apiResponse,
  };
};

export const getOnDemandDiscountTable = (onActionClickHandler, apiResponse) => {
  populateActions(apiResponse, null, null, null, null, "OndemandDiscount");
  createAccountIds(apiResponse);

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Template Name",
        accessor: "name",
        sortedIcon: true,
      },
      {
        Header: "Product Name",
        accessor: "productName",
        sortedIcon: true,
      },
      {
        Header: "Region",
        accessor: "region",
        sortedIcon: true,
      },
      {
        Header: "OS/DB/Type",
        accessor: "osDbType",
        sortedIcon: true,
      },
      {
        Header: "Custom Group",
        accessor: "customGroup",
        sortedIcon: true,
      },
      {
        Header: "Instance/Family",
        accessor: "instanceFamily",
        sortedIcon: true,
      },
      {
        Header: "In Use",
        accessor: "inUse",
        sortedIcon: true,
      },
      {
        Header: "Type",
        accessor: "type",
        // sortedIcon: true,
        component: Tag,
      },
      // {
      //   Header: "Linked Account ID’s",
      //   accessor: "accountFamilies",
      //   component: ListPopup,
      //   array: true,
      //   sortedIcon: true,
      //   bodyClass: "!text-left",
      // },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponse,
  };
};

export const getMAVTable = (onActionClickHandler, apiResponse) => {
  populateActions(apiResponse, null, null, null, null, "mavTable");
  createAccountIds(apiResponse);

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "MAV Name",
        accessor: "name",
        component: TextEditModal,
        onEditClick: true,
        // sortedIcon: true,
      },
      {
        Header: "Account ID’s",
        accessor: "accountFamilies",
        component: ListPopup,
        array: true,
        // sortedIcon: true,
        bodyClass: "!text-left",
      },
      {
        Header: "Manage Users",
        accessor: "users",
        // component: InvoiceCustomer,
        // sortedIcon: true,
      },
      {
        Header: "",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponse,
  };
};

export const templateTable = (onActionClickHandler, apiResponse, S3_BASE_URL) => {
  populateActions(apiResponse, false, S3_BASE_URL, "logoName", "logo");

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Logo",
        accessor: "logo",
        headerClass: "!text-center",
      },
      {
        Header: "Header",
        accessor: "header",
        sortedIcon: true,
      },
      {
        Header: "Description",
        accessor: "description",
        sortedIcon: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponse,
  };
};

export const getButtonAndHeaderName = () => {
  const { pathname } = window.location;

  switch (pathname) {
    case Routes.CONFIRMUSER:
    case Routes.CONFIRM_USER_OLD:
      return "Create Password";
    case Routes.RESETPASSWORD:
    case "/resetPassword":
      return "Reset Password";
    default:
      return "";
  }
};

export const CommonServiceNameForResetConfirm = () => {
  const { pathname } = window.location;

  switch (pathname) {
    case Routes.CONFIRMUSER:
    case Routes.CONFIRM_USER_OLD:
      return "confirmSignUpV2";
    case Routes.RESETPASSWORD:
    case "/resetPassword":
      return "handleResetPassword";
    default:
      return "";
  }
};

export const createCustomerHealthCheckAction = (apiResponse, action) => {
  apiResponse?.forEach((customer) => {
    const customerClone = customer;
    customerClone.actions = action;
  });
};

export const checkForNoDataCustomerHeath = (item, key) => {
  if (item?.[key]?.toLowerCase() === "no") {
    return (
      <div>
        {item?.[key]}
        <WarningRoundedIcon className="warning-icon" />
      </div>
    );
  }
  return <div>{item?.[key] || "- - -"}</div>;
};

export const getCustomerHeathCheckTable = (onActionClickHandler, apiResponse) => {
  createCustomerHealthCheckAction(apiResponse, [
    {
      actionType: "refresh",
      class: "refresh-icon",
    },
  ]);

  return {
    onIconClick: onActionClickHandler,
    headers: [
      {
        Header: "Customer Name",
        accessor: "name",
        sortedIcon: true,
      },
      {
        Header: "Onboarding Status",
        accessor: "accountStatus",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "accountStatus"),
      },
      {
        Header: "Account Type",
        accessor: "accountType",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "accountType"),
      },
      {
        Header: "ReadOnly IAM role verified",
        accessor: "readIamStatus",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "readIamStatus"),
      },
      {
        Header: "Customer S3 CUR configured",
        accessor: "sourceBucketData",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "sourceBucketData"),
      },
      {
        Header: "Customer Replication Policy working",
        accessor: "replicationPolicy",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "replicationPolicy"),
      },
      {
        Header: "Auto S3 Bucket Data arrived",
        accessor: "destinationBucketData",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "destinationBucketData"),
      },
      {
        Header: "Write IAM role verified",
        accessor: "writeIamStatus",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "writeIamStatus"),
      },
      {
        Header: "CE Enabled",
        accessor: "ceStatus",
        sortedIcon: true,
        render: (item) => checkForNoDataCustomerHeath(item, "ceStatus"),
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponse,
  };
};

export const DEFAULT_TEMPLATE_HEADERS = (actionClickHandler) => [
  {
    Header: "Template Name",
    accessor: "name",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case  bg-white !px-[8px] !text-left discount_name_V2",
    headerClass: "whitespace-nowrap  !text-left",
    searchable: true,
    render: (item) => (
      <TooltipV2
        parentClass="Edp_Tooltip_Style_V2 reservation_tooltip"
        displayText={() => <div className="Edp_TooltipV2_Header">{item?.name}</div>}
      >
        {renderEdpDescriptionTooltip(item?.description)}
      </TooltipV2>
    ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Discount Type",
    accessor: "templateType",
    bodyClass: "whitespace-nowrap",
    searchable: true,
    searchableKeys: ["templateType", "serviceType"],
    sortedIcon: true,
    render: (item) => (
      <div>
        <span>{item.templateType}</span>
        {item.templateType === "Reservation Discount" && (
          <span className="discountTypeChip">{item?.serviceType}</span>
        )}
      </div>
    ),
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    bodyClass: "whitespace-nowrap",
    searchable: true,
    sortedIcon: true,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    bodyClass: "whitespace-nowrap",
    searchable: true,
    sortedIcon: true,
    dateFormat: "DD-MMM-YYYY HH:mm",
  },
  {
    Header: "In Use",
    accessor: "inUse",
    bodyClass: "whitespace-nowrap",
    sortedIcon: true,
    searchable: true,
    render: (item) => (
      <div className={`${item?.inUse.toLowerCase() === "yes" ? "font-medium" : ""} flex`}>
        <p className="w-[20px]">{item?.inUse}</p>
        {item?.customers ? (
          <button
            type="button"
            onClick={() => actionClickHandler(item)}
            className="text-primary-blue font-bold cursor-pointer"
          >
            <EyeIcon className="ml-[5px] eyeIcon" />
          </button>
        ) : null}
      </div>
    ),
  },
  {
    Header: "Actions",
    // searchable: true,
    accessor: "actions",
    icons: true,
    overwritePermissions: true,
  },
];
export const getCustomDiscountTable = (onActionClickHandler, apiResponse, actionHandler) => {
  apiResponse.forEach((item) => {
    const clone = item;
    clone.createdAt = moment(clone.createdAt).format("DD-MMM-YYYY, HH:mm");
    clone.actions = [
      {
        actionType: "preview",
        class: "preview-icon",
        overwritePermissions: true,
      },
      {
        actionType: "clone",
        class: "edit-icon mx-[7px]",
        overwritePermissions: true,
      },
      {
        actionType: "delete",
        class: `edit-icon ${item.inUse === "Yes" ? "disabled-icon" : ""}`,
        overwritePermissions: true,
      },
    ];
  });

  return {
    onIconClick: onActionClickHandler,
    headers: DEFAULT_TEMPLATE_HEADERS(actionHandler),
    data: apiResponse,
  };
};
export const getDefaultDiscountTable = (onActionClickHandler, apiResponse, actionHandler) => {
  apiResponse.forEach((item) => {
    const clone = item;
    clone.createdAt = moment(clone.createdAt).format("DD-MMM-YYYY, HH:mm");
    clone.actions = [
      {
        actionType: "preview",
        class: "preview-icon",
        overwritePermissions: true,
      },
      {
        actionType: "clone",
        class: "edit-icon mx-[7px]",
        overwritePermissions: true,
      },
    ];
  });

  return {
    onIconClick: onActionClickHandler,
    headers: DEFAULT_TEMPLATE_HEADERS(actionHandler),
    data: apiResponse,
  };
};

const renderCheckbox = (props, tableData) => {
  const item = props.tableRow;
  return (
    <div className="text-field construct_ordering_field">
      {item?.name && (
        <>
          <Checkbox
            name="name"
            checked={item.checked}
            onChange={() => item?.changeHandler(item, tableData)}
            disabled={item?.default}
          />
          <span className="ml-[5px]">{item.name}</span>
          {item?.default && <span className="default">Default</span>}
        </>
      )}
    </div>
  );
};

export const CONSTRUCT_ORDERING_HEADERS = (tableData) => [
  {
    Header: "Template Name",
    accessor: "name",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case  bg-white !px-[8px] !text-left discount_name_V2",
    headerClass: "whitespace-nowrap  !text-left",
    searchable: true,
    component: (item) => renderCheckbox(item, tableData),
    // props: {
    //   defaultSelectedHeader: true,
    // },
  },
  {
    Header: "Customers",
    accessor: "customers",
    bodyClass: "whitespace-nowrap",
    searchable: true,
    sortedIcon: false,
    component: ListPopup,
    props: {
      dataCount: 3,
      defaultDisplayText: "N/A",
    },
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    bodyClass: "whitespace-nowrap",
    searchable: true,
    sortedIcon: true,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    bodyClass: "whitespace-nowrap",
    searchable: true,
    sortedIcon: true,
    filterType: "string",
    hideFilters: true,
    render: (item) => <>{moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm")}</>,
  },
  {
    Header: "In Use",
    accessor: "inUse",
    bodyClass: "whitespace-nowrap",
    sortedIcon: true,
    searchable: true,
    render: (item) => (
      <div className={item?.inUse.toLowerCase() === "yes" ? "font-bold" : ""}>{item?.inUse}</div>
    ),
  },
  {
    Header: "Actions",
    // searchable: true,
    accessor: "actions",
    icons: true,
    overwritePermissions: true,
  },
];

export const getConstructOrderingTable = (
  onActionClickHandler,
  apiResponse,
  changeHandler,
  actionDisabled = false
) => {
  apiResponse.forEach((item) => {
    const clone = item;
    clone.createdAt = item.createdAt;
    clone.checked = item.checked || false;
    clone.changeHandler = changeHandler;
    clone.actions = [
      {
        actionType: "preview",
        class: "preview-icon",
        overwritePermissions: true,
      },
      {
        actionType: "clone",
        class: `edit-icon !mx-[7px] ${actionDisabled ? "disabled-icon" : ""}`,
      },
      {
        actionType: "delete",
        class: `edit-icon delete-icon !mr-[10px] ${
          item.inUse === "Yes" || item?.default || !item?.isUnused || actionDisabled
            ? "disabled-icon"
            : ""
        }`,
      },
      {
        actionType: "apply",
        class: `edit-icon apply-icon border-l border-solid border-[#DDDFE8] font-medium px-[7px] text-[#0a3ca2] ${
          actionDisabled ? "disabled-icon disabled disabled-apply" : ""
        }`,
      },
    ];
  });

  return {
    onIconClick: onActionClickHandler,
    headers: CONSTRUCT_ORDERING_HEADERS(apiResponse),
    data: apiResponse,
  };
};

export const transformCustomerList = (data) =>
  data?.map((item) => ({
    label: item?.name,
    value: item?.name,
    id: item?.id,
    checked: false,
  }));
