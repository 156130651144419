import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import Form from "../FormConfig/Form";
import withRouter from "../Common/WithRouter";
import { changePasswordIcon, getLoginPayload, getWrapperClass } from "./utils";
import Routes from "../../Config/Routes";
import commonService from "../../Services/commonService";
import actions from "../../Actions";
import { saveUserData } from "../../Utils/userUtils";
import loginUtil from "../../Utils/publicPageUtils";
import Button from "../FormConfig/Form/Components/Button";
import Loader from "../Common/Loader";
import PageTitle from "../Common/PageTitle";
import { getToast } from "../../Utils/commonUtils";
import PublicFooter from "../CK-Auto/Common/PublicFooter";
import "./style.scss";
import { isCkAutoOrLens } from "../CK-Auto/signup/utils";
import fetchStrapiData from "../../Services/Strapi";
import strapiUrls, { strapiBasePath } from "../../Services/Strapi/urls";

import SsoCta from "../Common/SsoCta";

class LoginComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statePasswordValidations: {
        toggleCallback: this.toggleCallback,
        showLabel: "true",
        labelClass: "input-label",
        placeHolder: "Password",
      },
      isError: false,
      isLoading: false,
      partnerLogo: "",
      allRightsFooter: "",
      isLoadingLogo: true,
      submitValid: false,
      signUpCheck: props.ckAutoEnabled,
      htmlContent: "",
      captchaToken: "",
      refreshReCaptcha: false,
      partnerDisplayName: "",
      // isCkAuto: false,
      signUpEnabled: false,
      captchaTimer: null,
      isMicrosoftEnabled: false,
      strapiDataState: {},
      isPageLoading: false,
      isGoogleEnabled: false,
    };
  }

  async componentDidMount() {
    const {
      props: {
        navigate,
        actions: { CkAutoEnabledAction },
      },
    } = this;
    const { statePasswordValidations } = this.state;
    this.setState({ isPageLoading: true });
    localStorage.removeItem("logoutButtonClick");
    const response = await Promise.all([
      this.getStrapiData(),
      loginUtil(
        CkAutoEnabledAction,
        navigate,
        this.updateState,
        "Login",
        Routes.PAGENOTFOUND,
        this.refreshReCaptchaHandler
      ),
    ]);
    const [strapiData, partnerDisplayNameVar] = response;
    if (partnerDisplayNameVar) {
      this.setState({
        allRightsFooter: `${partnerDisplayNameVar} ${new Date().getFullYear()} ${
          strapiData?.pageFooter?.hypertext
        }`,
      });
    } else {
      this.setState({
        allRightsFooter: `${strapiData?.pageFooter?.pretext} ${new Date().getFullYear()} ${
          strapiData?.pageFooter?.hypertext
        }`,
      });
    }
    this.setState({
      strapiDataState: strapiData,
      statePasswordValidations: { ...statePasswordValidations, ...strapiData?.form?.[1] },
      isPageLoading: false,
    });
  }

  componentWillUnmount() {
    const { captchaTimer } = this.state;
    if (captchaTimer) {
      clearInterval(captchaTimer);
    }
  }

  updateState = (stateData) => {
    this.setState(stateData);
  };

  getStrapiData = async () => {
    // const { statePasswordValidations } = this.state;
    const data = await fetchStrapiData(strapiUrls.LOGIN);
    return data;
  };

  handleSubmit = async (values) => {
    const {
      props: {
        actions: { SidebarListRenderAction },
        navigate,
      },
      state: { captchaToken },
    } = this;
    try {
      this.setState({ isLoading: true, submitValid: false });
      delete localStorage.userData;
      const payload = getLoginPayload(values);
      const response = await commonService.handleLogin(payload, captchaToken);
      SidebarListRenderAction("LOGIN_USER", []);
      this.setState({ isLoading: false });

      saveUserData(response, this.props, navigate);
    } catch (err) {
      this.refreshReCaptchaHandler();
      this.handleLoginFail(err);
    }
  };

  handleLoginFail = (err) => {
    const { response: { status, data: { message } = {} } = {} } = err;

    const stateObject = {
      isLoading: false,
    };
    if (status === 400 || status === 401) {
      getToast("error", message);
    } else {
      stateObject.submitValid = true;
    }
    this.setState(stateObject);
  };

  toggleCallback = (inputType) => {
    const {
      state: { statePasswordValidations },
    } = this;

    const updatedValidation = changePasswordIcon(statePasswordValidations, inputType);
    this.setState({ statePasswordValidations: updatedValidation });
  };

  navigateToSignup = () => {
    const {
      props: { navigate },
    } = this;

    navigate({
      pathname: Routes.REGISTER,
    });
  };

  renderLeftContent = (content) =>
    content && (
      // eslint-disable-next-line react/no-danger
      <div className="LoginLeftHTML-Wrapper" dangerouslySetInnerHTML={{ __html: content }} />
    );

  getHTMLClassName = (HTMLContent) => (HTMLContent ? "leftHTML" : "");

  getLogoWrapper = (signUpCheck, partnerLogo, isLoadingLogo, Logo) =>
    signUpCheck && (
      <div className="logo-container">
        <div className={` ${partnerLogo && " logoWrapper "} logo`}>
          {!isLoadingLogo && <img src={`${partnerLogo || Logo}`} alt="logo" />}
        </div>
      </div>
    );

  refreshReCaptchaHandler = () => {
    /* do something like submit a form and then refresh recaptcha */
    const { refreshReCaptcha } = this.state;
    this.setState({ refreshReCaptcha: !refreshReCaptcha });
  };

  setToken = (token) => {
    const { captchaToken } = this.state;
    if (captchaToken !== token) {
      this.setState({ captchaToken: token });
    }
  };

  getModuleName = () => {
    const { isCkAuto } = this.state;
    if (isCkAuto) {
      return "ck-auto";
    }
    return "default";
  };

  getCKAutoClassWithoutHTMLContent = (signUpCheck, htmlContent) =>
    `${signUpCheck && !htmlContent && "withoutHTMLContent"}`;

  isButtonDisabled = (isError, isLoading) => isError || isLoading;

  renderPublicFooter = () => {
    const {
      state: { signUpCheck, allRightsFooter, htmlContent, isCkAuto, isLens, strapiDataState },
    } = this;
    return (
      <PublicFooter
        isCkAuto={isCkAutoOrLens(isCkAuto, isLens)}
        show={signUpCheck}
        leftText={!!htmlContent}
        allRightsFooter={allRightsFooter}
        strapiData={strapiDataState?.publicFooter}
      />
    );
  };

  returnAutoFooter = () => {
    const { isCkAuto, isLens } = this.state;
    if (!isCkAutoOrLens(isCkAuto, isLens)) {
      return this.renderPublicFooter();
    }
    return null;
  };

  returnNotAutoFooter = () => {
    const { isCkAuto, isLens } = this.state;
    if (isCkAutoOrLens(isCkAuto, isLens)) {
      return this.renderPublicFooter();
    }
    return null;
  };

  checkConditionForRecaptcha = (recaptchaFlag) =>
    recaptchaFlag === "true" && (
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
          async
          defer
        />
      </Helmet>
    );

  // eslint-disable-next-line max-lines-per-function, complexity
  render() {
    const {
      handleSubmit,
      navigateToSignup,
      setToken,
      state: {
        refreshReCaptcha,
        statePasswordValidations,
        isError,
        isLoading,
        partnerLogo,
        isLoadingLogo,
        submitValid,
        signUpCheck,
        allRightsFooter,
        htmlContent,
        partnerDisplayName,
        signUpEnabled,
        isMicrosoftEnabled,
        isGoogleEnabled,
        isCkAuto,
        isLens,
        strapiDataState: { formComponent: { data: { attributes } = {} } = {} } = {},
        strapiDataState,
        isPageLoading,
      },
      props: { pageTitle },
    } = this;
    const emailValidationsClone = {
      ...attributes?.form?.[0],
    };
    emailValidationsClone.validations = {
      rules: attributes?.form?.[0]?.rules,
      messages: attributes?.form?.[0]?.messages,
    };
    const passwordValidationsClone = {
      ...statePasswordValidations,
      ...attributes?.form?.[1],
    };
    passwordValidationsClone.validations = {
      rules: attributes?.form?.[1]?.rules,
      messages: attributes?.form?.[1]?.messages,
    };
    const moduleName = this.getModuleName();
    const recaptchaFlag = process.env.REACT_APP_RECAPTCHA_FLAG || "false";
    // !mt-6 do not delete
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {isPageLoading ? (
          <Loader />
        ) : (
          <div
            className={`logIn-mainContainer ${getWrapperClass(
              "login_wrapper_Without_bg_without_boxshadow whiteLabeledProduct",
              signUpCheck
            )}  ${this.getHTMLClassName(htmlContent)} ${this.getCKAutoClassWithoutHTMLContent(
              signUpCheck,
              htmlContent
            )}`}
          >
            {this.checkConditionForRecaptcha(recaptchaFlag)}
            <PageTitle
              pageTitle={pageTitle || strapiDataState?.heading}
              customPartnerDisplayName={partnerDisplayName}
              pageHeader={signUpCheck}
            />
            <div className="flex justify-between !w-full signup_wrapper">
              {this.renderLeftContent(htmlContent)}
              <div
                className={`login_wrapper ${
                  isCkAutoOrLens(isCkAuto, isLens) ? "ck_auto_module" : ""
                }`}
              >
                <div className="login-form-wrapper">
                  {this.getLogoWrapper(
                    !signUpCheck || !htmlContent,
                    partnerLogo,
                    isLoadingLogo,
                    `${strapiBasePath}${strapiDataState?.logo?.data?.attributes?.url}`
                  )}
                  {isLoadingLogo ? (
                    <Loader popup />
                  ) : (
                    <div className="login_form login_Without_bg">
                      {this.getLogoWrapper(
                        signUpCheck && htmlContent,
                        partnerLogo,
                        isLoadingLogo,
                        `${strapiBasePath}${strapiDataState?.logo?.data?.attributes?.url}`
                      )}
                      <h1>{strapiDataState?.headingTexts?.[moduleName]?.heading}</h1>
                      {strapiDataState?.headingTexts?.[moduleName]?.subHeading && (
                        <p className="subHeading">
                          {strapiDataState?.headingTexts?.[moduleName]?.subHeading}
                        </p>
                      )}
                      {submitValid ? (
                        <div className="formErrors">Invalid combination. Please try again.</div>
                      ) : (
                        ""
                      )}
                      <Form
                        formConfig={[emailValidationsClone, passwordValidationsClone]}
                        handleSubmit={handleSubmit}
                        isError={(value) => this.setState({ isError: value })}
                        formValues={{}}
                      >
                        <div className="flex justify-end remember-me-container flex-wrap">
                          {/* <FormControlLabel
                        control={<Checkbox disableRipple />}
                        label="Remember me"
                        labelPlacement="end"
                      /> */}
                          <Link className="forgot-password" to={strapiDataState?.routelink?.href}>
                            {strapiDataState?.routelink?.hypertext}
                          </Link>
                          {recaptchaFlag === "true" && (
                            <GoogleReCaptchaProvider
                              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            >
                              <GoogleReCaptcha
                                className="testing"
                                onVerify={setToken}
                                refreshReCaptcha={refreshReCaptcha}
                              />
                            </GoogleReCaptchaProvider>
                          )}
                        </div>
                        <Button
                          text={attributes?.form?.[2]?.label}
                          className={attributes?.form?.[2]?.className}
                          disabled={this.isButtonDisabled(isError, isLoading)}
                          isLoading={isLoading}
                          type={attributes?.form?.[2]?.type}
                        />
                      </Form>
                      <SsoCta
                        isMicrosoftEnabled={isMicrosoftEnabled}
                        isGoogleEnabled={isGoogleEnabled}
                        text="Sign in"
                        loginBtnEnabled={strapiDataState?.signupEnabled}
                      />

                      {signUpEnabled && (
                        <div className="account">
                          <span>{strapiDataState?.signupEnabled?.[1]?.heading}</span>
                          <span
                            aria-hidden="true"
                            className={strapiDataState?.signupEnabled?.[1]?.button?.className}
                            onClick={() => navigateToSignup()}
                          >
                            {strapiDataState?.signupEnabled?.[1]?.button?.label}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {!isLoadingLogo && !signUpCheck && (
                  <div className="footer-text">{allRightsFooter}</div>
                )}
                {this.returnNotAutoFooter()}
              </div>
            </div>
            {this.returnAutoFooter()}
          </div>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  ckAutoEnabled: state.CkAutoReducer.signupEnabled,
  isCkAuto: state.CkAutoReducer.isCkAuto,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginComp));
