import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../../Assets/images/ic-info.svg";
// import { ReactComponent as InfoIcon } from "../../../../../Assets/images/ic-info.svg";
// import { ReactComponent as InfoDisabledIcon } from "../../../Assets/images/ic-info-disabled.svg";
import "./style.scss";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

function TooltipV2(props) {
  const {
    displayText,
    children,
    parentClass,
    tooltipClass,
    popoverClassNames,
    tooltipid,
    anchorOrigin = {
      vertical: "center",
      horizontal: "left",
    },
    transformOrigin = {
      vertical: "center",
      horizontal: "right",
    },
  } = props;
  const showMore = useRef();

  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverAnchor = useRef(null);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  return (
    <div className={`list-popup-wrapper auto-renew-tooltip-wrapper ${tooltipClass}`} id={tooltipid}>
      <span className="display-text">{displayText() || ""}</span>
      <div className="pop-up ml-[3px] mb-[3px]">
        <span
          ref={popoverAnchor}
          aria-owns="auto-renew-popover"
          aria-haspopup="true"
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          <InfoIcon ref={showMore} className="show-more !w-[13px] !h-[13px]" />
        </span>

        <Popover
          id="auto-renew-popover"
          className={`${classes.popover} ${popoverClassNames} auto-renew-tooltip-popover
            before:absolute bottom-6 before:bottom-[-24px] before:right-[4px] before:transform before:rotate-[135deg] before:left-auto`}
          classes={{
            paper: classes.popoverContent,
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
        >
          <div className={`${parentClass}`}>{children}</div>
        </Popover>
      </div>
    </div>
  );
}

export default TooltipV2;
