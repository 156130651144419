/* eslint-disable max-lines-per-function */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Checkbox, FormControlLabel } from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import { BsSearch } from "react-icons/bs";
import { ReactComponent as SelectIcon } from "../../../../../Assets/icons/Vector.svg";
import { ReactComponent as PreviewIcon } from "../../../../../Assets/icons/ic-preview-templt.svg";
import ListSelectorNoData from "./noData";
import CustomDropDown from "../CustomDropDown";
import { ReactComponent as AdminIcon } from "../../../../../Assets/icons/reset-icon.svg";
import DimensionBox from "./DimensionBox";
import { removeDuplicates } from "../../../../../Utils/commonUtils";

// eslint-disable-next-line complexity
function ListSelector({
  configData = [],
  handleChange,
  error,
  name,
  values,
  headerText = "Configurations",
  resetText = "Reset Configurations",
  innerCardHeaderText = {},
  noDataMessage = {},
  headingCount = {},
  includeSelectionFilter = true,
  parentClass = "",
  errorClass = "",
  touched = false,
}) {
  const [selectedItem, setSelectedItem] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [searchTemplateInput, setSearchTemplateInput] = useState("");

  const [updatedConfig, setUpdatedConfig] = useState(configData);
  const [selectedConfiguration, setSelectedConfiguration] = useState({});
  const [touchedEvent, setTouchedEvent] = useState(false);
  const [lastSelectedItem, setLastSelectedItem] = useState("");
  const [resetClicked, setResetClicked] = useState(false);
  const [columnSelected, setColumnSelected] = useState(0);

  const [resetState, setResetState] = useState({});

  const dimensionLength = configData.length === 1;

  useEffect(() => {
    setUpdatedConfig(configData);
    if (dimensionLength) {
      setSelectedItem({ ...configData[0] });
    }
  }, [configData]);

  useEffect(() => {
    if (values) {
      setSelectedConfiguration(cloneDeep(values));
      const firstSelectedObj = Object.keys(values)[0];
      const firstSelected = updatedConfig.find((item) => item.displayName === firstSelectedObj);
      setSelectedItem(firstSelected);
      setResetState(cloneDeep(values));
    }
  }, []);

  const searchConfig = (e) => {
    const {
      target: { value },
    } = e;
    setSearchInput(e.target.value);
    const config = configData.filter((item) =>
      item.displayName?.toLowerCase().includes(value?.toLowerCase())
    );
    setUpdatedConfig(config);
  };

  const addColumnValues = (obj) => {
    let sum = 0;
    Object.values(obj).forEach((column) => {
      const valueArray = column.value;
      sum += valueArray.length;
    });
    setColumnSelected(sum);
  };

  useEffect(() => {
    if (selectedConfiguration) {
      addColumnValues(selectedConfiguration);
    }
  }, [selectedConfiguration]);

  const handleChecked = (selected, item) => {
    // Create a copy of the selectedConfiguration object to avoid mutating the original
    const updatedConfiguration = { ...selectedConfiguration };

    if (updatedConfiguration.hasOwnProperty(selected.displayName)) {
      // Check if the selected displayName already exists in the configuration object
      // If it does, we're going to manipulate the value associated with it

      if (updatedConfiguration[selected?.displayName]?.value?.includes(item)) {
        // Check if the item exists in the array associated with the displayName
        // If it does, remove it from the array
        updatedConfiguration[selected.displayName].value = updatedConfiguration[
          selected?.displayName
        ]?.value?.filter((string) => item !== string);

        if (!updatedConfiguration[selected.displayName].value.length) {
          delete updatedConfiguration[selected.displayName];
        }
      } else {
        // If the item doesn't exist in the array, add it to the array
        updatedConfiguration[selected?.displayName]?.value?.push(item);
      }
    } else {
      setTouchedEvent(true);

      // If the selected displayName doesn't exist in the configuration object,
      // create a new entry for it with a value array and an included flag
      updatedConfiguration[selected.displayName] = {
        value: [item],
        included: true,
        displayName: selected.displayName,
      };
    }
    // Logic for handling selected item to top of others
    const clonedItems = removeDuplicates(selectedItem?.items);
    const selectedItems = updatedConfiguration[selectedItem?.displayName]?.value;
    setSelectedItem({
      ...selectedItem,
      items: selectedItems?.concat(clonedItems.filter((x) => !selectedItems?.includes(x))),
    });

    handleChange({ target: { value: updatedConfiguration, name } });

    // Update the state with the modified configuration, which may include added or removed items
    setSelectedConfiguration(updatedConfiguration);
  };

  const arraysMatch = (arr1, arr2) => arr1?.every((element) => arr2?.includes(element));

  const searchedList = selectedItem?.items?.filter((item) =>
    item?.toLowerCase()?.includes(searchTemplateInput?.toLowerCase())
  );

  const handleSelectAll = (selected) => {
    const updatedConfiguration = { ...selectedConfiguration };

    if (arraysMatch(searchedList, updatedConfiguration[selected?.displayName]?.value)) {
      if (updatedConfiguration.hasOwnProperty(selected.displayName)) {
        updatedConfiguration[selected.displayName].value = updatedConfiguration[
          selected?.displayName
        ]?.value.filter((item) => !searchedList.includes(item));
      }
    } else if (updatedConfiguration.hasOwnProperty(selected.displayName)) {
      updatedConfiguration[selected?.displayName]?.value?.push(...searchedList);
    } else {
      updatedConfiguration[selected.displayName] = {
        value: [...searchedList],
        included: true,
        displayName: selected.displayName,
      };
    }

    updatedConfiguration[selected.displayName].value = removeDuplicates(
      updatedConfiguration[selected.displayName].value
    );

    handleChange({ target: { value: updatedConfiguration, name } });

    // Update the state with the modified configuration, which may include added or removed items
    setSelectedConfiguration(updatedConfiguration);
  };

  const deleteConfig = (config, selectedConfig, item) => {
    const updatedConfiguration = { ...selectedConfig };
    updatedConfiguration[item].value = updatedConfiguration[item]?.value?.filter(
      (string) => config !== string
    );
    if (!updatedConfiguration[item].value.length) {
      delete updatedConfiguration[item];
    }
    setSelectedConfiguration(updatedConfiguration);
    handleChange({ target: { value: updatedConfiguration, name } });
  };

  const deleteConfigGroup = (groupName) => {
    const updatedConfiguration = { ...selectedConfiguration };
    delete updatedConfiguration[groupName];
    setSelectedConfiguration(updatedConfiguration);
    handleChange({ target: { value: updatedConfiguration, name } });
  };

  const dropDownHandler = (item, option) => {
    const updatedConfiguration = { ...selectedConfiguration };

    if (updatedConfiguration.hasOwnProperty(item)) {
      updatedConfiguration[item] = {
        ...selectedConfiguration[item],
        included: option === "Include Selection",
      };
    } else {
      updatedConfiguration[item] = {
        value: [],
        included: option === "Include Selection",
        displayName: item,
      };
    }

    setSelectedConfiguration(updatedConfiguration);

    handleChange({ target: { value: updatedConfiguration, name } });
  };

  const returnClassName = () => {
    if (dimensionLength) return "!w-[75%]";
    return "";
  };

  const selectOrUnselectAll = (list, configuration) =>
    arraysMatch(list, configuration?.[selectedItem?.displayName]?.value);

  const resetConfiguration = () => {
    dropDownHandler(selectedItem?.displayName, "Include Selection");
    const _resetState = cloneDeep(resetState);
    setSelectedConfiguration(_resetState);
    // setLastSelectedItem("");
    setSearchTemplateInput("");
    setSearchInput("");
    setUpdatedConfig(configData);
    setResetClicked(!resetClicked);
    handleChange({ target: { value: _resetState, name } });
  };

  return (
    <>
      {headingCount?.title && (
        <div className={headingCount?.parentClass}>
          <div
            className={headingCount?.titleClass}
          >{`${headingCount?.title} (${columnSelected}/${headingCount?.count})`}</div>
          <div className={headingCount?.descriptionClass}>{headingCount?.description}</div>
        </div>
      )}
      <div className={`flex gap-[13px] align-center ${parentClass}`}>
        <h3 className="form_component_title">{headerText}</h3>
        <div className="vertical-line" />
        <div className="reset_config">
          <p
            className={`text-blue-800 text-[12px] mt-[2px] cursor-pointer flex ${
              JSON.stringify(selectedConfiguration) === JSON.stringify(resetState)
                ? "disabled pointer-events-none"
                : ""
            }`}
            onClick={() => {
              resetConfiguration();
            }}
          >
            <AdminIcon className="mt-[2px] mr-[4px]" />
            {resetText}
          </p>
        </div>
      </div>
      <div className={`list_selector_Wrapper ${innerCardHeaderText?.listSelectorClass}`}>
        {!dimensionLength ? (
          <div className="list_selector">
            <h2 className="list_selector_heading">
              {innerCardHeaderText?.listHeaderCardTitle || "Dimensions"}
            </h2>
            <div className="relative">
              <input
                value={searchInput}
                onChange={(e) => {
                  searchConfig(e);
                }}
                placeholder="Search"
                className="!rounded-none !border-r-0 !border-l-0 text-sm opacity-50 p-2 mb-0"
              />
              <BsSearch
                color="#7C7C7C"
                className="absolute right-[8px] translate-y-[-50%] top-[50%]"
                size={14}
              />
            </div>
            {updatedConfig?.length ? (
              <ul>
                {updatedConfig.map((item) => (
                  <li
                    onClick={() => {
                      setSelectedItem(item);
                      setLastSelectedItem(item);
                      setSearchTemplateInput("");
                    }}
                    key={item.id}
                    className={`flex align-center justify-between cursor-pointer ${
                      lastSelectedItem?.displayName === item?.displayName ? "bg-custom-gray" : ""
                    }`}
                  >
                    <p>{item.displayName}</p>
                    {selectedConfiguration[item?.displayName] &&
                    selectedConfiguration[item?.displayName]?.value?.length ? (
                      <p className="selected_length">
                        {selectedConfiguration[item?.displayName]?.value?.length}
                      </p>
                    ) : null}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="no_data">
                <ListSelectorNoData
                  message="Please select template type 
                  for template creation"
                  Icon={SelectIcon}
                />
              </div>
            )}
          </div>
        ) : null}

        <div className="list_selector">
          <div className="list_selector_heading flex justify-between">
            <div>{selectedItem?.displayName || "- - -"}</div>
            {selectedItem?.displayName && includeSelectionFilter && (
              <CustomDropDown
                options={["Include Selection", "Exclude Selection"]}
                handler={(option) => dropDownHandler(selectedItem?.displayName, option)}
                reset={resetClicked}
                selected={
                  selectedConfiguration?.[selectedItem?.displayName]?.included ?? true
                    ? "Include Selection"
                    : "Exclude Selection"
                }
              />
            )}
          </div>
          {selectedItem?.items?.length ? (
            <div className="relative">
              <input
                value={searchTemplateInput}
                onChange={(e) => {
                  setSearchTemplateInput(e?.target?.value);
                }}
                placeholder="Search"
                className="!rounded-none !border-r-0 !border-l-0 text-sm opacity-50 p-2 mb-0"
              />
              <BsSearch
                color="#7C7C7C"
                className="absolute right-[8px] translate-y-[-50%] top-[50%]"
                size={14}
              />
            </div>
          ) : null}
          {(
            selectedItem && Object.keys(selectedItem).length > 0
              ? selectedItem?.items?.filter((item) =>
                  item?.toLowerCase().includes(searchTemplateInput.toLowerCase())
                )?.length > 0
              : true
          ) ? (
            <ul>
              {selectedItem?.items?.length ? (
                <>
                  <li key="select" className="search_template_list">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectOrUnselectAll(searchedList, selectedConfiguration)}
                          onChange={() => {
                            handleSelectAll(selectedItem, selectedItem?.items);
                          }}
                        />
                      }
                      label={
                        selectOrUnselectAll(searchedList, selectedConfiguration)
                          ? "Unselect All"
                          : "Select All"
                      }
                    />
                  </li>
                  {removeDuplicates(selectedItem?.items)?.map((item) =>
                    item?.toLowerCase().includes(searchTemplateInput.toLowerCase()) ? (
                      <li key={item} className="search_template_list">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedConfiguration[selectedItem?.displayName]?.value?.includes(
                                  item
                                ) === true
                              }
                              onChange={() => {
                                handleChecked(selectedItem, item);
                              }}
                            />
                          }
                          label={item}
                        />
                      </li>
                    ) : null
                  )}
                </>
              ) : (
                <ListSelectorNoData
                  message={
                    noDataMessage?.listSelectorNoDataMsg ||
                    "Please Select at-least one dimension for template creation"
                  }
                  Icon={SelectIcon}
                />
              )}
            </ul>
          ) : (
            <div className="no_data !h-[80%]">
              <h5>No Data Found</h5>
            </div>
          )}
        </div>

        <div className={`list_selector ${returnClassName()}`}>
          <h2 className="list_selector_heading Template-Summary">
            {innerCardHeaderText?.selectionListHeaderTitle || "Template Summary"}
          </h2>
          <div className="px-[10px] py-[4px]">
            {Object.keys(selectedConfiguration).length ? (
              Object.keys(selectedConfiguration).map(
                (item) =>
                  (selectedConfiguration[item]?.value?.length && (
                    <DimensionBox
                      deleteConfigGroup={deleteConfigGroup}
                      dimenssionName={item}
                      selectedConfiguration={selectedConfiguration}
                      deleteConfig={deleteConfig}
                      CHIPS_THRESHOLD={dimensionLength ? 54 : 4}
                      includeSelectionFilter={includeSelectionFilter}
                    />
                  )) ||
                  null
              )
            ) : (
              <ListSelectorNoData message="Preview" Icon={PreviewIcon} />
            )}
          </div>
        </div>
      </div>
      <div className={`erro_wrapper !pr-0 !pt-[5px] !mr-0 ${errorClass}`}>
        {(touched || touchedEvent) && error && <p className="error_message">{error}</p>}
      </div>
    </>
  );
}

export default ListSelector;
