/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import Routes from "../../../../Config/Routes";
import { getLocalStorageUserData } from "../../../../Utils/commonUtils";

export const getUserCreationToastMssg = (error) => {
  const { response: { data: { status, message } = {} } = {} } = error;

  if (status === 403) {
    return message;
  }
  return "Something went wrong";
};

const getAllIds = (values = []) => values.map((val) => val?.id);

export const getPayload = (values, currentFormValues, props, configData) => {
  const {
    userData: {
      currentUser: { type, id },
    },
  } = props;
  let payload = {};
  let roleIds;
  let roleTagIds;
  if (type === "CUSTOMER") {
    roleIds = [values?.role?.id || values?.role?.value];
  } else {
    roleIds = getAllIds(values?.role);
    roleTagIds = getAllIds(values?.tags);
  }

  if (currentFormValues?.id) {
    payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      roleIds: roleIds || currentFormValues?.role?.value?.toUpperCase(),
      roleTagIds: roleTagIds || currentFormValues?.tags?.value?.toUpperCase(),
      id: currentFormValues?.id,
    };
  } else {
    payload = {
      email: values.email?.toLowerCase(),
      firstName: values.firstName,
      lastName: values.lastName,
      roleIds,
      roleTagIds,
      type,
    };
  }

  if (type === "PARTNER") {
    payload.partnerId = id;
    payload.customerSwitchConfig =
      configData?.map((i) => ({
        ...i,
        partnerId: id,
      })) || [];
  } else if (type === "CLOUDONOMICS") {
    payload.partnerConfig = configData;
  }

  return payload;
};

export const getUserAddEditRoutesFromRole = (item = {}) => {
  const { currentUser: { type = "" } = {} } = getLocalStorageUserData() || {};

  if (type === "PARTNER") {
    return {
      userRoute: Routes.PARTNERUSERMANAGEMENT,
      addUserRoute: Routes.PARTNERADDUSER,
      editUserRoute: `${Routes.PARTNEREDITUSER}?id=${item.id}`,
    };
  }

  return {
    userRoute: Routes.USERMANAGEMENT,
    addUserRoute: Routes.ADDUSER,
    editUserRoute: `${Routes.EDITUSER}?id=${item.id}`,
  };
};

export const preferencesUsersChecks = (obj) => {
  const {
    userId,
    firstnameValidations,
    lastnameValidations,
    stateEmailValidations,
    modifiedRoleValidations,
    modifiedRoleConfigCheckbox,
    userRole,
    tagsValidationsMultiselect,
  } = obj;
  const userData = getLocalStorageUserData();
  if (userData?.currentUser?.type === "PARTNER") {
    modifiedRoleConfigCheckbox.label = "Enable switch configuration for Customer";
  }
  if (!userId) {
    tagsValidationsMultiselect.triggerHandleChangeOnInit = false;
    modifiedRoleValidations.triggerHandleChangeOnInit = false;
    modifiedRoleConfigCheckbox.triggerHandleChangeOnInit = false;

    return userData?.currentUser?.type === "CUSTOMER"
      ? [firstnameValidations, lastnameValidations, stateEmailValidations, modifiedRoleValidations]
      : [
          firstnameValidations,
          lastnameValidations,
          stateEmailValidations,
          modifiedRoleValidations,
          tagsValidationsMultiselect,
          modifiedRoleConfigCheckbox,
        ];
  }

  tagsValidationsMultiselect.triggerHandleChangeOnInit = true;
  modifiedRoleValidations.triggerHandleChangeOnInit = true;
  modifiedRoleConfigCheckbox.triggerHandleChangeOnInit = true;

  if (userData?.currentUser?.type === "CUSTOMER") {
    if (userRole?.toUpperCase() === "OWNER") {
      return [firstnameValidations, lastnameValidations];
    }
    return [firstnameValidations, lastnameValidations, modifiedRoleValidations];
  }
  if (userRole?.toUpperCase() === "OWNER") {
    return [firstnameValidations, lastnameValidations, modifiedRoleConfigCheckbox];
  }

  return [
    firstnameValidations,
    lastnameValidations,
    modifiedRoleValidations,
    tagsValidationsMultiselect,
    modifiedRoleConfigCheckbox,
  ];
};

export const appendOptionCustomer = (MultiAddConfigState, value) => {
  MultiAddConfigState.forEach((configObject) => {
    const dropdownIndex = configObject.config.findIndex((item) => item.name.includes("partnerId"));
    if (dropdownIndex !== -1) {
      configObject.config[dropdownIndex].options.push(...value);
    }
  });
  return MultiAddConfigState;
};

export function cleanObjectArray(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => cleanObjectArray(item))
      .filter((item) => Object.keys(item).length > 0 || item.constructor === Object); // Keep non-empty objects or any non-object items
  }
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) {
        delete obj[key]; // Delete undefined keys
      } else {
        obj[key] = cleanObjectArray(obj[key]); // Recurse into sub-objects and arrays
      }
    });
    return obj;
  }
  return obj;
}

export function removeEmptyObjects(data) {
  // Check if data is an array
  if (Array.isArray(data)) {
    // If it's an array, apply removeEmptyObjects to each element recursively
    return data.map((item) => removeEmptyObjects(item)).filter((item) => item !== null);
  }
  if (typeof data === "object" && data !== null) {
    // If it's an object, recursively apply removeEmptyObjects to its values
    for (const key in data) {
      data[key] = removeEmptyObjects(data[key]);
    }
    // Delete keys with empty arrays or empty objects as values
    for (const key in data) {
      if (Array.isArray(data[key]) && data[key].length === 0) {
        delete data[key];
      } else if (
        typeof data[key] === "object" &&
        data[key] !== null &&
        Object.keys(data[key]).length === 0
      ) {
        delete data[key];
      }
    }
    // Return the object if it's not empty after cleaning
    return Object.keys(data).length > 0 ? data : null;
  }
  // For non-object items, keep them as they are
  return data;
}

export const modifyData = (data) => {
  const modifiedData = [...data];

  const modifyArrayObjects = (arr) => {
    arr.forEach((item) => {
      if (typeof item === "object") {
        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            modifyArrayObjects(item[key]);
          } else if (item[key] && typeof item[key] === "object") {
            modifyArrayObjects([item[key]]);
          } else if (key === "label" && item.label && item.value) {
            const itemClone = item;
            itemClone.id = item.value;
            itemClone.value = item.label;
            itemClone.checked = true;
          }
        });
      }
    });
  };
  modifyArrayObjects(Object.values(modifiedData));
  return modifiedData;
};

export const getCustomerList = async (formattedFormValues, callCustomerOption) => {
  const apiCallArray = [];
  const customerListMapping = {};
  const updateValues = formattedFormValues;

  Object.keys(formattedFormValues).forEach(async (item) => {
    if (item.startsWith("partnerId-")) {
      if (!customerListMapping[formattedFormValues[item].id]) {
        apiCallArray.push(callCustomerOption(formattedFormValues[item].id));

        customerListMapping[formattedFormValues[item].id] = {
          partnerKey: [item],
          partnerId: formattedFormValues[item].id,
          index: apiCallArray.length - 1,
          customerListKey: [`customerIds-options-${item.split("partnerId-").pop()}`],
        };
      } else {
        customerListMapping[formattedFormValues[item].id].partnerKey.push(item);
        customerListMapping[formattedFormValues[item].id].customerListKey.push(
          `customerIds-options-${item.split("partnerId-").pop()}`
        );
      }
    }
  });

  if (apiCallArray?.length) {
    const response = await Promise.all(apiCallArray);

    Object.keys(customerListMapping).forEach((partner) => {
      const mapping = customerListMapping[partner];
      const customerList = response[mapping.index]?.data?.data?.content || [];

      mapping.customerListKey.forEach((item) => {
        updateValues[item] = customerList.map((customer) => ({
          label: customer?.name,
          value: customer?.name,
          id: customer?.id,
          checked: false,
        }));
      });
    });
  }
};

export const checkEventType = (e) =>
  e.target.eventName === "blur" ||
  e.target.name === "blur" ||
  e.target.name === "addMore" ||
  e.target.customEvent === "custom:change" ||
  e.eventType === "blur";
