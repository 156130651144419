import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import "./style.scss";
import DialogComponent from "../../../Common/Modal/Dialog";
import { dialogHeaderHTML } from "./helper";

import { ReactComponent as TickIcon } from "../../../../Assets/icons/ic-tick.svg";
import DialogBodyHtml from "./components/dialogBodyHtml";

function CreditsOrdering(props) {
  const {
    config: { options },
    label,
    handleChange,
    className,
    name,
    disabled = false,
    value,
    renderTooltipDisclaimer,
    renderModalDisclaimer,
  } = props;

  const [formValue, setFormValue] = useState({});

  const [modalData, setModalData] = useState([]);

  const [open, setOpen] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    const updatedValue = value?.orderingOptions?.map((item) => ({
      text: item?.text || item?.value,
      order: item?.order || item?.id,
    }));
    setFormValue({ ...value });
    setModalData(updatedValue);
    setInitialData(updatedValue);
  }, [value?.orderingOptions]);

  useEffect(() => {
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      count: false,
      value: "no",
      orderingOptions: [],
    }));
  }, []);

  const radioChangeHandler = (item) => {
    setFormValue((prevFormValue) => {
      const newFormValue = {
        ...prevFormValue,
        value: item.value,
        count: false,
      };
      handleChange({ target: { name, value: newFormValue } });

      return newFormValue;
    });
  };

  const handleModalData = (newModalData) => {
    const {
      target: { value: modalValue },
    } = newModalData;
    setModalData(modalValue);
    setReviewed(false);
  };

  const saveOrder = () => {
    setOpen(!open);
    setReviewed(false);
    setFormValue({ ...formValue, count: true });
    setModalData(modalData);
    handleChange({
      target: { name, value: { ...formValue, count: true, orderingOptions: [...modalData] } },
    });
    setInitialData(modalData);
  };

  const resetOrder = () => {
    setModalData(initialData);
    setReviewed(false);
  };

  return (
    <div className={`credits-ordering-wrapper ${className}`}>
      <div
        className={`credits-ordering ${disabled && "disabled"} ${
          formValue?.value === "no" && "grey-out"
        }`}
      >
        <span className="credits-ordering-label">{label}</span>
        <div className="radio-group">
          {options.map((item) => (
            <label key={item.value}>
              <input
                type="radio"
                name="creditsOrdering"
                value={item.value}
                checked={item.value === formValue.value}
                onChange={() => radioChangeHandler(item)}
              />
              <span className="radio-label">{item.label}</span>
            </label>
          ))}
        </div>
        <Button
          onClick={() => setOpen(!open)}
          variant="outlined"
          disabled={formValue.value === "no"}
        >
          Apply Now
          {formValue?.count === true && (
            <div className="active-icon">
              <TickIcon />
            </div>
          )}
        </Button>
        {open && (
          <DialogComponent
            className="wrapper-support-configuration"
            showCloseButton={false}
            open
            header={dialogHeaderHTML()}
          >
            <DialogBodyHtml
              modalData={modalData}
              handleModalData={handleModalData}
              renderModalDisclaimer={renderModalDisclaimer}
              reviewed={reviewed}
              setReviewed={setReviewed}
              resetOrder={resetOrder}
              setOpen={setOpen}
              setModalData={setModalData}
              initialData={initialData}
              saveOrder={saveOrder}
            />
          </DialogComponent>
        )}
      </div>
      {formValue?.value === "yes" && (
        <div className="bottom-disclaimer">{renderTooltipDisclaimer}</div>
      )}
    </div>
  );
}

export default CreditsOrdering;
