import { createStore } from "redux";

// redux-persist implementation :
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./Reducer/RootReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["sidebarReducer", "MarketPlaceBulkUploadReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
persistStore(store);
export default store;
