import React from "react";
import forwardIcon from "../../Assets/icons/customer-creation-icons/forward.svg";
import forwardColoredIcon from "../../Assets/icons/customer-creation-icons/forward-colored.svg";
import backwardIcon from "../../Assets/icons/customer-creation-icons/backward.svg";
import backwardColoredIcon from "../../Assets/icons/customer-creation-icons/backward-colored.svg";
import Text from "../FormConfig/Form/Components/Text";
import { ReactComponent as MoveRightButtonActive } from "../../Assets/icons/MoveButtonActive.svg";
import { ReactComponent as MoveRightButtonInactive } from "../../Assets/icons/MoveButtonInactive.svg";
import { ReactComponent as MoveLeftButtonActive } from "../../Assets/icons/MoveButtonLeftActive.svg";
import { ReactComponent as MoveLeftButtonInactive } from "../../Assets/icons/MoveButtonLeftInactive.svg";
import TickIcon from "../../Assets/icons/ic-green-tick-large.svg";
import { getLocalStorageUserData } from "../../Utils/commonUtils";

export default function renderFlowIcons(
  leftSideSelectedItems,
  addItemToRightSide,
  rightSideSelectedItems,
  addItemToLeftSide
) {
  const { length: leftSideSelectedItemsLength } = leftSideSelectedItems;

  return (
    <>
      <div
        aria-hidden="true"
        onClick={addItemToRightSide}
        className={`icon display_flex ${leftSideSelectedItemsLength && "active"}`}
      >
        <img
          className="right-[-1px]"
          src={leftSideSelectedItemsLength ? forwardColoredIcon : forwardIcon}
          alt="forward"
        />
      </div>
      <div
        onClick={addItemToLeftSide}
        aria-hidden="true"
        className={`icon display_flex ${rightSideSelectedItems.length && "active"}`}
      >
        <img
          className="left-[-1px]"
          src={rightSideSelectedItems.length ? backwardColoredIcon : backwardIcon}
          alt="backward"
        />
      </div>
    </>
  );
}

export const renderFlowIconsV2 = (
  leftSideSelectedItems,
  addItemToRightSide,
  rightSideSelectedItems,
  addItemToLeftSide
) => (
  <>
    <div
      aria-hidden="true"
      onClick={addItemToRightSide}
      className={`${leftSideSelectedItems?.length && "active "} mb-[50px]`}
    >
      {leftSideSelectedItems?.length ? <MoveRightButtonActive /> : <MoveRightButtonInactive />}
    </div>
    <div
      onClick={addItemToLeftSide}
      aria-hidden="true"
      className={`${rightSideSelectedItems.length && "active"}`}
    >
      {rightSideSelectedItems.length ? <MoveLeftButtonActive /> : <MoveLeftButtonInactive />}
    </div>
  </>
);

export const renderLabel = (label) => (
  <Text text={label} size="small" type="!text-black tracking-[.012em]" className="mandatory" />
);

export const getSortedList = (arr) =>
  arr.sort((a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });

export const resetList = (itemFn, selectedItemsFn, list) => {
  itemFn(
    list.map((item) => ({
      ...item,
      checked: false,
    }))
  );
  selectedItemsFn([]);
};

export const fireHandleChange = (values, handleChange, name) => {
  const eventClone = {
    target: {
      name,
      value: values,
    },
  };
  handleChange(eventClone);
};

export const getLeftHeading = (rightHeading, rightSideItems, leftListHeader) => (
  <div className="flex justify-between cld_multiselect_heading">
    <p className="selector-heading">{rightHeading}</p>
    <p className="selected-item">
      {rightSideItems.length} {leftListHeader || "Added"}
    </p>
  </div>
);

export const resetItems = (
  listItems,
  selectedListItems,
  listItemsStateFn,
  selectedListItemsStateFn
) => {
  const updatedRightSideList = listItems.filter(
    (item) => !selectedListItems.filter((ele) => ele.value === item.value)?.length
  );

  /**
   * remove checked from left side items
   * so that when they are moved to right side
   * it's checked property is reset
   */
  selectedListItems.forEach((item) => {
    const clone = item;
    clone.checked = false;
  });
  listItemsStateFn(updatedRightSideList);
  selectedListItemsStateFn([]);

  return updatedRightSideList;
};

export const updateStructure = (config, statusList, currentAccount, setOpenModal, fromModel) => {
  const userData = getLocalStorageUserData();
  if (userData?.tokens?.moduleName !== "Billdesk") {
    return {};
  }
  return {
    ...config,
    options: [
      ...statusList.map((item) => ({
        id: item?.id,
        label: item?.type,
        postImage: (currentAccount?.customerTypeId === item?.id && { icon: TickIcon }) || "",
        value: item?.type,
      })),
    ],
    footer: !fromModel && (
      <div className="bg-[#F0F6FF] py-[10px] px-[10px] flex rounded-b-[3px] items-center justify-end border-t border-solid border-t-[#DDDFE8]">
        <div
          aria-hidden="true"
          onClick={() => {
            setOpenModal();
          }}
          className="text-[#0A3CA2] text-xs whitespace-nowrap flex items-center font-semibold cursor-pointer"
        >
          Create New Type
        </div>
      </div>
    ),
  };
};

export const updateStructureForModal = (
  config,
  statusList,
  currentAccount,
  setOpenModal,
  fromModel = true
) => {
  const userData = getLocalStorageUserData();
  if (userData?.tokens?.moduleName !== "Billdesk") {
    return {};
  }
  return {
    ...config,
    options: [
      ...statusList.map((item) => {
        if (currentAccount?.customerTypeId === item?.id) {
          return 0;
        }
        return { id: item?.id, label: item?.type, value: item?.type };
      }),
    ],
    footer: !fromModel && (
      <div className="bg-[#F0F6FF] py-[10px] px-[10px] flex rounded-b-[3px] items-center justify-end border-t border-solid border-t-[#DDDFE8]">
        <div
          aria-hidden="true"
          onClick={() => {
            setOpenModal();
          }}
          className="text-[#0A3CA2] text-xs whitespace-nowrap flex items-center font-semibold"
        >
          Create New Type
        </div>
      </div>
    ),
  };
};

export const changeStructure = (items) =>
  items.map((item) => ({
    label: item.accountId,
    value: `${item.accountName} (${item.accountId})`,
    checked: false,
  }));
