import React, { useState } from "react";
import { noop } from "../../../../Utils/commonUtils";
import TextClipper from "./ShowMore";
import "./input.scss";

// eslint-disable-next-line complexity
export default function TextArea({
  className = "",
  label: textLabel,
  handleChange = noop,
  error,
  value = "",
  name,
  placeHolder,
  resize = false,
  rows,
  maximum,
  touched,
  onBlurHandler = () => {},
  labelClass,
  inputFieldClass,
  showCounter = true,
  highlightLabel = true,
  maxContentPosition,
  maxLength = null,
  disabled = false,
  readOnly = false,
  showMoreComponent = false,
}) {
  const [focus, setFocus] = useState(false);
  const [counter, setCounter] = useState(maximum - value.length);
  const [touchedEvent, setTouchedEvent] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = (event) => {
    setFocus(false);
    onBlurHandler(event);
    setTouchedEvent(true);
  };

  const onChangeHandler = (event) => {
    const {
      target: { value: targetValue = "" },
    } = event;

    // eslint-disable-next-line no-unsafe-optional-chaining
    const itemLeft = maximum - targetValue?.length;
    setCounter(itemLeft);
    handleChange(event);
  };
  return (
    <div
      className={`${className} text-area-wrapper ${readOnly ? "readonly_text_area" : ""} ${
        (highlightLabel && focus && "active") || ""
      }`}
    >
      <label className={`text-area-label text-black text-xs ${labelClass}`} htmlFor={name}>
        {textLabel}
      </label>
      <p className="MuiInputBase-root mt-1">
        {showMoreComponent ? (
          <div>
            <TextClipper data={value} wordLimit={100} />
          </div>
        ) : (
          <textarea
            name={name}
            placeholder={placeHolder}
            className={`text-area ${(!rows && "fixed-height") || ""} ${value && "filled"} ${
              (!resize && " resize-none") || ""
            } ${inputFieldClass} ${className} ${
              ((touched || touchedEvent) && error && "error_box") || ""
            }  ${maxContentPosition === "inside" ? "relative" : ""}`}
            onChange={onChangeHandler}
            rows={rows}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            maxLength={maxLength}
            disabled={disabled || readOnly}
          >
            {value}
          </textarea>
        )}

        {!readOnly && showCounter && (
          <p
            className={`flex justify-end counter ${
              maxContentPosition === "inside" ? "absolute right-[4px] bottom-[10px]" : ""
            }`}
          >
            <span className={`${maxContentPosition === "inside" ? "!bg-[#888888]" : ""}`}>
              {counter}/{maximum}
            </span>
          </p>
        )}
      </p>
      {touched && error && <p className="error_message !mt-[-17px]">{error}</p>}
    </div>
  );
}
