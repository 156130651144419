import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// importing basic css global
import "./index.css";
import "./main.scss";
import "./reset.css";
import "./styleguide/commonclass.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Route configurations
import { connect } from "react-redux";
import PrivateRoute from "./Config/PrivateRoute";
import PrivateRouteConfig from "./Config/PrivateRouteConfig";
import PublicRoute from "./Config/PublicRoute";
import PublicRouteConfig from "./Config/PublicRouteConfig";
import ErrorBoundary from "./Components/Common/ErrorBoundary/ErrorBoundary";
import NoAuthRouteConfig from "./Config/NoAuthRouteConfig";
import NoAuthRoute from "./Config/NoAuthRoute";
import { getCookie } from "./Utils/commonUtils";
import { createStrapiToken } from "./Services/Strapi";
import Loader from "./Components/Common/Loader";

// FusionCharts.options.license({
//   key: process.env.REACT_APP_API_KEY_CHART,
//   creditLabel: false,
// });

// ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, treemap, FusionTheme);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const strapiToken = getCookie("strapiToken");
      if (strapiToken === "undefined" || strapiToken === "null" || !strapiToken) {
        await createStrapiToken();
      }
      this.setState({ isLoading: false });
    } catch (error) {
      console.log("app.js error", error);
    }
  }

  render() {
    const {
      state: { isLoading },
    } = this;

    if (!process.env.REACT_APP_ENABLE_LOGS) {
      console.log = () => {};
      console.error = () => {};
      console.warn = () => {};
    }
    if (isLoading) return <Loader popup />;
    return (
      <ErrorBoundary>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Routes>
              {NoAuthRouteConfig.map((routeConfig) => (
                <Route
                  path={routeConfig.path}
                  key={routeConfig}
                  element={
                    <NoAuthRoute>
                      <routeConfig.component />
                    </NoAuthRoute>
                  }
                />
              ))}

              {PublicRouteConfig.map((routeConfig) => (
                <Route
                  path={routeConfig.path}
                  key={routeConfig}
                  element={
                    <PublicRoute>
                      <routeConfig.component />
                    </PublicRoute>
                  }
                />
              ))}

              {PrivateRouteConfig.map((routeConfig) => (
                <Route
                  path={routeConfig.path}
                  key={routeConfig}
                  exact={false}
                  element={
                    <PrivateRoute>
                      <routeConfig.component />
                    </PrivateRoute>
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </BrowserRouter>
        <ToastContainer className="toast toast-V2" hideProgressBar />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer.userData,
});

export default connect(mapStateToProps)(App);
