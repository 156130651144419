// function getReportList() {
//   const obj = {};
//   const Details = JSON.parse(localStorage.getItem("TagList"));
//   if (Details !== null) {
//     for (const key of Details) {
//       obj[key.name] = key.name;
//     }
//     return obj;
//   }
// }

export default {
  LOGIN: "/",
  FORGOTPASSWORD: "/forgotpassword",
  RESETPASSWORD: "/resetpassword",
  SINGUP: "/signup",
  REGISTER: "/signup",
  RESENDEMAIL: "/resendemail",
  MARKETPLACE: "/marketplace",
  PAGENOTFOUND: "/404",
  CONFIRMUSER: "/confirmuser",
  CONFIRM_USER_OLD: "/confirmUser",
  CONFIRM_CUSTOMER: "/confirmcustomer",
  DASHBOARD: "/dashboard",
  USERMANAGEMENT: "/dashboard/users",

  CREATETEMPLATE: "/dashboard/template/create",

  VIRTUALRESERVATIONSCART: "/dashboard/customers/purchase-reservations",

  CONFIGURE_FLAT_DISCOUNT: "/dashboard/customers/configure-flat-discount",
  CONFIGURE_GENERIC_DISCOUNT: "/dashboard/customers/configure-flat-discount",

  CONFIGURE_CHARGES_AND_REFUND: "/dashboard/customers/configure-charges-and-refunds",
  VIRTUALRESERVATIONSCARTDETAIL: "/dashboard/customers/purchase-reservations/cart",

  PARTNERUSERMANAGEMENT: "/dashboard/partner/users",
  PARTNER_CUSTOMER_USER_MANAGEMENT: "/dashboard/partner/customer/users",
  SAVINGSACHIEVED: "/dashboard/savingsdelivered",
  POTENTIALSAVINGDETAILS: "/dashboard/potentialsavings/details",

  POTENTIALSAVING: "/dashboard/potentialsavings",
  MISSEDSAVING: "/dashboard/missedsavings",
  ADDUSER: "/dashboard/users/create",
  PARTNERADDUSER: "/dashboard/partner/users/create",
  EDITUSER: "/dashboard/users/edit",
  PARTNEREDITUSER: "/dashboard/partner/users/edit",
  CUSTOMERMANAGEMENT: "/dashboard/customers",
  DISCOUNT_TEMPLATE: "/dashboard/configure-discount-templates",
  ADDCUSTOMER: "/dashboard/customers/create",
  EDITCUSTOMER: "/dashboard/customers/edit",
  CUSTOMERDETAILS: "/dashboard/customers/details",
  CLOUDFRONTPRIVATEPRICING: "/dashboard/customers/edit/cloudfrontpricing",
  CLOUDFRONTPRIVATEPRICINGOLD: "/dashboard/customers/edit/cloudfront",
  CONFIGUREDISCOUNT: "/configure-discounts/:discountTypeParam/:discountSchemeParam",
  CONFIGURE_EDP_DISCOUNT: "/dashboard/customers/configure-edp-discount",
  CONFIGUREDISCOUNT_NAV: "/dashboard/configure-discounts",
  PARTNERMANAGEMENT: "/dashboard/partnermanagement",
  ADDPARTNER: "/dashboard/partnermanagement/create",
  EDITPARTNER: "/dashboard/partnermanagement/edit",
  PAYERMANAGEMENT: "/dashboard/payeraccounts",
  ADDPAYER: "/dashboard/payeraccounts/create",
  EDITPAYER: "/dashboard/payeraccounts/edit",
  INVOICE: "/dashboard/invoice",
  INVOICELISTING: "/dashboard/invoice/listing",
  INVOICESETTINGS: "/dashboard/invoice/settings",
  PARTNERSETTINGS: "/dashboard/partnersettings",
  VIRTUALRESERVATIONS: "/dashboard/virtual-reservations",
  PLANSELECTION: "/dashboard/plans-selection",
  UPDATEPLANSELECTION: "/plans-selection/customer/:customerName/:customerId",
  INVOICEGENERATION: "/dashboard/invoice/generateinvoices",
  QUOTEGEN: "/dashboard/quote-generator",
  DASHBOARDCONTROLGRID: "/dashboard/dashboardcontrolgrid",
  MODULE_CONTROL_GRID: "/dashboard/modulecontrolgrid",
  PARTNERDASHBOARDCONTROLGRID: "/dashboard/partner/dashboardcontrolgrid",
  PARTNER_MODULE_CONTROL_GRID: "/dashboard/partner/modulecontrolgrid",
  BILLINGSUMMARY: "/dashboard/billingsummary",
  COSTEXPLORER: "/dashboard/costexplorer",
  COSTANALYSIS: "/dashboard/costanalysis",
  BILLINGCONSOLE: "/dashboard/billingconsole",
  ACCOUNTWISEBREAKUP: "/dashboard/accountwisebreakup",
  RESOURCEGROUPWISEBREAKUP: "/dashboard/resourcegroupwisebreakup",
  SUBSCRIPTIONWISEBREAKUP: "/dashboard/subscriptionwisebreakup",
  PROJECTWISEBREAKUP: "/dashboard/projectwisebreakup",
  COST_BREAKUP_STORAGE: "/dashboard/storage",
  INSTANCE_COST_STORAGE: "/dashboard/instanceCost",
  COST_BREAKUP_SERVERLESS: "/dashboard/serverless",
  COST_BREAKUP_SECURITY: "/dashboard/security",
  COST_BREAKUP_MIGRATION_TRANSFER: "/dashboard/migration-transfer",
  COST_BREAKUP_CONTAINERS: "/dashboard/containers",
  COST_BREAKUP_DATABASE: "/dashboard/database",
  COST_BREAKUP_DATABASE_OLD: "/dashboard/databaseOld",
  COST_BREAKUP_MANAGED_DATABASE: "/dashboard/managed-database",
  COST_BREAKUP_LOGGING_AND_MONITORING: "/dashboard/logging-monitoring",
  COST_BREAKUP_ANALYTICS: "/dashboard/analytics",
  COST_BREAKUP_MANAGEMENT: "/dashboard/cloudwatch",
  COST_BREAKUP_MEDIA_SERVICES: "/dashboard/mediaservices",
  COST_BREAKUP_DATA_TRANSFER: "/dashboard/dataTransfer",
  COST_BREAKUP_DATA_TRANSFER_OLD: "/dashboard/dataTransferOld",
  CUSTOMER_HEATH_CHECK: "/dashboard/customers-health",
  CUSTOMER_METERING_RECORDS: "/dashboard/customer-metering-record",
  CUSTOMER_INVOICES_RECORDS: "/dashboard/customer-billing-record",
  COST_BREAKUP_NETWORK_CONTENT_DELIEVERY: "/dashboard/networking-contentdelivery",

  // REPORTDTO: "/dashboard/reports/tag_1",
  // REPORTDTO2: "/dashboard/reports/resource_tags_user_created_by",

  RICOVERAGE: "/dashboard/coverage",
  RESERVATIONSUMMARY: "/dashboard/summary",
  RESERVATIONSUTILIZATION: "/dashboard/utilization",
  RECOMMENDATIONS: "/dashboard/recommendation",
  // NOTIFICATIONSINTEGRATION: "/dashboard/integrations",
  NOTIFICATIONSINTEGRATION: "/dashboard/notification",
  NOTIFICATIONSBUGETALERT: "/dashboard/budgetalert",
  NOTIFICATIONSBUGETALERTDAILY: "/dashboard/budgetalert/daily",
  NOTIFICATIONSBUGETALERTCREATE: "/dashboard/budgetalert/create",
  NOTIFICATIONSBUGETALERTEDIT: "/dashboard/budgetalert/edit",
  NOTIFICATIONSBUGETALERTCLONE: "/dashboard/budgetalert/clone",

  NOTIFICATIONSBUGETALERTMONTHLY: "/dashboard/budgetalert/monthly",
  ADMINAWSCONSOLE: "/dashboard/awsconsole",
  ADMINNETWORKRESOURCE: "/dashboard/admin/idlenetworkresources",
  ADMINMONTHLYREPORT: "/dashboard/admin/monthlyreport",
  ADMINCUDOSS3: "/dashboard/simple-storage-service",
  ADMIN_CUDOS_DATA_TRANSFER: "/dashboard/datatransfersummary",
  INSTANCES_RECOMMENDATIONS: "/dashboard/recommendation/instances",
  FINOPS_INSTANCES_RECOMMENDATIONS: "/dashboard/finops/recommendation/instances",
  CLOUDFRONT_RECOMMENDATIONS: "/dashboard/recommendation/cloudFront",
  FINOPS_CLOUDFRONT_RECOMMENDATIONS: "/dashboard/finops/recommendation/cloudFront",
  S3_RECOMMENDATIONS: "/dashboard/recommendation/s3",
  FINOPS_S3_RECOMMENDATIONS: "/dashboard/finops/recommendation/s3",
  EBS_RECOMMENDATION: "/dashboard/recommendation/ebs",
  FINOPS_EBS_RECOMMENDATION: "/dashboard/finops/recommendation/ebs",
  EBS_SNAPSHOT_RECOMMENDATION: "/dashboard/recommendation/ebs-snapshot",
  FINOPS_EBS_SNAPSHOT_RECOMMENDATION: "/dashboard/finops/recommendation/ebs-snapshot",
  UPGRADE_VERSION_RECOMMENDATION: "/dashboard/recommendation/upgrade-version",
  FINOPS_UPGRADE_VERSION_RECOMMENDATION: "/dashboard/finops/recommendation/upgrade-version",
  CUSTOMER_WISE_RECOMMENDATION: "/dashboard/finops/recommendation/customer-wise",
  CLB_RECOMMENDATION: "/dashboard/recommendation/clb",
  FINOPS_CLB_RECOMMENDATION: "/dashboard/finops/recommendation/clb",
  IDLE_NETWORK_RECOMMENDATION: "/dashboard/recommendation/idle-network",
  FINOPS_IDLE_NETWORK_RECOMMENDATION: "/dashboard/finops/recommendation/idle-network",
  PREFERENCES: "/dashboard/preferences",
  PREFERENCES_MAV: "/dashboard/preferences/mav",
  PREFERENCES_MPV: "/dashboard/preferences/payeraccounts",
  PREFERENCES_TAGS: "/dashboard/preferences/tags",
  PREFERENCES_PASSWORD: "/dashboard/preferences/password",
  PREFERENCES_USER: "/dashboard/preferences/user",
  PREFERENCES_USER_ADD: "/dashboard/preferences/user/create",
  PREFERENCES_ONBOARDING_SUMMARY: "/dashboard/preferences/onboardingSummary",
  AWSACCOUNTCREATION: "/dashboard/awsaccount",
  RIOPSONBOARD: "/dashboard/awsaccount/riops-onboard",
  AWS_RESELLER_REGISTRATION: "/dashboard/awsaccount/aws-reseller-registration",
  AZURE_LENS_ACCOUNT_ONBOARDING: "/dashboard/azure-lens-accountonboarding",
  AZURE_LENS_ACCOUNT_ONBOARDING_Manual: "/dashboard/azure-lens-accountonboarding/manual",
  TEMPLATE_ON_DEMAND_DISCOUNTS: "/dashboard/template/on-demand-discounts",
  CLONE_DISCOUNT: "/dashboard/template/on-demand-discounts/clone",
  EDIT_DISCOUNT: "/dashboard/template/on-demand-discounts/edit",
  LENS_ACCOUNT_ONBOARDING: "/dashboard/lens-accountonboarding",
  EDIT_DISCOUNT_TEMPLATES: "/dashboard/configure-discount-templates/edit",
  MONTHLYREPORTS: "/dashboard/monthly-report",
  DAILY_SUMMARY_ALERT: "/dashboard/budgetalert/summary-detailed",
  DAILY_SUMMARY_EDIT: "/dashboard/budgetalert?selected=Daily%20Summary%20Report",
  CreateUtilization: "/dashboard/budgetalert/create-utilization",
  UtilizationListing: "/dashboard/budgetalert?selected=RI%20Utilization",
  CONFIGURE_RESERVATION: "/dashboard/customers/reservation-discount",
  EXPIRY_DETAILED_ALERT: "/dashboard/budgetalert/expiry-detailed",
  EXPIRY_EDIT: "/dashboard/budgetalert?selected=RI%20Expiry",
  CONFIGURE_CREDITS: "/dashboard/customers/configure-credits",
  VRI_ADDITIONAL_DISCOUNT: "/dashboard/customers/configure-additional-discount",
  AZURE_SUBSCRIPTION_ACTIVATION: "/subscription-activation/:subscriptionId",
  AZURE_SUBSCRIPTION_ERROR: "/subscription-error",
  AZURE_ONBOARDING_ACTIVATION: "/confirm-details",
  AZURE_SSO_LOGIN: "/sso-login",
  PRIVATE_PRICING: "/dashboard/customers/edit/privatepricing",
  PREVIEW_CUSTOMER: "/dashboard/customers/preview",
  COST_USAGE_REPORT_LIST: "/dashboard/cost-usage-report",
  CUR_CREATE: "/dashboard/cost-usage-report/create",
  THANKYOU_PAGE: "/dashboard/thankyou",
  HOURLY_DASHBOARD_COMPUTE: "/dashboard/hourly-dashboard/compute",
  HOURLY_DASHBOARD_RDS: "/dashboard/hourly-dashboard/rds",
  HOURLY_DASHBOARD_ELASTICACHE: "/dashboard/hourly-dashboard/elasticache",
  HOURLY_DASHBOARD_OPENSEARCH: "/dashboard/hourly-dashboard/opensearch",
  HOURLY_DASHBOARD_REDSHIFT: "/dashboard/hourly-dashboard/redshift",
  EDIT_PERMISSIONS: "/dashboard/permissions/edit",
  EDIT_PERMISSIONS_TAGS: "/dashboard/tags/edit",
  PERMISSIONS_TAGS_LIST: "/dashboard/tags",
};
