import React from "react";
import moment from "moment";
import { ReactComponent as VisibilityIcon } from "../../Assets/icons/eye-open.svg";
import { ReactComponent as VisibilityOffIcon } from "../../Assets/icons/eye-off.svg";
import { ReactComponent as VisibilityOffSlackPassword } from "../../Assets/icons/Eye-Closed.svg";
import { ReactComponent as VisibilityOnSlackPassword } from "../../Assets/icons/mdi_eye.svg";
import { ReactComponent as CsvIcon } from "../../Assets/icons/ic-csv.svg";
import { ReactComponent as PdfIcon } from "../../Assets/icons/ic-pdf.svg";
import UsernameIcon from "../../Assets/icons/feather-username.svg";
import { ReactComponent as LinkIcon } from "../../Assets/icons/icon-link.svg";
import PartnerSettingsPasswordIcon from "../../Assets/icons/lock.svg";
import MagnifyingGlassIcon from "../../Assets/icons/magnifying-glass.svg";
import Patterns from "./Form/patterns";
import { BUDGET_ALERT_TYPE, isPreferences } from "../../Constants/Constant";
import {
  CUSTOMER_TRANSFER_LIST_CONFIG,
  accountIdMAVMsgs,
  userMAVMsgs,
} from "../Dashboard/MAV_Preferences/Constants";
import noMavDataIcon from "../../Assets/icons/noMavDataIcon.svg";
import { getPartnerInvoideDate } from "../Dashboard/CustomerManagement/CreateCustomer/PrivatePricingAgreements/util";
import Dropdown from "./Form/Components/DropDown";
import MultiSelect from "./Form/Components/MultiSelect";
import TextArea from "./Form/Components/TextArea";
import RenderHTML from "./Form/RenderHTML";
import "./styles.scss";

const emailRules = {
  rules: {
    required: true,
    pattern: Patterns.email,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
};

export const exportNameRules = {
  rules: {
    required: true,
    pattern: Patterns.only128underscoreCapitalRegex,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
};
export const legacyExportNameRules = {
  rules: {
    required: true,
    pattern: Patterns.alphaNumericSpecialCharacters,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
};
export const dataExportS3BucketRules = {
  rules: {
    required: true,
    pattern: Patterns.s3BucketNameValidation,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
};

export const dataExportS3PathPrefixRules = {
  rules: {
    required: true,
    pattern: Patterns.s3PathPrefixRegex,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
};

export const dataExportS3AccountIDRules = {
  rules: {
    required: true,
    pattern: Patterns.bucketAccountRegex,
  },
  messages: {
    required: "This field is required",
    pattern: "Please enter a valid {{fieldName}}",
  },
};

const dateRules = {
  rules: {
    required: true,
    minimum: 10,
    maskingPattern: {
      elementSize: "CB/AB/DEBB",
      translationPattern: {
        M: {
          pattern: "[0-12]",
        },
        A: {
          pattern: "[0-3]",
        },
        B: {
          pattern: "[0-9]",
        },
        C: {
          pattern: "[0-1]",
        },
        D: {
          pattern: "[1-2]",
        },
        E: {
          pattern: "[0,9]",
        },
      },
    },
    pattern: Patterns.dateRegexMMDDYYYY,
  },
  messages: {
    required: "This field is required",
    maskingPattern: "Please enter a valid date",
    minimum: "Please enter a valid {{fieldName}}",
    pattern: "Please enter a valid date",
  },
  onChange: {
    maskingPattern: true,
  },
};

export const requiredRules = {
  rules: {
    required: true,
  },
  messages: {
    required: "This field is required",
  },
};

export const requiredRulesExport = {
  rules: {
    required: false,
  },
  messages: {
    required: "",
  },
};

export const optionalRules = {
  rules: {
    required: false,
  },
  messages: {
    required: "",
  },
};

const nameValidations = {
  rules: {
    required: true,
    maximum: 30,
    minimum: 2,
  },
  messages: {
    required: "This field is required",
    maximum: "Maximum {{digit}} characters are allowed",
    minimum: "Minimum {{digit}} characters are allowed",
  },
};

const slackGroupNameValidation = {
  rules: {
    required: true,
    maximum: 80,
    minimum: 2,
  },
  messages: {
    required: "This field is required",
    maximum: "Maximum {{digit}} characters are allowed",
    minimum: "Minimum {{digit}} characters are allowed",
  },
};

export const EDpnameValidations = {
  rules: {
    required: true,
    maximum: 50,
    minimum: 2,
  },
  messages: {
    required: "This field is required",
    maximum: "Maximum {{digit}} characters are allowed",
    minimum: "Minimum {{digit}} characters are allowed",
  },
};

export const EntityNameValidations = {
  rules: {
    required: true,
    maximum: 30,
    minimum: 2,
    pattern: Patterns.nameRegex,
  },
  messages: {
    required: "This field is required",
    maximum: "Maximum {{digit}} characters are allowed",
    minimum: "Minimum {{digit}} characters are allowed",
    pattern: "Please enter a valid {{fieldName}}",
  },
};

export const PartnerNameValidation = {
  rules: {
    required: true,
    maximum: 30,
    minimum: 2,
    pattern: Patterns.partnerNameRegex,
  },
  messages: {
    required: "This field is required",
    maximum: "Maximum {{digit}} characters are allowed",
    minimum: "Minimum {{digit}} characters are allowed",
    pattern: "Please enter a valid {{fieldName}}",
  },
};

export const PaginationRequiredValidation = {
  rules: {
    required: true,
    minimum: 1,
  },
  messages: {
    required: "Selected count must be atleast 1",
    minimum: "Selected count must be atleast 1",
  },
};

export const passwordRules = {
  type: "Label",
  name: "passwordrules",
  placeHolder:
    "Use 8-20 characters with atleast one uppercase, lowercase, a number and a symbol from !@#$%^&*",
  className: "small text-[#525252] leading-[14px] items-center p-0 w-[350px] clear-both pt-2",
  validations: optionalRules,
};

export const togglepasswordValidations = {
  type: "checkbox",
  name: "togglepasswords",
  label: "Show Password",
  className: "pt-1.5",
  validations: optionalRules,
};

export const excludeValidations = {
  type: "toggleInput",
  name: "exclude",
  label: "Exclude Only",
  className: "pt-1.5 mt-3 configure_template_excludeOnly",
  validations: optionalRules,
};

export const emailValidations = {
  type: "text",
  name: "email",
  label: "Email",
  inputClass: "email",
  placeHolder: "Business Email",
  validations: emailRules,
};

export const passwordValidations = {
  type: "password",
  name: "password",
  placeHolder: "Password",
  label: "Password",
  inputClass: "password",
  toggleIcon: false,
  selectedVisibilityIcon: <VisibilityOffIcon />,
  iconList: {
    password: <VisibilityOffIcon />,
    text: <VisibilityIcon />,
  },
  validations: JSON.parse(JSON.stringify(requiredRules)),
};
export const previousPasswordValidations = {
  type: "password",
  name: "previouspassword",
  placeHolder: "Previous",
  label: "Previous Password",
  inputClass: "password ",
  selectedVisibilityIcon: <VisibilityOffIcon />,
  iconList: {
    password: <VisibilityOffIcon />,
    text: <VisibilityIcon />,
  },
  validations: JSON.parse(JSON.stringify(requiredRules)),
};
export const newPasswordValidations = {
  type: "password",
  name: "newpassword",
  placeHolder: "New",
  label: "New Password",
  inputClass: "password ",
  selectedVisibilityIcon: <VisibilityOffIcon />,
  iconList: {
    password: <VisibilityOffIcon />,
    text: <VisibilityIcon />,
  },
  validations: JSON.parse(JSON.stringify(requiredRules)),
};
export const confirmPasswordValidations = {
  type: "password",
  name: "confirmpassword",
  placeHolder: "Confirm",
  label: "Confirm Password",
  inputClass: "password ",
  selectedVisibilityIcon: <VisibilityOffIcon />,
  iconList: {
    password: <VisibilityOffIcon />,
    text: <VisibilityIcon />,
  },
  validations: JSON.parse(JSON.stringify(requiredRules)),
};

export const passwordResetTokenValidations = {
  type: "number",
  name: "passwordresettoken",
  placeHolder: "OTP",
  label: "Password Reset Token",
  inputClass: "passwordresettoken",
  validations: requiredRules,
};
export const firstnameValidations = {
  type: "text",
  name: "firstName",
  placeHolder: isPreferences() ? "Add First Name" : "Enter First Name",
  label: "First Name",
  inputClass: "firstname",
  validations: EntityNameValidations,
  filledStyle: "!text-[14px]",
  labelClass: "mandatory",
  showLabel: "true",
  parentClass: "col-md-3 mb-[20px] mr-[20px]",
};

export const companynameValidations = {
  type: "text",
  name: "companyName",
  placeHolder: "Enter Company Name",
  label: "Company Name",
  inputClass: "companyname",
  validations: {
    ...EntityNameValidations,
    rules: {
      ...EntityNameValidations.rules,
      pattern: Patterns.companyNameRegex,
    },
  },
  filledStyle: "!text-[14px]",
  labelClass: "mandatory",
  showLabel: "true",
  parentClass: "mt-5",
};

export const lastnameValidations = {
  type: "text",
  name: "lastName",
  placeHolder: isPreferences() ? "Add Last Name" : "Enter Last Name",
  inputClass: "lastname",
  label: "Last Name",
  validations: EntityNameValidations,
  labelClass: "mandatory",
  showLabel: "true",
  filledStyle: "!text-[14px]",
  parentClass: "col-md-3 mb-[20px] clear-next-elem",
};

export const roleValidations = {
  type: "select",
  name: "role",
  placeHolder: "Select Role",
  className: "w-[347px] mt-5",
  label: "User Role",
  topLabel: {
    text: "Role",
    fontSize: "small",
    class: "!text-black mandatory !leading-[12px] !mt-0",
  },
  validations: requiredRules,
  enableFilter: true,
  options: [],
  resetValues: ["configForPartner"],
  revalidation: true,
  triggerHandleChangeOnInit: true,
  customEvent: {
    active: false,
    resetInfo: {
      key: "active",
      value: false,
    },
    name: "show-confirmation-box",
    value: {
      key: "enableSwitchConfig",
      comparisonValue: false,
    },
  },
  enableDisable: {
    valueKey: "enableSwitchConfig",
    enableSwitchConfig: [
      {
        id: "configForPartner",
        defaultValue: false,
      },
    ],
  },
};

export const roleConfigCheckbox = {
  type: "checkbox",
  name: "configForPartner",
  label: "Enable switch configuration for partner",
  className: "pt-1.5 clear-both",
  validations: optionalRules,
  revalidation: true,
  disabled: true,
  triggerHandleChangeOnInit: true,
  info: "Disable will delete configurations",
  customEvent: {
    active: true,
    name: "show-confirmation-box",
    value: false,
  },
  resetDefaultValue: false,
  enableDisable: {
    true: [
      {
        id: "role",
        updateKey: "customEvent",
        customEvent: {
          active: true,
          resetInfo: {
            key: "active",
            value: false,
          },
          name: "show-confirmation-box",
          value: {
            key: "enableSwitchConfig",
            comparisonValue: false,
          },
        },
      },
      {
        id: "tags",
        updateKey: "customEvent",
        customEvent: {
          active: true,
          resetInfo: {
            key: "active",
            value: false,
          },
          name: "show-confirmation-box",
          value: {
            key: "enableSwitchConfig",
            comparisonValue: false,
          },
        },
      },
    ],
    false: [
      {
        id: "role",
        updateKey: "customEvent",
        customEvent: {
          active: false,
          resetInfo: {
            key: "active",
            value: true,
          },
          name: "show-confirmation-box",
          value: {
            key: "enableSwitchConfig",
            comparisonValue: false,
          },
        },
      },
      {
        id: "tags",
        updateKey: "customEvent",
        customEvent: {
          active: false,
          resetInfo: {
            key: "active",
            value: true,
          },
          name: "show-confirmation-box",
          value: {
            key: "enableSwitchConfig",
            comparisonValue: false,
          },
        },
      },
    ],
  },
};

export const discountEDPTypeValidations = {
  type: "select",
  name: "templateType",
  placeHolder: "Select Template Type",
  className: "!mb-[22px] !pr-2.5 !w-[347px] inline-block template_type",
  label: "Template Type",
  topLabel: { text: "Template Type", fontSize: "small", class: "!text-black mandatory" },
  validations: requiredRules,
  enableFilter: { placeHolder: "Search" },
  options: [],
};

export const discountEDPTemplateValidations = {
  type: "select",
  name: "templateType",
  placeHolder: "Select Template Type",
  enableFilter: { placeHolder: "Search" },
  className: "!mb-[24px] !pr-2.5 !w-[347px] inline-block template_type !mb-[14px]",
  label: "Template Type",
  topLabel: {
    text: "Template Type",
    fontSize: "small",
    class: "!text-black mandatory mb-[9px]",
  },
  validations: requiredRules,
  options: [],
};

export const serviceFormElement = {
  type: "select",
  name: "service",
  placeHolder: "Select Service",
  className: "!w-[488px] mt-5",
  label: "Select Service",
  options: [],
  validations: requiredRules,
  topLabel: { text: "Select Service", fontSize: "small", class: "!text-black" },
};

export const partnerurlLabel = {
  type: "Label",
  name: "partnerurllabel",
  placeHolder: "Preferred Partner URL",
  label: "Choose what kind of partner URL you need.",
  tooltipPlacement: "bottom-start",
  className: "label primary medium flex items-center p-0 mt-6 mb-[10px] w-full",
  validations: optionalRules,
};

export const partnerLogoLabel = {
  type: "Label",
  name: "partnerlogolabel",
  label:
    "Uploaded Logo, will be used for display at various places in portal, Like Header, Login pages etc.",
  tooltipPlacement: "top-start",
  placeHolder: "Upload Logo",
  className: "label primary medium flex items-center p-0 mt-5 mb-4 w-full",
  validations: optionalRules,
  tooltipOtherStyles: { whiteSpace: "nowrap", maxWidth: "inherit" },
};

export const partnernameValidations = {
  type: "text",
  name: "name",
  placeHolder: "Enter Partner Name",
  label: "Partner Name",
  inputClass: "partnername",
  filledStyle: "!text-[14px]",
  validations: PartnerNameValidation,
  showLabel: true,
  labelClass: "mandatory !text-[11px] !font-medium",
  parentClass: " mb-[24px] col-md-4 mb-[24px] !w-[346px]",
};

export const partnerDisplayNameValidations = {
  type: "text",
  name: "partnerDisplayName",
  placeHolder: "Enter Partner Display Name",
  label: "Partner Display Name",
  inputClass: "displaypartnername",
  filledStyle: "!text-[14px]",
  validations: {
    rules: {
      maximum: 30,
      minimum: 2,
      pattern: Patterns.nameRegex,
    },
    messages: {
      maximum: "Maximum {{digit}} characters are allowed",
      minimum: "Minimum {{digit}} characters are allowed",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
  showLabel: true,
  labelClass:
    "suffix after:content-['(Optional)'] after:ml-0.5 after:text-[#525252] small bold primary text-field !text-[11px] !font-medium",
  parentClass: " mb-[24px] col-md-4 mb-[24px] ml-5 !w-[346px]",
};

export const cloudonomicDomainValidations = {
  type: "text",
  name: "cloudonomicDomain",
  showLabel: true,
  label: "Cloudonomic Domain",
  labelClass: "suffix small primary text-field mandatory !text-[11px] !font-medium",
  placeHolder: "Enter Sub Domain",
  inputClass: "mt-[5px] text-xs !pr-[116px]",
  filledStyle: "!text-[14px]",
  parentClass: "cloudonomic-domain col-md-4 p-0 mb-[24px] !w-[346px]",
  suffix: {
    icon: <LinkIcon className=" inline ml-1" />,
    text: `.${process.env.REACT_APP_DOMAIN}`,
    style: "absolute text-[11px] text-[#337AB7] bottom-[20px] right-[10px] !font-medium",
  },
  size: "small",
  prefixIcon: <LinkIcon className=" text-[#525252] inline mr-1" />,
  validations: {
    rules: {
      required: true,
      maximum: 30,
      minimum: 2,
      pattern: Patterns.domainRegex,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} characters are allowed",
      minimum: "Minimum {{digit}} characters are allowed",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
};
export const customDomainValidations = {
  type: "text",
  name: "customdomain",
  placeHolder: "Enter Custom Domain",
  showLabel: true,
  labelClass:
    "suffix after:content-['(Optional)'] after:ml-0.5 after:text-[#525252] small bold primary text-field !text-[11px] !font-medium",
  label: "Custom Domain",
  inputClass: "mt-[5px] text-xs",
  filledStyle: "!text-[14px]",
  parentClass: "custom-domain col-md-4 p-0 mb-5 ml-5 !w-[346px]",
  suffix: "",
  size: "small",
  prefixIcon: <LinkIcon className=" text-[#525252] inline mr-1" />,
  validations: {
    rules: {
      maximum: 50,
      minimum: 2,
      pattern: Patterns.domain,
    },
    messages: {
      maximum: "Maximum {{digit}} characters are allowed",
      minimum: "Minimum {{digit}} characters are allowed",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
};
export const partnerlogoValidations = {
  type: "file",
  width: "w-[347px] !h-[45px]",
  name: "partnerlogo",
  text: "Add",
  label: "Partner Logo",
  className: "partnerlogo ",
  validations: {
    rules: {
      required: false,
      allowedFileType: ["jpg", "png", "svg", "jpeg", "JPG", "PNG", "SVG", "JPEG"],
      allowedFileSize: 0.5,
    },
    messages: {
      required: "",
      allowedFileType: "This file format is not supported",
      allowedFileSize: "Max file size is 500KB",
    },
  },
};

export const payernameValidations = {
  type: "text",
  name: "name",
  placeHolder: "Enter Payer Name",
  label: "Payer Name",
  inputClass: "payername ",
  filledStyle: "!text-[14px]",
  validations: nameValidations,
  labelClass: "mandatory",
  showLabel: "true",
};
export const payeridValidations = {
  type: "text",
  name: "payerId",
  parentClass: "mt-6",
  placeHolder: "Enter Payer Account ID",
  label: "Payer Account ID",
  filledStyle: "!text-[14px]",
  inputClass: "payerid",
  labelClass: "mandatory",
  showLabel: "true",
  validations: {
    rules: {
      required: true,
      maximum: 12,
      minimum: 12,
      pattern: Patterns.numberOnly,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} digits are allowed",
      minimum: "Minimum {{digit}} digits are allowed",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
};

export const s3bucketValidations = {
  type: "text",
  name: "s3Bucket",
  placeHolder: "Enter S3 Bucket",
  parentClass: "mt-6 mb-2",
  showLabel: "true",
  label: "S3 Bucket",
  filledStyle: "!text-[14px]",
  inputClass: "s3bucket",
  labelClass: "mandatory",
  validations: nameValidations,
};
export const customernameValidations = {
  type: "textarea",
  name: "name",
  placeHolder: "Enter Customer Name",
  label: "Customer Name",
  inputClass: "customername",
  className: "customername !w-[460px]",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 50 } },
  maximum: 50,
  labelClass: "mandatory",
  showLabel: "true",
  filledStyle: "!text-[16px]",
};

export const customerTagField = {
  type: "invoice-customer-input-type",
  name: "tags",
  placeHolder: "Customer Tag(s)",
  className: "v2 !w-[460px] customer-tag",
  label: "Choose Customer Tag(s)",
  topLabel: { text: "Select Customer Tag(s)", fontSize: "x-small", class: "mt-0" },
  validations: { ...optionalRules },
  handleOptions: true,
  labelClass: "",
  v2: true,
  options: [],
  labelText: "Customer Tag(s)",
  searchPlaceHolder: "",
};

export const nameEDPValidation = {
  type: "text",
  name: "name",
  placeHolder: "Enter Name",
  label: "Name",
  inputClass: "name",
  parentClass: "customername !w-[488px]",
  validations: EDpnameValidations,
  labelClass: "mandatory",
  showLabel: "true",
  filledStyle: "!text-[16px]",
};

export const descriptionEDPValidation = {
  type: "textarea",
  name: "description",
  placeHolder: "Enter Description",
  label: "Description",
  className: "w-[498px] mt-2",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 100 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  resize: true,
  rows: 1,
  maximum: 100,
};

export const linkedAccIdValidations = {
  type: "transfer-List",
  name: "accountFamilies",
  validations: requiredRules,
  placeHolder: "Account Families",
  label: "Manage Account ID(s)",
  noDataMsgs: CUSTOMER_TRANSFER_LIST_CONFIG,
  noDataIcon: noMavDataIcon,
  classList: "cld_customer_linked_acc_wrapper_v2",
  leftListHeader: "Available",
  leftHeading: "Choose Account IDs to Associate",
  rightHeading: "Associated Account IDs",
  componentHeader: "Manage Account Id(s)",
  parentClass: "manage-account-wrapper",

  // formLabel: "Choose Account IDs to Associate",
  // formLabelSelected: "Associated Account IDs",
  // V2: true,
};

export const awsServicenameValidations = {
  type: "linked-AccId-input-type",
  name: "aws-service-families",
  validations: requiredRules,
  placeHolder: "AWS Service Families",
  label: "AWS Service Name",
  noDataLabelHeading: "Add AWS Services",
  noDataLabel: "Add some AWS Service and push them in selected List",
  formLabel: "AWS Services",
  formLabelSelected: "Selected AWS Services",
};

// Invoice settings Input Fields
export const invoiceHeaderValidations = {
  type: "textarea",
  name: "header",
  placeHolder: "Header of the report i.e. ‘This AWS Usage Report’",
  label: "Header",
  className: "w-[312px] mt-4",
  labelClass: "mandatory",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 30 } },
  maximum: 30,
};
export const invoiceHeaderDescValidations = {
  type: "textarea",
  name: "description",
  placeHolder: "For any billing related queries, email us at ‘aws-billing-support@tothenew.com’.",
  label: "Header Description",
  className: "w-[498px] mt-2",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 80 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  resize: true,
  rows: 1,
  maximum: 80,
};
export const invoiceSummaryValidations = {
  type: "textarea",
  name: "summary",
  placeHolder:
    "Greetings from ${PARTNER_NAME}, we’re writing to provide you with an electronic report for your use of AWS services. Additional information about your bill, individual service charge details, and your account history are available on CK Lens Platform.",
  label: "Summary",
  className: "invoicesummary floatingPlaceholder mt-2",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 400 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  floatingPlaceholder: true,
  resize: true,
  rows: 3,
  maximum: 400,
};
export const invoiceLogoLabel = {
  type: "Label",
  name: "invoicelogolabel",
  placeHolder: "Upload Logo",
  className: "label primary medium flex items-center p-0 mb-2.5 w-full",
  validations: optionalRules,
  label: "Upload Logo",
  tooltipPlacement: "top-start",
  tooltipOtherStyles: { whiteSpace: "nowrap", maxWidth: "inherit" },
};
export const invoicelogoValidations = {
  type: "file",
  width: "w-[347px]",
  name: "logoName",
  text: "Add",
  label: "Invoice Logo",
  className: "invoicelogo mb-2",
  preview: true,
  validations: {
    rules: {
      allowedFileType: ["jpg", "png", "svg", "jpeg", "JPG", "PNG", "SVG", "JPEG"],
      allowedFileSize: 0.5,
    },
    messages: {
      allowedFileType: "This file format is not supported",
      allowedFileSize: "Max file size is 500KB",
    },
  },
};

// invoice generation
export const invoiceTemplateValidations = {
  type: "select",
  name: "invoicetemplate",
  placeHolder: "Choose Invoice Template",
  className: "!mb-8 col-md-4 !pr-2.5",
  label: "Invoice Template",
  enableFilter: { placeHolder: "Search" },
  topLabel: { text: "Template Type", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [
    { label: "Default Template", value: "DEFAULT" },
    { label: "Strike Through Discounts", value: "STRIKE_THROUGH_DISCOUNTS" },
    { label: "Separate Line Item For Discounts", value: "SEPARATE_LINE_ITEMS_FOR_DISCOUNTS" },
  ],
};

export const invoiceMonthRangeValidations = {
  type: "calender",
  name: "invoicemonthrange",
  placeHolder: "Choose Invoice Month(s)",
  className: "col-md-4 rangeDateModule !pr-2.5",
  label: "Invoice Month",
  topLabel: {
    text: "Date Range",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  validations: requiredRules,
  startDate: moment().subtract(1, "months").startOf("month").format("MM/DD/YYYY"),
  endDate: moment().subtract(1, "months").endOf("month").format("MM/DD/YYYY"),
  collapseState: true,
};
export const invoiceCustomerValidations = {
  type: "invoice-customer-input-type",
  name: "invoicecustomer",
  placeHolder: "Choose Customer(s)",
  className: "col-md-4 clear-both !pr-2.5",
  label: "Invoice Customer",
  searchEnabled: true,
  labelText: "Choose Customer(s)",
  validations: requiredRules,
  options: [],
};

export const customerInvoiceLabel = {
  type: "Label",
  name: "customerInvoiceLabel",
  placeHolder: "Customer Details",
  className: "label primary medium flex items-center !py-[4.5px] !px-0  !m-0 w-full",
  validations: optionalRules,
};

export const customerUserStatementLabel = {
  type: "Label",
  name: "customerUserStatementLabel",
  placeHolder: "Usage Statement Generation",
  className: "label primary medium flex items-center !py-[4.5px] !px-0 w-full !mt-[15px]",
  validations: optionalRules,
};

export const customerInvoiceValidations = {
  type: "multiselect-selection",
  name: "invoicecustomer",
  placeHolder: "Choose Customer(s)",
  className: "col-md-4 clear-both !pr-2.5",
  label: "Invoice Customer",
  searchEnabled: true,
  labelText: "Choose Customer(s)",
  validations: requiredRules,
  options: [{ label: "label", value: "value", checked: false }],
  modalTitle: "Customers",
  parentClass: "w-full !pr-0",
  innerClass: "col-md-4 !pr-2.5",
  mandatory: "true",
  showCount: "true",
  modalClassWrapper: "generate-invoice-modal",
};

export const invoiceDefaultFormatValidations = {
  type: "select",
  name: "defaultformat",
  placeHolder: "Select Default Format",
  className: "col-md-4 !pr-2.5",
  label: "Usage Statement Format",
  enableFilter: { placeHolder: "Search" },
  topLabel: { text: "Usage Statement Format", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [
    { label: "Summary", value: "SUMMARY" },
    { label: "Detailed", value: "DETAILED" },
  ],
};
export const invoiceGroupingValidations = {
  type: "select",
  name: "invoicegrouping",
  placeHolder: "Choose Invoice Grouping",
  className: "col-md-4 !pr-2.5",
  label: "Usage Statement Grouping",
  enableFilter: { placeHolder: "Search" },
  topLabel: { text: "Usage Statement Grouping", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [
    { label: "Account Wise", value: "ACCOUNT" },
    { label: "Billing Alias", value: "BILLING_NAME" },
    { label: "CBU", value: "CBU" },
    { label: "Customer Wise", value: "CUSTOMER" },
    { label: "Legal Name", value: "LEGAL_ENTITY" },
  ],
};

export const invoiceDateValidations = {
  type: "calender",
  name: "invoicedate",
  placeHolder: "Select Invoice Date",
  className: "col-md-4 !pr-2.5 singleDateModule",
  singleDatePicker: true,
  topLabel: {
    text: "Usage Statement Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "Invoice Date",
  validations: requiredRules,
  startDate: moment().startOf("month").format("MM/DD/YYYY"),
  collapseState: true,
};

export const dateConfiguration = {
  type: "calender",
  name: `invoicedate-${new Date()}`,
  placeHolder: "Select Invoice Date",
  className: "vri-form-element !mt-0 !mb-2 singleDateModule relative !z-1",
  singleDatePicker: true,
  topLabel: {
    // text: "Start Date",
    fontSize: "",
    class: "text-field small !text-black primary mt-2.5",
  },
  disableFutureDates: false,
  disablePreviousDates: false,
  validations: requiredRules,
  labelText: "",
  collapseState: true,
  haveparentEl: false,
};

export const yearlyCommitmentStartDate = {
  type: "calender",
  name: "commitmentStartDate",
  placeHolder: "Start Date",
  className: "singleDateModule mr-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "Start Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "Start Date",
  validations: requiredRules,
  collapseState: true,
};

export const counterConfig = {
  type: "text",
  name: "counter",
  countName: "",
};

export const invoiceFormatValidations = {
  type: "radio",
  name: "invoiceformat",
  placeHolder: "Select Invoice Format",
  className: "!mb-8 col-md-4",
  buttonStyle: true,
  topLabel: { text: "File Format", fontSize: "x-small", class: "mandatory" },
  label: "File Format",
  childClassName: "pt-[5px]",
  validations: requiredRules,
  options: [
    { label: ".PDF", value: ".PDF", icon: <PdfIcon /> },
    { label: ".CSV", value: ".CSV", icon: <CsvIcon /> },
  ],
};

export const arnValidationRules = {
  rules: {
    ...requiredRules?.rules,
    pattern: Patterns.arnRegex,
  },
  messages: {
    ...requiredRules?.messages,
    pattern: "Please enter a valid {{fieldName}}",
  },
};

export const arnValidationCommaSeperatedRules = {
  rules: {
    ...requiredRules?.rules,
    commaSeperatedPattern: {
      pattern: Patterns.arnRegex,
      threshold: 15,
    },
  },
  messages: {
    ...requiredRules?.messages,
    pattern: "Please enter a valid {{fieldName}}",
    commaSeperatedPattern: {
      pattern: "Please enter a valid {{fieldName}}",
      threshold: "You can add maximum 15 ARNs",
    },
  },
};

export const createTemplateValidations = {
  type: "radio",
  name: "invoiceformat",
  placeHolder: "Select Invoice Format",
  className: "!mb-8 col-md-4",
  buttonStyle: true,
  topLabel: { text: "Handle Shortfall Chargeback?", fontSize: "x-small", class: "mandatory" },
  label: "Handle Shortfall Chargeback?",
  childClassName: "pt-[5px]",
  validations: requiredRules,
  options: [
    { label: "Never", value: "NEVER" },
    { label: "Year End", value: "YEAR_END" },
    { label: "Tenure End", value: "TENURE_END" },
  ],
};

export const CloudfrontPricing = {
  type: "radio",
  name: "newRegionToggle",
  placeHolder: "Handle new region by default",
  className: "",
  buttonStyle: true,
  topLabel: {
    text: "Handle new region by default?",
    fontSize: "x-small",
    tooltipText: "lorem ispum",
    class: "flex items-center",
  },
  label: "Handle new region by default",
  childClassName: "pt-[5px] no_image",
  options: [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ],
};

export const CloudfrontPricingCommitments = {
  type: "radio",
  name: "shortfallCharge",
  placeHolder: "Handle Shortfall Chargeback?",
  className: "cld_cloudfront_radio_group",
  buttonStyle: false,
  topLabel: {
    text: "Handle Shortfall Chargeback?",
    fontSize: "x-small",
    tooltipText:
      "Choose how you’d like to Chargeback customer if they can’t meet the commitment made",
    class: "title flex items-center",
  },
  label: "Handle Shortfall Chargeback?",
  childClassName: "pt-[5px] no_image",
  options: [
    { label: "Never", value: "NEVER" },
    { label: "Year End", value: "YEAR_END" },
    { label: "Tenure End", value: "TENURE_END" },
  ],
};

export const createCustomerLegalEntityName = {
  type: "text",
  name: "legalEntityName",
  placeHolder: "Enter Legal Name",
  label: "First Name",
  inputClass: "firstname floatingPlaceholder",
  validations: nameValidations,
};

export const createCustomerAccountIDs = {
  type: "invoice-customer-input-type",
  name: "invoicecustomer",
  placeHolder: "Account Id(s)",
  className: "clear-both",
  label: "Invoice Customer",
  searchEnabled: true,
  validations: requiredRules,
  options: [],
};

export const createCustomerStartDateValidations = {
  type: "calender",
  name: "startDate",
  placeHolder: "DD/MM/YYYY",
  className: "col-md-6 !mt-0 !mb-4 singleDateModule icon_btn",
  singleDatePicker: true,
  disableFutureDates: false,
  topLabel: {
    text: "Start Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  validations: requiredRules,
  startDate: moment().startOf("month").format("MM/DD/YYYY"),
  disablePreviousDates: true,
  collapseState: true,
};

export const cppDialogEndDate = {
  type: "calender",
  name: "endDate",
  placeHolder: "DD-MMM-YYYY",
  className: "col-md-6 !mt-0 !mb-4 singleDateModule icon_btn",
  singleDatePicker: true,
  disableFutureDates: false,
  topLabel: {
    text: "End Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0",
  },
  validations: requiredRules,
  startDate: moment().startOf("month").format("DD-MMM-YYYY"),
  disablePreviousDates: true,
  collapseState: true,
  minDate: getPartnerInvoideDate(),
};

export const VirtualReservationsEndDateDialog = {
  type: "calender",
  name: "endDate",
  placeHolder: "DD-MMM-YYYY",
  className: "col-md-6 !mt-0 !mb-4 singleDateModule icon_btn",
  singleDatePicker: true,
  disableFutureDates: false,
  topLabel: {
    text: "End Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  validations: requiredRules,
  startDate: moment().startOf("month").format("DD-MMM-YYYY"),
  disablePreviousDates: true,
  collapseState: true,
};

export const createCustomerSupportLevelValidations = {
  type: "select",
  name: "supportLevel",
  placeHolder: "Support Level",
  topLabel: {
    text: "Support Level",
    fontSize: "x-small",
    class: "!text-[#151E33] !text-[11px] !mt-0",
  },
  className: "col-md-6",
  label: "Support Level",
  showSelectedIcon: true,
  validations: requiredRules,
  options: [
    {
      label: "As-Is",
      value: "AS_IS",
    },
    {
      label: "Business Support",
      value: "BUSINESS",
    },
    {
      label: "Calculate Support",
      value: "CALCULATE",
    },
    {
      label: "Developer Support",
      value: "DEVELOPER",
    },
    {
      label: "Enterprise Support",
      value: "ENTERPRISE",
    },
  ],
};

export const createCustomerSupportLevelAccountIds = {
  type: "select-readonly",
  name: "accountIds",
  placeHolder: "Account ID(s)",
  labelText: "Account ID(s)",
  className: "col-md-12 !mt-0",
  validations: optionalRules,
  enableFilter: { placeHolder: "Search" },
  options: [],
};

export const createCustomerLegalEntityValidations = {
  type: "select",
  name: "legalEntity",
  placeHolder: "Legal Name",
  topLabel: {
    text: "Legal Name",
    fontSize: "x-small",
    class: "!text-[#151E33] !text-[11px] !mt-0 mandatory",
  },
  className: "col-md-12 !mt-0 !mb-2",
  label: "Legal Name",
  showSelectedIcon: true,
  enableFilter: { placeHolder: "Search" },
  validations: requiredRules,
  options: [],
};

export const createCustomerMinChargeValidations = {
  type: "select",
  name: "minCharge",
  placeHolder: "Select Charge Min.",
  className: "col-md-6 !pl-2.5",
  topLabel: {
    text: "Select Charge Min.",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0",
  },
  label: "Select Charge Min.",
  validations: requiredRules,
  options: [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ],
};

// Partner Settings
export const partnerSettingsFaviconValidations = {
  type: "file",
  name: "faviconName",
  sourceKeyURL: "faviconSourceURL",
  manageLogos: true,
  tooltip: {
    text: "Upload the favicon you customers will see when they log in to their dashboard.",
    placement: "bottom-start",
  },
  logo: {
    supported: "GIF, SVG & ICO",
    header: "Favicon",
    recommended: "16*16 px",
  },
  className: "partnersettingsfavicon mb-2",
  validations: {
    rules: {
      required: false,
      allowedFileType: ["gif", "svg", "ico", "GIF", "SVG", "ICO"],
      allowedFileSize: 0.5,
    },
    messages: {
      required: "",
      allowedFileType: "This file format is not supported",
      allowedFileSize: "Max file size is 500KB",
    },
  },
};
export const partnerSettingsLogoValidations = {
  type: "file",
  name: "logoName",
  sourceKeyURL: "logoSourceURL",
  manageLogos: true,
  tooltip: {
    text: "Upload the logo your customers will see when they log in to their dashboard.",
    placement: "bottom-start",
  },
  logo: {
    supported: "JPG, PNG",
    header: "Logo",
    recommended: "184*42 px",
  },
  className: "partnersettingslogo mb-2",
  validations: {
    rules: {
      required: false,
      allowedFileType: ["png", "jpg", "jpeg", "PNG", "JPG", "JPEG"],
      allowedFileSize: 0.5,
    },
    messages: {
      required: "",
      allowedFileType: "This file format is not supported",
      allowedFileSize: "Max file size is 500KB",
    },
  },
};

export const partnerEmailSettingsValidations = {
  type: "partner-email-settings-type",
  name: "partneremailsettings",
  className: "w-full",
  validations: requiredRules,
  label:
    "Choose the Email IDs shown in the email templates & notifications sent to your users and customers.",
  dependencies: [
    {
      id: "smtpsettings",
      value: "Custom Emails",
      valueKey: "setting",
    },
  ],
  childValidations: [
    {
      name: "senderName",
      label: "sender name",
      validations: nameValidations,
    },
    {
      name: "forSupport",
      label: "support email",
      validations: {
        rules: {
          required: true,
          pattern: Patterns.email,
        },
        messages: {
          required: "This field is required",
          pattern: "Please enter a valid {{fieldName}}",
        },
      },
    },
    {
      name: "forNoReply",
      label: "no reply email",
      validations: {
        rules: {
          required: true,
          pattern: Patterns.email,
        },
        messages: {
          required: "This field is required",
          pattern: "Please enter a valid {{fieldName}}",
        },
      },
    },
  ],
  options: [
    {
      label: "Default Emails",
      senderName: { key: "SENDER NAME", value: "Team CK Lens ™" },
      forSupport: { key: "FOR SUPPORT", value: "support@cloudonomic.com" },
      forNoReply: { key: "FOR NO REPLY", value: "no-reply@cloudonomic.com" },
    },
    {
      label: "Custom Emails",
      senderName: { key: "Sender Name" },
      forSupport: { key: "For Support" },
      forNoReply: { key: "For No Reply" },
    },
  ],
};
export const smtpSettingsValidations = {
  type: "smtp-settings-type",
  name: "smtpsettings",
  childClassName: "",
  label: "SMTP Settings",
  validations: optionalRules,
  hidden: true,
};
export const smtpSettingsServerValidations = {
  type: "text",
  name: "smtpsettingsserver",
  placeHolder: "smtp.example.com",
  label: "Server",
  parentClass: "col-md-6 mb-[20px]",
  inputClass: "server",
  showLabel: true,
  labelClass: "",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 50 } },
};
export const smtpSettingsPortValidations = {
  type: "number",
  name: "smtpsettingsport",
  placeHolder: "Port",
  label: "Port",
  showLabel: true,
  labelClass: "",
  inputClass: "port",
  parentClass: "col-md-6 ml-2.5",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 3 } },
};
export const configDiscountPercent = {
  type: "number",
  name: "discountPercent",
  placeHolder: "",
  label: "Discount (%)",
  showLabel: true,
  labelClass: "mandatory",
  inputClass: "port",
  parentClass: "col-md-6 ml-2.5",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 3 } },
};

export const smtpSettingsUsernameValidations = {
  type: "text",
  name: "smtpsettingsusername",
  label: "SMTP User Name",
  showLabel: true,
  autoComplete: "email",
  labelClass: "",
  inputClass: "smtp-username",
  parentClass: "col-md-6 mb-[14px]",
  inputIcon: UsernameIcon,
  placeHolder: "SMTP User Name",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 50 } },
};

export const smtpSettingsPasswordValidations = {
  type: "password",
  autoComplete: "new-password",
  name: "smtpsettingspassword",
  placeHolder: "SMTP Password",
  label: "SMTP Password",
  showLabel: true,
  labelClass: "",
  inputClass: "smtp-password",
  parentClass: "col-md-6 smtp-password-wrapper ml-2.5",
  toggleIcon: true,
  inputIcon: PartnerSettingsPasswordIcon,
  selectedVisibilityIcon: <VisibilityOffIcon />,
  iconList: {
    password: <VisibilityOffIcon />,
    text: <VisibilityIcon />,
  },
  validations: JSON.parse(JSON.stringify(requiredRules)),
};
export const smtpSettingsEncryptionValidations = {
  type: "radio",
  name: "smtpsettingsencryption",
  className: "smtp-settings-encryption clear-both !ml-0 mt-[10px]",
  topLabel: {
    text: "Encryption",
    fontSize: "small",
    class: "primary flex items-center",
    tooltipText: "Choose what kind of encryption you need here.",
    tooltipPlacement: "bottom-start",
  },
  label: "Encryption",
  validations: requiredRules,
  options: [
    { label: "None", value: "NONE" },
    { label: "SSL", value: "SSL" },
    { label: "TLS", value: "TLS" },
  ],
};

// CBU
export const createCustomerCBUName = {
  type: "text",
  name: "cbuName",
  placeHolder: "CBU Name",
  label: "CBU Name",
  inputClass: "customername",
  validations: nameValidations,
  labelClass: "!text-[11px] text-[#151E33] mandatory",
  showLabel: "true",
  filledStyle: "!text-[16px]",
  parentClass: "!w-full",
};

// Legal Entity

export const createCustomerMandatoryText = {
  type: "Label",
  name: "addDetailLabel",
  placeHolder: "Address Details",
  className:
    "label primary small flex items-center p-0 mt-3 mb-1 !text-[#161616] tracking-[0.13px]",
  validations: optionalRules,
};

export const createCustomerLegalEntName = {
  type: "text",
  name: "legalEntityName",
  placeHolder: "Enter Legal Name",
  label: "Legal Name",
  inputClass: "customername",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 100 } },
  labelClass: "!text-[11px] text-[#151E33] mandatory",
  showLabel: "true",
  filledStyle: "!text-[14px] tracking-[0.15px]",
  parentClass: "!w-full",
};

export const createCustomerBillingAlias = {
  type: "text",
  name: "billingAlias",
  placeHolder: "Enter Billing Alias",
  label: "Billing Alias",
  inputClass: "customername",
  validations: nameValidations,
  labelClass: "!text-[11px] text-[#151E33] mandatory",
  showLabel: "true",
  filledStyle: "!text-[14px] tracking-[0.15px]",
  parentClass: "!w-full billing_alias_input_wrapper",
};

export const createCustomerLegalEntityAddress = {
  type: "textarea",
  name: "address",
  placeHolder: "Enter Address.",
  label: "Address (Street / Apartment / Building Details)",
  className: "!w-full",
  labelClass: "!text-[12px] text-[#151E33] tracking-[0.13px] mandatory",
  validations: { ...nameValidations, rules: { ...nameValidations.rules, maximum: 300 } },
  floatingPlaceholder: true,
  resize: true,
  rows: 2,
  maximum: 300,
  showCounter: false,
  highlightLabel: false,
};

export const createCustomerLegalEntityCountry = {
  type: "select",
  name: "country",
  placeHolder: "Country",
  className: "col-md-12 country-list !mb-3",
  label: "Country",
  topLabel: { text: "Country", fontSize: "x-small", class: "!text-[#151E33] mt-1 mandatory" },
  validations: requiredRules,
  resetValues: ["state", "city", "zipcode"],
  options: [
    { label: "India", value: "India" },
    { label: "Australia", value: "Australia" },
  ],
  enableFilter: {
    placeHolder: "Search",
  },
};

export const createCustomerLegalEntityState = {
  type: "select",
  name: "state",
  placeHolder: "Select State / Province",
  className: "col-md-12 !mt-0 !mb-3",
  enableFilter: {
    placeHolder: "Search",
  },
  label: "State / Province",
  topLabel: {
    text: "State / Province",
    fontSize: "x-small",
    class: "!text-[#151E33] mt-1 mandatory",
  },
  validations: requiredRules,
  options: [],
};

export const createCustomerLegalEntityCity = {
  type: "text",
  name: "city",
  placeHolder: "Enter Town/City",
  label: "Town / City",
  inputClass: "zipcode",
  parentClass: "!w-full mb-2",
  validations: nameValidations,
  labelClass: "!text-[11px] text-[#151E33] mandatory",
  showLabel: "true",
  filledStyle: "!text-[14px] tracking-[0.15px]",
};

export const createCustomerLegalEntityZip = {
  type: "text",
  name: "zipcode",
  placeHolder: "Enter ZIP",
  label: "ZIP Code",
  inputClass: "zipcode",
  parentClass: "!w-[50%]",
  validations: {
    rules: {
      required: true,
      pattern: "",
    },
    messages: {
      required: "This field is required",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
  labelClass: "!text-[11px] text-[#151E33] mandatory",
  showLabel: "true",
  filledStyle: "!text-[14px] tracking-[0.15px]",
};

export const createCustomerCBUNames = {
  type: "select",
  name: "CBUNames",
  enableFilter: { placeHolder: "Search" },
  placeHolder: "CBU",
  topLabel: {
    text: "CBU",
    fontSize: "x-small",
    class: "!text-[#151E33] !text-[11px] !mt-0",
  },
  className: "col-md-12",
  label: "CBU",
  showSelectedIcon: true,
  validations: requiredRules,
};

export const preferencesTagNameValidations = {
  type: "text",
  name: "name",
  placeHolder: "Enter Tag Name",
  label: "tag name",
  inputClass: "partnername",
  filledStyle: "!text-[14px]",
  validations: nameValidations,
  labelClass: "mandatory",
  parentClass: "!w-full",
};

export const preferencesTagDescriptionValidations = {
  type: "text",
  name: "description",
  placeHolder: "Enter Description...",
  label: "description",
  inputClass: "partnername",
  filledStyle: "!text-[14px]",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, minimum: 3, maximum: 50 },
    messages: {
      maximum: "Maximum {{digit}} characters are allowed",
      minimum: "Minimum {{digit}} characters are allowed",
    },
  },
  labelClass: "mandatory",
  parentClass: "!w-full",
};

export const preferencesTagMavValidations = {
  type: "invoice-customer-input-type",
  name: "mav",
  placeHolder: "Select MAV's",
  className: "!w-full !mb-1.5",
  label: "Invoice Customer",
  searchEnabled: true,
  validations: optionalRules,
  options: [],
};

export const preferencesManageUsersValidations = {
  type: "invoice-customer-input-type",
  name: "users",
  placeHolder: "Select User's",
  className: "!w-full !mb-1.5",
  label: "Invoice Customer",
  searchEnabled: true,
  validations: optionalRules,
  options: [],
};

export const preferencesMavNameValidations = {
  type: "text",
  name: "name",
  placeHolder: "Enter Mav Name",
  label: "mav name",
  inputClass: "partnername",
  // filledStyle: "!text-[14px]",
  validations: nameValidations,
  labelClass: "mandatory",
  parentClass: "!w-full",
};

export const preferencesAccountValidations = {
  type: "invoice-customer-input-type",
  name: "accounts",
  placeHolder: "Select Account Id's",
  className: "!w-full !mb-1.5",
  label: "Invoice Customer",
  searchEnabled: true,
  validations: optionalRules,
  options: [],
};

export const moduleSelect = {
  type: "select",
  name: "selectedModule",
  placeHolder: "Select Default Format",
  className: "module_dropdown !pr-2.5 Grid_dropdown",
  label: "Default Format",
  topLabel: { text: "Module", fontSize: "x-small" },
  validations: requiredRules,
  options: [],
};
export const userTypeSelect = {
  type: "select",
  name: "selectedUserType",
  placeHolder: "Select User Type",
  className: "module_dropdown !pr-2.5 Grid_dropdown",
  label: "Default Format",
  topLabel: { text: "User Type", fontSize: "x-small" },
  validations: requiredRules,
  options: [
    { label: "Customer", value: "CUSTOMER" },
    { label: "Partner", value: "PARTNER" },
  ],
};

// Clone Customer Discount
export const cloneTemplateName = {
  type: "text",
  name: "templateName",
  placeHolder: "Template Name",
  label: "Template Name",
  inputClass: "firstname",
  validations: nameValidations,
  filledStyle: "!text-[14px]",
  labelClass: "mandatory",
  parentClass: "!pr-2.5 !w-1/2",
  showLabel: "true",
};

export const cloneTemplateDescription = {
  type: "text",
  name: "templateType",
  placeHolder: "Template Type",
  label: "Template Type",
  parentClass: "!pr-2.5 !w-[347px] disabled inline-block template_type",
  inputClass: "firstname disabled",
  validations: nameValidations,
  filledStyle: "!text-[14px]",
  labelClass: "mandatory",
  showLabel: "true",
};

export const templateName = {
  type: "textarea",
  name: "templateName",
  placeHolder: "Enter Template Name",
  label: "Template Name",
  labelClass: "mandatory",
  className: "!pr-2.5 !w-[491px] inline-block template_name !font-normal",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    rules: {
      required: true,
      maximum: 70,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} digits are allowed",
    },
  },
  floatingPlaceholder: true,
  resize: false,
  rows: 1,
  maximum: 70,
};

export const templateDescription = {
  type: "textarea",
  name: "description",
  placeHolder: "Enter Description",
  label: "Description",
  labelClass: "",
  className: "!pr-2.5 !w-100 !font-normal !min-h-[53px]",
  isEditableClassName: "text-base leading-5 pb-3 !font-normal",
  floatingPlaceholder: true,
  resize: true,
  rows: 2,
  maximum: 2000,
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 2000 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
};

export const regionCloneValidations = {
  type: "multiselect-selection",
  name: "region",
  placeHolder: "Choose Region(s)",
  parentClass: " clear-both !pr-2.5 col-md-2",
  label: "Regions",
  searchEnabled: true,
  labelText: "Choose Region(s)",
  validations: requiredRules,
  options: [],
  modalTitle: "Regions",
};
export const productCloneValidations = {
  type: "multiselect-selection",
  name: "invoicecustomer",
  placeHolder: "Choose Product(s)",
  parentClass: " clear-both !pr-2.5 col-md-2",
  label: "Product Name",
  searchEnabled: true,
  labelText: "Choose Product(s)",
  validations: requiredRules,
  options: [],
  modalTitle: "Product Name",
};
export const osDbTypeCloneValidations = {
  type: "multiselect-selection",
  name: "invoicecustomer",
  placeHolder: "Choose OS/DB/Type(s)",
  parentClass: " clear-both !pr-2.5 col-md-2",
  label: "OS/DB/Type",
  searchEnabled: true,
  labelText: "Choose OS/DB/Type(s)",
  validations: requiredRules,
  options: [],
  modalTitle: "OS/DB/Type",
};
export const customGroupCloneValidations = {
  type: "multiselect-selection",
  name: "invoicecustomer",
  placeHolder: "Choose Custom Group(s)",
  parentClass: " clear-both !pr-2.5 col-md-2",
  label: "Custom Group",
  searchEnabled: true,
  labelText: "Choose Custom Group(s)",
  validations: requiredRules,
  options: [],
  modalTitle: "Custom Group",
};
export const awsServicesCloneValidations = {
  type: "multiselect-selection",
  name: "invoicecustomer",
  placeHolder: "Choose AWS Service(s)",
  parentClass: " clear-both !pr-2.5 col-md-2",
  label: "AWS Services",
  searchEnabled: true,
  labelText: "Choose AWS Service(s)",
  validations: requiredRules,
  options: [],
  modalTitle: "AWS Services",
};
export const instanceFamilyTypeCloneValidations = {
  type: "multiselect-selection",
  name: "invoicecustomer",
  placeHolder: "Choose Instance Family/Type(s)",
  parentClass: " clear-both !pr-2.5 col-md-2",
  label: "Instance Family/Type",
  searchEnabled: true,
  labelText: "Choose Instance Family/Type(s)",
  validations: requiredRules,
  options: [],
  modalTitle: "Instance Family/Type",
};
export const basicDetailText = {
  type: "Label",
  name: "addDetailLabel",
  placeHolder: "Basic Details",
  className:
    "label large flex items-center !p-0 mt-3 mb-1 !text-[#161616] tracking-[0.13px] col-md-12",
  validations: optionalRules,
};

export const templateconfigurationsText = {
  type: "Label",
  name: "addDetailLabel",
  placeHolder: "Template Configurations",
  className:
    "label large flex items-center !p-0 mt-3 mb-1 !text-[#161616] tracking-[0.13px] col-md-12",
  validations: optionalRules,
};

export const createtemplateconfigurationsText = {
  type: "Label",
  name: "addDetailLabel",
  placeHolder: "Configurations",
  className:
    "label flex items-center !p-0 mt-3 mb-1 !text-[#161616] tracking-[0.13px] col-md-12 !text-[15px]",
  validations: optionalRules,
};

export const moduleSelectYearlyCpp = {
  type: "select",
  name: "selectedModule",
  placeHolder: "Select Default Format",
  className: "!pr-2.5 Grid_dropdown",
  label: "Default Format",
  // topLabel: { text: "Default Format", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [],
};

export const cloudFrontPricingValidations = [
  {
    type: "textarea",
    name: "name",
    placeHolder: "Enter Name",
    label: "Name:",
    labelClass: "mandatory mb-[3px]",
    className: "cloudfrontpricing_name mr-2 floatingPlaceholder leading-[14px]",
    isEditableClassName: "text-base leading-5 pb-3 font-medium",
    validations: {
      ...requiredRules,
      rules: { ...requiredRules.rules, maximum: 50 },
      messages: {
        required: "This field is required",
        maximum: "Maximum {{digit}} characters are allowed",
      },
    },
    floatingPlaceholder: true,
    resize: false,
    rows: 1,
    maximum: 50,
  },
  {
    type: "headerComponent",
    heading: {},
    wrapperClass: "![box-shadow:none] createdAndSummary",
    name: "createdAndSummary",
    config: [
      {
        component: TextArea,
        type: "textarea",
        name: "createdAt",
        placeHolder: "Enter Name",
        label: "Created At:",
        rows: 1,
        labelClass: "mandatory !mb-[2px]",
        className: "cloudfrontpricing_name !p-0 mr-2 floatingPlaceholder",
        isEditableClassName: "text-base leading-5 pb-3 font-medium",
        validations: {
          ...requiredRulesExport,
        },
        readOnly: true,
        disabled: true,
      },
      {
        component: RenderHTML,
        name: "tenureSummary",
        type: "customHTML",
        labelClass: "mandatory !mb-[2px] text-[12px] font-normal leading-[14px]",
        label: "Term Summary:",
        value: {},
        validations: {
          ...requiredRulesExport,
        },
      },
    ],
    validations: {
      ...requiredRulesExport,
    },
  },
  {
    type: "textarea",
    name: "description",
    placeHolder: "Enter Description",
    label: "Description:",
    className: "cloudfrontpricing_description floatingPlaceholder !min-h-[50px]",
    isEditableClassName: "text-sm font-normal leading-4 pb-5",
    labelClass: "mb-[1px]",
    validations: {
      ...optionalRules,
      rules: { ...optionalRules.rules, maximum: 2000 },
      messages: { maximum: "Maximum {{digit}} characters are allowed" },
    },
    floatingPlaceholder: true,
    resize: true,
    rows: 2,
    maximum: 2000,
  },
  {
    type: "invoice-customer-input-type",
    name: "cbu",
    placeHolder: "Select CBU(s)",
    className: "module_dropdown cloudfront_form_fields cloudfrontpricing_cbu !mb-[0px] v2 ",
    label: "Choose CBU(s)",
    topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
    validations: {
      rules: {
        required: true,
      },
      messages: {
        required: "This field is required",
      },
    },
    labelClass: "mandatory !mt-[0] !text-[12px]",
    v2: true,
    labelText: "CBU(s)",
    searchPlaceHolder: "",
    options: [],
  },
  {
    type: "invoice-customer-input-type",
    name: "region",
    placeHolder: "Select Region(s)",
    className: "module_dropdown cloudfront_form_fields cloudfrontpricing_region !mb-[0px] v2 ",
    label: "Choose Region(s)",
    topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
    validations: {
      rules: {
        required: true,
      },
      messages: {
        required: "This field is required",
      },
    },
    labelClass: "mandatory !mt-[0] !text-[12px]",
    v2: true,
    labelText: "Region(s)",
    searchPlaceHolder: "",
    options: [],
  },
  {
    type: "invoice-customer-input-type",
    name: "pricing",
    placeHolder: "Select Pricing(s)",
    className: "module_dropdown cloudfront_form_fields cloudfrontpricing_pricing !mb-[0px] v2 ",
    label: "Choose Pricing(s)",
    topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
    validations: {
      rules: {
        required: true,
      },
      messages: {
        required: "This field is required",
      },
    },
    labelClass: "mandatory !mt-[0] !text-[12px]",
    v2: true,
    labelText: "Pricing(s)",
    searchPlaceHolder: "",
    options: [],
  },
  {
    type: "commitmentTable",
    name: "configureCommitments",
    heading: "Configure Commitment(s)",
    options: [],
    validations: {
      rules: {
        required: true,
        commitmentTableValidation: true,
      },
      messages: {
        required: "Enter valid commitment",
        commitmentTableValidation: "This field is required",
      },
    },
  },
  {
    type: "configurableTable",
    name: "configure",
    heading: "Configure Rates",
    headers: [],
    tableData: [],
    modalTitle: "Copy to Other Region(s)",
    validations: {
      rules: {
        required: true,
        configurableTableValidation: true,
      },
      messages: {
        required: "This field is required",
        configurableTableValidation: "This field is required",
      },
    },
  },
];

export const cloudfrontPrivatePricingTextLabel = {
  type: "Label",
  name: "label",
  placeHolder: "Name",
  className: "!text-[11px] !text-black !font-normal !w-full",
  validations: optionalRules,
};
export const cloudfrontPrivatePricingText = {
  type: "Label",
  name: "partnerurllabel",
  placeHolder: "",
  className: "!mb-[10px] !text-[14px] !text-black !font-medium !w-full !mt-[3px]",
  validations: optionalRules,
};

// export const templateNameValidations = {
//   type: "text",
//   name: "name",
//   placeHolder: "Enter Template Name",
//   label: "Template Name*",
//   inputClass: "partnername",
//   filledStyle: "!text-[14px]",
//   validations: nameValidations,
//   labelClass: "mandatory",
//   parentClass: "!w-full",
//   showLabel: "true",
// };

export const templateNameValidations = {
  type: "textarea",
  name: "templateName",
  placeHolder: "Enter Template Name",
  label: "Template Name",
  labelClass: "mandatory",
  className: "cloudfrontpricing_name mr-2 floatingPlaceholder !w-[491px]",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 50 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  floatingPlaceholder: true,
  resize: false,
  rows: 1,
  maximum: 50,
};

export const templateDescriptionValidations = {
  type: "textarea",
  name: "templateDescription",
  placeHolder: "Enter Template Name",
  label: "Template Name",
  labelClass: "mandatory",
  className: "cloudfrontpricing_name mr-2 floatingPlaceholder !w-full",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 200 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  floatingPlaceholder: true,
  resize: true,
  rows: 2,
  maximum: 200,
};

export const distinctOfferingType = {
  type: "select",
  name: "selectedModule",
  placeHolder: "Select Plan",
  className: "module_dropdown !pr-2.5 Grid_dropdown",
  label: "Default Format",
  // topLabel: { text: "Default Format", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [],
};

export const viewPageDropdown = {
  type: "select",
  name: "selectedPlan",
  placeHolder: "Select Plan",
  className: "module_dropdown !pr-2.5 Grid_dropdown",
  label: "Default Format",
  // topLabel: { text: "Default Format", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [],
};

export const ConfigDiscountsValidations = [
  {
    type: "textarea",
    name: "name",
    placeHolder: "Enter Name",
    label: "Name",
    labelClass: "mandatory",
    className: "cloudfrontpricing_name mr-2 floatingPlaceholder !w-[491px]",
    isEditableClassName: "text-base leading-5 pb-3 font-medium",
    validations: {
      ...optionalRules,
      rules: { ...optionalRules.rules, maximum: 50 },
      messages: { maximum: "Maximum {{digit}} characters are allowed" },
    },
    floatingPlaceholder: true,
    resize: false,
    rows: 1,
    maximum: 50,
  },
  {
    type: "textarea",
    name: "description",
    placeHolder: "Enter Description",
    label: "Description",
    className: "cloudfrontpricing_description floatingPlaceholder !min-h-[50px]",
    isEditableClassName: "text-sm font-normal leading-4 pb-5",
    validations: {
      ...optionalRules,
      rules: { ...optionalRules.rules, maximum: 2000 },
      messages: { maximum: "Maximum {{digit}} characters are allowed" },
    },
    floatingPlaceholder: true,
    resize: true,
    rows: 2,
    maximum: 2000,
  },
];

export const configurationDiscountapplyRadio = {
  type: "radio",
  name: "yearlyCalculationCondition",
  placeHolder: "Select which to apply first",
  className: " col-md-4 flex justify-start w-full",
  buttonStyle: false,
  topLabel: {
    text: "Select which to apply first",
    fontSize: "x-small",
    tooltipText:
      "Please select if you want to apply Credits or Discounts first in order to calculate invoices of this customer",
    class: "",
  },
  label: "Select which to apply first",
  childClassName: "pt-[5px] pl-[20px] font-bold	",
  validations: requiredRules,
  options: [
    { label: "Credit", value: "credit" },
    { label: "Discount", value: "discount" },
  ],
};

export const configurationDiscountRadio = {
  type: "radio",
  name: "selectDiscountCharges",
  placeHolder: "Please select one",
  className: " col-md-4 flex justify-start",
  buttonStyle: false,
  topLabel: { text: "Please select one", fontSize: "x-small", class: "mandatory" },
  label: "Please select one",
  childClassName: "pt-[5px] pl-[20px] font-bold	",
  validations: requiredRules,
  options: [
    { label: "Discounts", value: "Discounts" },
    { label: "Charges", value: "Charges" },
  ],
};
export const configurationFrequencyRadio = {
  type: "radio",
  name: "selectFrequencyDuration",
  placeHolder: "Frquency/Duration",
  className: " col-md-4 flex justify-start w-full",
  buttonStyle: false,
  topLabel: { text: "Frquency/Duration", fontSize: "x-small", class: "mandatory" },
  label: "Frquency/Duration",
  childClassName: "pt-[5px] pl-[20px] font-bold	",
  validations: requiredRules,
  options: [
    { label: "OneTime", value: "OneTime" },
    { label: "Repeatative", value: "Repeatative" },
  ],
};
export const configurationChargeTypeRadio = {
  type: "radio",
  name: "selectChargeType",
  placeHolder: "Charge Type",
  className: " col-md-4 flex justify-start w-full",
  buttonStyle: false,
  topLabel: { text: "Charge Type", fontSize: "x-small", class: "mandatory" },
  label: "Charge Type",
  childClassName: "pt-[5px] pl-[20px] font-bold	",
  validations: requiredRules,
  options: [
    { label: "Fixed", value: "Fixed" },
    { label: "Percentage", value: "Percentage" },
  ],
};

export const ConfigDiscountShortfall = {
  type: "radio",
  name: "invoiceformat",
  placeHolder: "Handle Shortfall Chargeback?",
  className: "",
  buttonStyle: false,
  topLabel: {
    text: "Handle Shortfall Chargeback?",
    fontSize: "x-small",
    tooltipText:
      "Please select how do you want to charge the customer if they don't meet the commitment they are making.",
    class: "flex items-center",
  },
  label: "Handle Shortfall Chargeback?",
  childClassName: "pt-[5px] no_image",
  options: [
    { label: "Never", value: "NEVER" },
    { label: "Year End", value: "YEAR_END" },
    { label: "Tenure End", value: "TENURE_END" },
  ],
};
export const ReadOnlyText = {
  name: "readOnlyInfo",
  type: "readonly-view",
  requiredRules: false,
  class: "text-center",
  readOnlyData: [],
  validations: optionalRules,
};
export const VRManageRenewalRadio = {
  type: "radio",
  name: "manageRenewal",
  placeHolder: "",
  className: "w-full flex justify-center VRManageRenewalRadio",
  // buttonStyle: true,
  topLabel: { text: "", fontSize: "x-small", class: "" },
  label: "",
  childClassName: "pt-[5px]",
  validations: optionalRules,
  options: [
    { label: "Enable", value: "Enable" },
    { label: "Disable", value: "Disable" },
  ],
};

export const ConfigDiscountsCalendar = {
  type: "calender",
  name: "commitmentStartDate",
  placeHolder: "Start Date",
  className: "col-md-4 pr-2.5 singleDateModule",
  singleDatePicker: true,
  topLabel: {
    text: "Start Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "Start Date",
  validations: requiredRules,
  startDate: moment().format("MM/DD/YYYY"),
  disableFutureDates: false,
  collapseState: true,
  haveparentEl: false,
};
export const ConfigDiscountsEndCalendar = {
  type: "calender",
  name: "commitmentEndDate",
  placeHolder: "End Date",
  className: "col-md-4 pr-2.5 singleDateModule",
  singleDatePicker: true,
  topLabel: {
    text: "End Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "End Date",
  validations: requiredRules,
  startDate: moment().format("MM/DD/YYYY"),
  disableFutureDates: false,
  collapseState: true,
  haveparentEl: false,
};

export const configDiscountsCheckbox = {
  type: "checkbox",
  name: "togglepasswords",
  label: "I have reviewed the above changes carefully.",
  className: "pt-1.5",
  validations: optionalRules,
};

export const monthlyReportValidations = {
  type: "text",
  name: "name",
  placeHolder: "Enter Email Id(s)",
  inputClass: "",
  filledStyle: "!text-[14px]",
  parentClass: "!w-full",
  showLabel: "false",
};

export const monthlyReportSearchValidation = {
  type: "text",
  name: "name",
  placeHolder: "Enter customer name",
  inputClass: "",
  filledStyle: "!text-[14px]",
  parentClass: "!w-full",
  showLabel: "false",
};

export const preferences = {
  type: "toggleInput",
  name: "preferences",
  label: "Preferences",
  className: "pt-[4px] h-full mr-[16px]",
  validations: optionalRules,
};

export const startDateValidations = {
  type: "text",
  name: "startDate",
  label: "Enter Start Date",
  inputClass: "startDate",
  placeHolder: "MM/DD/YYYY",
  validations: dateRules,
  showLabel: "true",
};

export const endDateValidations = {
  type: "text",
  name: "endDate",
  label: "Enter End Date",
  inputClass: "endDate",
  placeHolder: "MM/DD/YYYY",
  validations: dateRules,
  showLabel: "true",
};

export const listSelectorValidation = {
  type: "listSelector",
  name: "listSelector",
  label: "listSelector",
  className: "pt-[4px] h-full",
  validations: {
    rules: {
      multiSelect: true,
      required: true,
    },
    messages: {
      multiSelect: "Please select atleast one configuration",
      required: "This field is required",
    },
  },
};
export const configureDiscountsNameValidations = {
  type: "textarea",
  name: "name",
  placeHolder: "Enter Name",
  label: "Name",
  labelClass: "mandatory",
  className: "cloudfrontpricing_name mr-2 floatingPlaceholder !w-[460px]",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    rules: {
      required: true,
      maximum: 50,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} digits are allowed",
    },
  },
  floatingPlaceholder: true,
  resize: false,
  rows: 1,
  maximum: 50,
};

export const configureDiscountsDescriptionValidations = {
  type: "textarea",
  name: "description",
  placeHolder: "Enter Description",
  label: "Description",
  className: "cloudfrontpricing_name mr-2 floatingPlaceholder !min-h-[53px]",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 2000 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  floatingPlaceholder: true,
  resize: true,
  rows: 1,
  maximum: 2000,
};

export const configureDiscountsModuleSelect = {
  type: "invoice-customer-input-type",
  name: "selectCbu",
  placeHolder: "Select CBU",
  className: "module_dropdown !pr-2.5 Grid_dropdown col-md-3 v2 unset_width",
  label: "Default Format",
  topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: requiredRules,
  labelClass: "mandatory",
  options: [],
  v2: true,
  // parentClass: "col-md-3",
  // labelClass: "mandatory",
  labelText: "CBU",
  searchPlaceHolder: "",
};

export const configureDiscountsModuleSelectTemplate = {
  type: "select",
  name: "selectTemplate",
  placeHolder: "Select Template",
  className: "module_dropdown !pr-2.5 Grid_dropdown col-md-3 v2 select_template unset_width",
  label: "Default Format",
  topLabel: { text: "Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: requiredRules,
  labelClass: "mandatory",
  options: [],
  v2: true,
  // parentClass: "col-md-3",
  // labelClass: "mandatory",
  enableFilter: {
    placeHolder: "Search",
  },
};

export const customerTemplateDropDown = {
  type: "select",
  name: "orderingTemplate",
  placeHolder: "Select Template",
  className:
    "inline-block !mb-0 module_dropdown !pr-2.5 Grid_dropdown v2 select-template !w-[420px] relative ordering_template !float-none",
  label: "Default Format",
  topLabel: {
    text: "Construct Ordering Template",
    fontSize: "x-small",
    class: "mandatory mt-0 !text-[#000000]",
  },
  validations: requiredRules,
  labelClass: "mandatory",
  options: [],
  v2: true,
  enableFilter: {
    placeHolder: "Search",
  },
};

export const customerTemplateDropDownInfo = {
  type: "infoBox",
  name: "accountFamilies",
  message: "You can create your own or choose partner default ordering template.",
  customClass: "w-[fit-content] mb-[20px] h-[24px]",
  tooltip: true,
  validations: optionalRules,
};

export const configureDiscountsPercent = {
  type: "text",
  name: "discount",
  parentClass: "mt-[3px] col-md-2 !pr-2.5",
  // className: "!max-w-[100px]",
  placeHolder: "Enter Discount",
  label: "Discount (%)",
  filledStyle: "!text-[14px]",
  inputClass: "",
  labelClass: "mandatory",
  showLabel: "true",
  validations: {
    rules: {
      required: true,
      maximum: 5,
      minimum: 1,
      pattern: /^(0\.([1-9]\d*|\d*[1-9]\d*)|100|([1-9]|[1-9]\d)(\.\d{1,2})?)$/,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} digits are allowed",
      minimum: "Minimum {{digit}} digits are allowed",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
};

export const ConfigureFlatDiscountDateValidations = {
  type: "calender",
  name: "startDate",
  placeHolder: "DD-MM-YYYY",
  className: "col-md-2 pr-2.5 singleDateModule",
  singleDatePicker: true,
  topLabel: {
    text: "Start Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "Start Date",
  validations: requiredRules,
  startDate: moment().format("MM/DD/YYYY"),
  disableFutureDates: false,
  collapseState: true,
};

export const configureMultiChipValidations = {
  type: "mult-chip-select",
  name: "multiChip",
  placeHolder: "Select",
  className: "!w-full h-[94px] m-[0px] col-md-2 pr-2.5 singleDateModule",
  inputClass: "h-[40px] m-[0px] !text-[16px] !font-[400]",
  labelClass: "mandatory",
  labelText: "Select",
  validations: requiredRulesExport,
  disableFutureDates: false,
  collapseState: true,
  required: true,
  readMode: false,
  v2: true,
};

export const confgNotificationIntegrationGroupName = {
  type: "text",
  name: "GroupName",
  placeHolder: "Group name",
  label: "Group name",
  inputClass: "h-[40px] m-[0px] !text-[16px] !font-[400]",
  validations: EDpnameValidations,
  labelClass: "!text-[14px] !font-[400] mandatory !mb-[5px]",
  showLabel: "true",
  filledStyle: "",
  parentClass: "!w-full !h-[94px]",
  required: true,
};

export const configNotificationIntegrationChannelName = {
  type: "text",
  name: "channelName",
  placeHolder: "Channel Name",
  label: "Channel Name",
  inputClass: "h-[40px] m-[0px] !text-[16px] !font-[400]",
  validations: slackGroupNameValidation,
  labelClass: "!text-[14px] !font-[400] mandatory",
  showLabel: "true",
  filledStyle: "",
  parentClass: "!w-full !h-[94px]",
  required: true,
};

export const configNotificationIntegrationPassword = {
  ...smtpSettingsPasswordValidations,
  abelClass: "",
  showLabel: "true",
  filledStyle: "",
  parentClass: "!w-full",
  name: "slackOuthToken",
  placeHolder: "Oauth Token",
  label: "Oauth Token",
  labelClass: "!text-[14px] !font-[400] mandatory",
  inputIcon: "",
  inputClass: "",
  selectedVisibilityIcon: <VisibilityOffSlackPassword />,
  iconList: {
    password: <VisibilityOffSlackPassword />,
    text: <VisibilityOnSlackPassword />,
  },
  readMode: true,
  v2: true,
};

export const configureAutoResizeTextbox = {
  type: "auto-resize-textbox",
  name: "auto-resize-textbox",
  placeHolder: "Enter Comments",
  className: "col-md-2 pr-2.5 singleDateModule",
  labelText: "Description",
  validations: requiredRulesExport,
};

export const TagsDescription = {
  type: "textarea",
  name: "TagDescription",
  placeHolder: "Add the required details about Tag",
  label: "Tag Description",
  inputClass: "mavname",
  labelClass: "",
  className: "!font-normal",
  isEditableClassName: "font-medium",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, minimum: 0, maximum: 300 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  floatingPlaceholder: true,
  resize: false,
  rows: 2,
  minimum: 0,
  maximum: 300,
};

export const purposeOfStatus = {
  ...TagsDescription,
  name: "description",
  label: "Purpose",
  inputClass: "!text-[#fff]",
  labelClass: "pt-[20px]",
  className: "!font-normal !text-[#000] customer-type",
  placeHolder: "Add the required details about customer type",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, minimum: 0, maximum: 100 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  maximum: 100,
};

export const MAVNameInput = {
  type: "text",
  name: "name",
  placeHolder: "Enter MAV Name",
  label: "MAV Name",
  inputClass: "mavname",
  validations: {
    rules: {
      required: true,
      maximum: 50,
      minimum: 2,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} characters are allowed",
      minimum: "Minimum {{digit}} characters are allowed",
    },
  },
  // filledStyle: "!text-[14px]",
  labelClass: "font-medium",
  showLabel: "true",
};

export const MAVTransferList = {
  type: "transfer-List",
  name: "accountFamilies",
  validations: requiredRules,
  placeHolder: "Account Families",
  label: "Linked Account ID",
  leftHeading: accountIdMAVMsgs?.leftHeading,
  rightHeading: accountIdMAVMsgs?.rightHeading,
  noDataMsgs: accountIdMAVMsgs,
  noDataIcon: noMavDataIcon,
  classList: "",
  infoBoxMessage: "Modifying account IDs is not possible within default accounts.",
  infoBoxClass: "w-[fit-content] mb-[20px] h-[24px]",
  tooltip: true,
};

export const MAVTransferUserList = {
  type: "transfer-List",
  name: "userAccountFamilies",
  validations: optionalRules,
  placeHolder: "Account Families",
  label: "Linked Account ID",
  leftHeading: userMAVMsgs?.leftHeading,
  rightHeading: userMAVMsgs?.rightHeading,
  noDataMsgs: userMAVMsgs,
  noDataIcon: noMavDataIcon,
  classList: "hide",
  tooltip: true,
};

export const statusTypeInput = {
  ...MAVNameInput,
  type: "textarea",
  placeHolder: "Enter Customer Type",
  name: "type",
  className: "!font-normal !text-[#000] customer-type",
  inputClass: "!text-[#fff]",
  label: "Customer Type",
  labelClass: "mandatory",
  validations: {
    ...PartnerNameValidation,
    rules: {
      required: true,
      minimum: 2,
      maximum: 30,
      pattern: Patterns.customerTypeStatus,
    },
  },
  maximum: 30,
};

export const updateCustomernameValidations = {
  type: "text",
  name: "name",
  inputClass: "",
  parentClass: "mav-search-box mr-[6px]",
  inputIcon: MagnifyingGlassIcon,
  filledStyle: "!text-[16px]",
};
export const formElement = {
  type: "select",
  name: "service",
  placeHolder: "Select Service",
  className: "mav-dropdown-wrapper !mb-[0px] w-[100px]",
  options: [
    { label: "All MAVs", value: 2 },
    { label: "Default", value: 1 },
    { label: "Custom", value: 0 },
  ],
};

export const ReservationName = {
  type: "textarea",
  name: "name",
  placeHolder: "Name",
  label: "Name",
  labelClass: "mandatory",
  className: "!pr-2.5 !w-[491px] inline-block template_name !mt-[3px] !font-normal",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 50 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  floatingPlaceholder: true,
  resize: false,
  rows: 1,
  maximum: 50,
};

export const ReservationDescription = {
  type: "textarea",
  name: "description",
  placeHolder: "Description",
  label: "Description",
  labelClass: "",
  className: "!pr-2.5 !w-100 !font-normal template_name !min-h-[53px]",
  isEditableClassName: "text-base leading-5 pb-3 !font-normal",
  floatingPlaceholder: true,
  resize: true,
  rows: 2,
  maximum: 2000,
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 2000 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
};

export const calculationTypeValidation = {
  type: "select",
  name: "calculationType",
  placeHolder: "Please Select",
  className: "!mb-8 w-[245px] !pr-2.5 inline-block",
  label: "Calculation Type",
  topLabel: { text: "Calculation Type", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  enableFilter: {
    placeHolder: "Search",
  },
  options: [],
};

export const serviceTypeValidation = {
  type: "select",
  name: "serviceType",
  placeHolder: "Select Service Type",
  className: "col-md-2 !pr-2.5 clear-left inline-block serviceType",
  label: "Service Type",
  topLabel: { text: "Service Type", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  enableFilter: { placeHolder: "Search" },
  options: [],
};

export const discountValueValidation = {
  type: "select",
  name: "discountValue",
  placeHolder: "Please Select",
  className: "!mb-8 w-[250px] !pr-2.5 inline-block template_type",
  label: "Discount Value (%)",
  enableFilter: { placeHolder: "Search" },
  topLabel: { text: "Discount Value (%)", fontSize: "x-small", class: "mandatory" },
  validations: requiredRules,
  options: [],
  hidden: true,
};

// const getPartnerInvoideDate = () => {
//   const userData = getLocalStorageUserData();
//   if (userData) {
//     const {
//       currentUser: { lastInvoiceDate },
//     } = userData;
//     return moment(lastInvoiceDate)?.format("DD-MMM-YYYY");
//   }
//   return moment();
// };

export const ReservationDiscountValidations = {
  type: "calender",
  name: "startDate",
  placeHolder: "DD-MM-YYYY",
  className: "w-[250px] pr-2.5 singleDateModule inline-block",
  singleDatePicker: true,
  topLabel: {
    text: "Start Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "Start Date",
  validations: requiredRules,
  startDate: moment().format("MM/DD/YYYY"),
  disableFutureDates: false,
  collapseState: true,
};

export const CBUListValidation = {
  type: "invoice-customer-input-type",
  name: "selectCbu",
  placeHolder: "Select CBU",
  className:
    "!mb-[20px] multiselect_dropdown_wrapper module_dropdown inline-block !pr-2.5 Grid_dropdown !w-[350px] v2",
  label: "Default Format",
  topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: requiredRules,
  labelClass: "mandatory",
  options: [],
  v2: true,
  labelText: "CBU",
  searchPlaceHolder: "",
};

export const discountTemplateValidations = {
  type: "select",
  name: "selectTemplate",
  placeHolder: "Select Template",
  className:
    "!mt-[10px] module_dropdown !pr-2.5 Grid_dropdown v2 select_template !w-[400px] inline-block relative !mb-[20px] mr-custom",
  label: "Default Format",
  topLabel: { text: "Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: requiredRules,
  labelClass: "mandatory",
  options: [
    {
      label: "Option-1",
      value: "Option-1",
      id: 1,
      postImage: {
        // icon: EyeIcon,
        // onIconClick,
        // props: { modalName: "cloneTemplate", modalId: item?.id },
      },
    },
  ],
  v2: true,
  // parentClass: "col-md-3",
  // labelClass: "mandatory",
  enableFilter: {
    placeHolder: "Search",
  },
};

export const absoluteTextValidation = {
  type: "number",
  name: "absoluteValue",
  placeHolder: "Add Value",
  inputClass: "",
  label: "Discount Value (%)",
  validations: {
    rules: {
      required: true,
      pattern: Patterns.positiveNumberLessThen100,
    },
    messages: {
      required: "This field is required",
      pattern: "Please enter a valid {{fieldName}}",
    },
  },
  labelClass: "mandatory",
  showLabel: "true",
  parentClass: "!w-[250px] inline-block mr-[10px] mb-[11px] !mt-[0px] template_name",
  filledStyle: "!text-[14px]",
  hidden: true,
};

// Support Configurations:

export const supportConfigComments = {
  type: "textarea",
  name: "comments",
  placeHolder: "Comments",
  label: "Comments",
  className: "cloudfrontpricing_name supportConfig_comment  floatingPlaceholder !w-full",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  floatingPlaceholder: true,
  resize: false,
  rows: 1,
  maximum: 2000,
  maxLength: 2000,
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 2000 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
};

export const accountIdsValidation = {
  type: "invoice-customer-input-type",
  name: "accountIds",
  placeHolder: "Choose Account(s)",
  className: "module_dropdown !pr-2.5 Grid_dropdown col-md-3 v2 unset_width !float-none	",
  label: "Choose Account(s)",
  topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: requiredRules,
  labelClass: "mandatory",
  // options: [],
  v2: true,
  // parentClass: "col-md-3",
  // labelClass: "mandatory",
  labelText: "Choose Account(s)",
  searchPlaceHolder: "",

  options: [{ label: "label", value: "value", checked: false }],
};

export const ConfigAlertValidations = [
  {
    type: "textarea",
    name: "budgetName",
    placeHolder: "Alert Name",
    label: "Alert Name",
    labelClass: "mandatory",
    className: "inline-block template_name utilization-inputs alert-name",
    isEditableClassName: "text-base leading-5 pb-3 font-medium",
    validations: requiredRules,
    floatingPlaceholder: true,
    resize: false,
    rows: 1,
    maximum: 50,
    showCounter: false,
  },
  {
    type: "select",
    name: "period",
    placeHolder: "Period",
    className: "inline-block serviceType utilization-inputs period",
    label: "Period",
    topLabel: { text: "Period", fontSize: "x-small", class: "mandatory" },
    validations: requiredRules,
    options: [
      {
        label: "Daily",
        value: "Daily",
      },
      {
        label: "Monthly",
        value: "Monthly",
      },
    ],
  },
  {
    type: "text",
    name: "utilizationThreshold",
    placeHolder: "Add Value",
    inputClass: "",
    label: "Utilization Threshold(%)",
    validations: {
      onChange: { percentage: true },
      rules: {
        required: true,
        percentage: 100,
      },
      messages: {
        required: "This field is required",
        percentage: "Please enter a valid {{fieldName}}",
      },
    },
    labelClass: "mandatory",
    showLabel: "true",
    parentClass: "utilization-inputs threshold inline-block template_name",
    filledStyle: "!text-[14px]",
  },
  {
    type: "select",
    name: "serviceType",
    placeHolder: "Service Type",
    className: "inline-block serviceType utilization-inputs service-type",
    label: "Service Type",
    topLabel: { text: "Service Type", fontSize: "x-small", class: "mandatory" },
    validations: requiredRules,
    options: [
      { label: "EC2", value: "EC2" },
      { label: "RDS", value: "RDS" },
      { label: "ELASTICACHE", value: "ELASTICACHE" },
      { label: "REDSHIFT", value: "REDSHIFT" },
      { label: "OPENSEARCH", value: "OPENSEARCH" },
      { label: "SAVINGPLAN", value: "SAVINGSPLAN" },
    ],
  },
  {
    ...configureMultiChipValidations,
    name: "emailNotificationGroup",
    // options: createOptionsForEmail(emailsNameMappedData),
    labelText: "Email Notification Group(s)",
    placeHolder: "Add Email Notification Group(s)",
    className: "utilization-inputs",
    labelClass: "!mt-0",
    validations: {
      ...optionalRules,
    },
    required: false,
    readMode: false,
    noDataFound: {
      noDataText: "No email notification group matched your search",
      noDataLabel: "Try another search, or add them to the space",
    },
  },
  {
    ...configureMultiChipValidations,
    name: "slackChannels",
    // options: createOptionsForEmail(emailsNameMappedData),
    labelText: "Slack Channel(s)",
    placeHolder: "Add Slack Channel(s)",
    className: "utilization-inputs",
    labelClass: "!mt-0",
    validations: {
      ...optionalRules,
    },
    required: false,
    readMode: false,
    noDataFound: {
      noDataText: "No slack group matched your search",
      noDataLabel: "Try another search, or add them to the space",
    },
  },
  {
    type: "auto-resize-textbox",
    name: "emailRecipients",
    placeHolder: "Add Recipients",
    label: "Email Recipient(s)",
    labelText: "Email Recipient(s)",
    labelClass: "",
    className:
      "!w-100 !font-normal template_name !min-h-[53px] utilization-inputs email-recipients",
    componentWrapperClass: "mt-[10px]",
    validations: {
      rules: {
        pattern: Patterns.emailCommaSpaceSeperated,
      },
      messages: {
        pattern: "Please enter a valid {{fieldName}}",
      },
    },
    detailedMsg: "Please seperate email address with commas",
  },
  {
    type: "auto-resize-textbox",
    name: "adminEmails",
    placeHolder: "Add Recipients",
    label: "Admin Email(s)",
    labelText: "Admin Email(s)",
    labelClass: "",
    className: "!w-100 !font-normal template_name !min-h-[53px] utilization-inputs admin-emails",
    componentWrapperClass: "mt-[10px]",
    validations: {
      rules: {
        pattern: Patterns.emailCommaSpaceSeperated,
      },
      messages: {
        pattern: "Please enter a valid {{fieldName}}",
      },
    },
    detailedMsg: "Please seperate email address with commas",
  },
];

export const configureCheckboxGroup = {
  type: "chekbox_group",
  name: "chekbox-group",
  validations: requiredRules,
};

export const aletNameConfiguration = {
  type: "textarea",
  name: "description",
  placeHolder: "Enter Description",
  label: "Description",
  // labelClass: "mandatory",
  className: "cloudfrontpricing_name mr-2 floatingPlaceholder !min-h-[53px]",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: optionalRules,
  floatingPlaceholder: true,
  resize: true,
  rows: 1,
  maximum: 2000,
  maxLength: 2000,
};

export const budgetAlertType = {
  type: "select",
  name: "budgetTypeSelected",
  placeHolder: "Select Role",
  className: "!mb-[0] !pr-2.5 !w-[180px] inline-block template_type budget_alertType",
  // label: "",
  // topLabel: { text: "Template Type", fontSize: "small", class: "!text-black mandatory" },
  validations: requiredRules,
  options: BUDGET_ALERT_TYPE,
};

export const customerStatusDropDown = {
  ...invoiceTemplateValidations,
  name: "selectStatus",
  placeHolder: "Select Customer Type",
  enableFilter: { placeHolder: "Search" },
  label: "Customer Type",
  labelClass: "mandatory !mt-0",
  topLabel: { text: "Customer Type", fontSize: "small", class: "mandatory !text-[#000]" },
  className: "v2 max-w-[auto] min-w-[223px] mt-[14px] inline-block mr-[10px]",
  v2: true,
};

export const customerDetailsLabel = {
  type: "Label",
  name: "customerDetailsLabel",
  placeHolder: "Customer Details",
  className: "label primary medium flex items-center !py-[4.5px] !px-0 w-full !mb-[14px]",
  validations: optionalRules,
};

export const customerStatusEffectiveDate = {
  ...dateConfiguration,
  name: "effectiveDate",
  label: "Effective Date",
  placeHolder: "DD-MM-YYYY",
  topLabel: {
    text: "Effective Date",
    fontSize: "",
    class: "text-field small !text-black primary mt-2.5 mandatory",
  },
};

export const activeStatusLabel = {
  type: "Label",
  name: "ActiveStatusLabel",
  placeHolder: "",
  className: "!text-[#000] !text-[15px]",
  validations: optionalRules,
};

export const cloudFrontCBU = {
  type: "invoice-customer-input-type",
  name: "cbu",
  placeHolder: "Select CBU(s)",
  className: "module_dropdown cloudfront_form_fields !mb-[11px] v2 !w-[33%]",
  label: "Choose Account(s)",
  topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: {
    rules: {
      required: true,
    },
    messages: {
      required: "This field is required",
    },
  },
  labelClass: "mandatory",
  v2: true,
  labelText: "Select CBU(s)",
  searchPlaceHolder: "",
  options: [
    {
      label: "TATA-Sky Delhi",
      value: "TATA-Sky Delhi",
      checked: false,
    },
    {
      label: "TATA-Sky Mumbai",
      value: "TATA-Sky Mumbai",
      checked: false,
    },
    {
      label: "TATA-Sky Modinagar",
      value: "TATA-Sky Modinagar",
      checked: false,
    },
  ],
};

export const cloudFrontRegionList = {
  type: "invoice-customer-input-type",
  name: "region",
  placeHolder: "Select Region(s)",
  className: "module_dropdown cloudfront_form_fields !mb-[11px] v2 !w-[33%]",
  label: "Choose Account(s)",
  topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: {
    rules: {
      required: true,
    },
    messages: {
      required: "This field is required",
    },
  },
  labelClass: "mandatory",
  v2: true,
  labelText: "Select Region(s)",
  searchPlaceHolder: "",
  options: [
    {
      label: "Asia-pacific",
      value: "Asia-pacific",
      checked: false,
    },
    {
      label: "US-West",
      value: "US-West",
      checked: false,
    },
    {
      label: "US-East",
      value: "US-East",
      checked: false,
    },
  ],
};

export const cloudFrontPricing = {
  type: "invoice-customer-input-type",
  name: "pricing",
  placeHolder: "Select Pricing(s)",
  className: "module_dropdown cloudfront_form_fields !mb-[11px] v2 !w-[33%]",
  label: "Choose Account(s)",
  topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
  validations: {
    rules: {
      required: true,
    },
    messages: {
      required: "This field is required",
    },
  },
  labelClass: "mandatory",
  v2: true,
  labelText: "Select Pricing(s)",
  searchPlaceHolder: "",
  options: [
    {
      label: "HTTP Request ($)",
      value: "HTTP Request ($)",
      checked: false,
    },
    {
      label: "HTTPS Request ($)",
      value: "HTTPS Request ($)",
      checked: false,
    },
    {
      label: "DT Out to Internet",
      value: "DT Out to Internet",
      checked: false,
    },
  ],
};

export const editableTableConfig = {
  type: "radio",
  name: "yearlyCalculationCondition",
  placeHolder: "Select which to apply first",
  className: "cld_cloudfront_radio_group",
  buttonStyle: false,
  label: "Select which to apply first",
  childClassName: "pt-[5px] pl-[20px] font-bold	",
  validations: requiredRules,
  options: [
    { label: "Private Rate", value: "public" },
    { label: "Discount on Public Rate", value: "discount" },
  ],
};

export const handleNewRegion = {
  type: "toggleInput",
  name: "newRegion",
  label: "handleNewRegion",
  className: "",
  validations: optionalRules,
};

export const cloudFrontPricingValidationsOld = [
  {
    type: "textarea",
    name: "name",
    placeHolder: "Enter Name",
    label: "Name",
    labelClass: "mandatory",
    className: "cloudfrontpricing_name mr-2 floatingPlaceholder !w-[491px]",
    isEditableClassName: "text-base leading-5 pb-3 font-medium",
    validations: {
      ...optionalRules,
      rules: { ...optionalRules.rules, maximum: 50 },
      messages: { maximum: "Maximum {{digit}} characters are allowed" },
    },
    floatingPlaceholder: true,
    resize: false,
    rows: 1,
    maximum: 50,
  },
  {
    type: "textarea",
    name: "description",
    placeHolder: "Enter Description",
    label: "Description",
    className: "cloudfrontpricing_description floatingPlaceholder !min-h-[50px]",
    labelClass: "mandatory",
    isEditableClassName: "text-sm font-normal leading-4 pb-5",
    validations: {
      ...optionalRules,
      rules: { ...optionalRules.rules, maximum: 200 },
      messages: { maximum: "Maximum {{digit}} characters are allowed" },
    },
    floatingPlaceholder: true,
    resize: true,
    rows: 3,
    maximum: 200,
  },
];

export const ordingTemplateApplyConfig = [
  {
    type: "Label",
    name: "heading",
    className: "!text-[11px] !font-normal !mt-0 !text-[#000]",
    validations: optionalRules,
    placeHolder: "Template Name",
  },
  {
    type: "Label",
    name: "name",
    className: "!text-[14px] !font-medium !text-[#000]",
    validations: optionalRules,
  },
  {
    type: "invoice-customer-input-type",
    name: "customers",
    className: "module_dropdown cloudfront_form_fields !mt-[20px] !mb-[0px] v2 ",
    label: "Customer(s)",
    placeHolder: "Select Customer(s)",
    topLabel: { text: "Select Template", fontSize: "x-small", class: "mandatory mt-0" },
    validations: {
      rules: {
        required: true,
      },
      messages: {
        required: "This field is required",
      },
    },
    labelClass: "mandatory !mt-[0] !text-[12px]",
    v2: true,
    labelText: "Customer(s)",
    searchPlaceHolder: "",
    options: [],
  },
  {
    type: "calender",
    name: "startDate",
    placeHolder: "Start Date",
    className: "singleDateModule mr-[0]",
    singleDatePicker: true,
    openPosition: "right",
    topLabel: {
      text: "Start Date",
      fontSize: "",
      class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
    },
    labelText: "Start Date",
    validations: requiredRules,
    disableFutureDates: false,
    disablePreviousDates: true,
    minDate: getPartnerInvoideDate(),
  },
];

export const constructOrderingTemplate = {
  type: "textarea",
  name: "name",
  placeHolder: "Enter Template Name",
  label: "Template Name",
  labelClass: "mandatory",
  className: "!pr-2.5 !w-[100%] inline-block template_name !font-normal",
  isEditableClassName: "text-base leading-5 pb-3 font-medium",
  validations: {
    rules: {
      required: true,
      maximum: 70,
    },
    messages: {
      required: "This field is required",
      maximum: "Maximum {{digit}} digits are allowed",
    },
  },
  floatingPlaceholder: true,
  resize: false,
  rows: 1,
  maximum: 70,
};

export const customerDescriptionValidation = {
  type: "textarea",
  name: "description",
  placeHolder: "Enter Description",
  label: "Description",
  className: "w-100",
  validations: {
    ...optionalRules,
    rules: { ...optionalRules.rules, maximum: 2000 },
    messages: { maximum: "Maximum {{digit}} characters are allowed" },
  },
  resize: true,
  rows: 2,
  maximum: 2000,
};

export const userStatementPersonalization = {
  type: "headerComponent",
  name: "userStatementPersonalization",
  heading: {
    name: "Usage Statement Personalization",
    class: "font-medium text-[14px] leading-5 text-center usage_header",
  },
  infoIconEnabled: true,
  infoIcon: {
    text: "Choose personalization settings for “usage type statements” of the customer",
  },
  hideSeprator: false,
  wrapperClass: "mb-[10px] user_statement_personalization",
  config: [
    {
      component: Dropdown,
      type: "select",
      name: "invoiceTemplate",
      placeHolder: "Select Template Type",
      label: "Template Type",
      topLabel: { text: "Template Type", fontSize: "small", class: "!text-black mandatory" },
      validations: requiredRules,
      enableFilter: { placeHolder: "Search" },
      options: [],
    },
    {
      component: Dropdown,
      type: "select",
      name: "invoiceGroup",
      placeHolder: "Select Usage Statement Grouping",
      // className: "w-[347px] mt-5",
      label: "Usage Statement Grouping",
      topLabel: {
        text: "Usage Statement Grouping",
        fontSize: "small",
        class: "!text-black mandatory",
      },
      enableFilter: { placeHolder: "Search" },
      validations: requiredRules,
      options: [],
    },
    {
      component: Dropdown,
      type: "select",
      name: "invoiceType",
      placeHolder: "Select Usage Statement Format",
      // className: "w-[347px] mt-5",
      label: "Usage Statement Format",
      topLabel: {
        text: "Usage Statement Format",
        fontSize: "small",
        class: "!text-black mandatory",
      },
      enableFilter: { placeHolder: "Search" },
      validations: requiredRules,
      options: [],
    },
  ],
};

export const constructOrderingEffectiveDate = {
  ...dateConfiguration,
  className: "w-[200px] inline-block !mb-0 ordering-effective-date",
  name: "templateStartDate",
  label: "Effective Date",
  placeHolder: "DD-MM-YYYY",
  topLabel: {
    text: "Effective Date",
    fontSize: "",
    class: "text-field small !text-black primary mt-0 mandatory",
  },
};

export const customerTypeEffectiveDate = {
  ...dateConfiguration,
  className: "w-[200px] inline-block !mb-[18px] ordering-effective-date",
  name: "customerStatusStartDate",
  label: "Effective Date",
  placeHolder: "DD-MM-YYYY",
  topLabel: {
    text: "Effective Date",
    fontSize: "",
    class: "text-field small !text-black primary mt-0 mandatory",
  },
};

export const autoCompleteDropdown = {
  type: "autoCompleteInput",
  keyToShowInOptions: "label",
  labelClass: "mandatory !pb-[5px] font-medium text-[12px] text-black block",
  validations: requiredRules,
};

export const DataExportDeliveryConfig = {
  type: "headerComponent",
  heading: {
    name: "Partner detail:",
    class: "font-semibold text-black mandatory text-[18px] leading-[20px]",
  },
  wrapperClass: "mb-[10px] !w-3/12 p-5 border border-solid border-[#0a3ca2]",
  config: [
    {
      component: MultiSelect,
      type: "select",
      name: "Partner Name",
      placeHolder: "Select Partner Name",
      className: "!mb-[0px] !pr-2.5 !w-full inline-block CUR-dropdown",
      label: "Partner Name",
      topLabel: {
        text: "Partner Name",
        fontSize: "14px",
        class: "!text-black font-bold compression-type-header-style",
      },
      validations: requiredRules,
      options: [
        { label: "CK AZ", value: "CK AZ", description: "Format type: CK AZ" },
        { label: "CK AUTO", value: "CK AUTO", description: "Format type: text/csv" },
      ],
    },
    {
      component: Dropdown,
      type: "select",
      name: "Partner Resources",
      placeHolder: "Select Partner Resources",
      className: "!mb-[0px] !pr-2.5 !w-full inline-block CUR-dropdown",
      label: "Partner Resources",
      topLabel: {
        text: "Partner Resources",
        fontSize: "14px",
        class: "!text-black font-bold compression-type-header-style",
      },
      validations: requiredRules,
      options: [
        { label: "Customers", value: "Customers", description: "Format type: LENS" },
        { label: "Users", value: "Users", description: "Format type: text/csv" },
        { label: "Invoice", value: "Invoice", description: "Format type: text/csv" },
      ],
    },
    {
      component: Dropdown,
      type: "select",
      name: "Customer Name",
      placeHolder: "Select Customer Name",
      className: "!mb-[0px] !pr-2.5 !w-full inline-block CUR-dropdown",
      label: "Customer Name",
      topLabel: {
        text: "Customer Name",
        fontSize: "14px",
        class: "!text-black font-bold compression-type-header-style",
      },
      validations: requiredRules,
      options: [
        { label: "TATA PLAY", value: "TATA PLAY", description: "Format type: TATA PLAY" },
        {
          label: "GCP INSGIHTS CUSTOMER",
          value: "GCP INSGIHTS CUSTOMER",
          description: "Format type: text/csv",
        },
      ],
    },
    {
      component: Dropdown,
      type: "select",
      name: "Customer Resources",
      placeHolder: "Select Customer Resources",
      className: "!mb-[0px] !pr-2.5 !w-full inline-block CUR-dropdown",
      label: "Customer Resources",
      topLabel: {
        text: "Customer Resources",
        fontSize: "14px",
        class: "!text-black font-bold compression-type-header-style",
      },
      validations: requiredRules,
      options: [
        { label: "Billing Summary", value: "Billing Summary", description: "Format type: LENS" },
        {
          label: "Billing Console",
          value: "Billing Console",
          description: "Format type: text/csv",
        },
        { label: "Cost explorer", value: "Cost explorer", description: "Format type: text/csv" },
      ],
    },
  ],
};
