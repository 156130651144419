/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react";
import MultiSelect from "../FormConfig/Form/Components/MultiSelect";
import {
  renderFlowIconsV2,
  getSortedList,
  resetList,
  fireHandleChange,
  getLeftHeading,
} from "../CustomerLinkedAccId/utils";
import "./style.scss";
import resetItems, { isResetDisabled, noDataLabelStructure } from "./util";
import InfoBox from "../CK-Lens/Common/InfoBox";
import { ResetIcon } from "../../Constants/icons";

export default function TransferList({
  name,
  handleChange,
  options = [],
  value = [],
  error,
  touched,
  resetClicked,
  noDataLabel,
  noDataLabelHeading,
  leftHeading,
  rightHeading,
  leftListHeader,
  noDataMsgs = {},
  noDataIcon = "",
  disabled = false,
  infoBoxMessage = "",
  infoBoxClass = "",
  tooltip = false,
  classList = "",
  componentHeader = "",
  parentClass = "",
}) {
  const [leftSideItems, setLeftSideItems] = useState([]);
  const [leftSideSelectedItems, setLeftSideSelectedItems] = useState([]);

  const [rightSideItems, setRightSideItems] = useState([]);
  const [rightSideItemsClone, setRightSideItemsClone] = useState([]);

  const [rightSideSelectedItems, setRightSideSelectedItems] = useState([]);

  useEffect(() => {
    if (resetClicked) {
      setRightSideItems([]);
      setLeftSideItems(options.map((item) => ({ ...item, checked: false })));
      setLeftSideSelectedItems([]);
      setRightSideSelectedItems([]);
    }
  }, [resetClicked]);

  const populateFileds = () => {
    const filteredArray = options.filter(
      (item) => !value.some((smallItem) => smallItem?.value === item?.value)
    );
    if (options.length) {
      setLeftSideItems(filteredArray.map((item) => ({ ...item, checked: false })));
    }

    setRightSideItems(value);
  };

  useEffect(() => {
    populateFileds();
    // setRightSideItemsClone(...value);
    setRightSideItemsClone(JSON.parse(JSON.stringify(value)));
  }, [options]);

  const checkItems = (event, stateFn, listToEmpty) => {
    const {
      target: { value: targetValue },
    } = event;

    stateFn(targetValue);

    if (listToEmpty === "rightSide") {
      resetList(setRightSideItems, setRightSideSelectedItems, rightSideItems);
    } else {
      resetList(setLeftSideItems, setLeftSideSelectedItems, leftSideItems);
    }
  };

  // left side event handlers
  const addItemToRightSide = () => {
    if (leftSideSelectedItems?.length) {
      resetItems(leftSideItems, leftSideSelectedItems, setLeftSideItems, setLeftSideSelectedItems);
      const updatedRightSideItems = [...rightSideItems, ...leftSideSelectedItems];
      setRightSideItems(getSortedList(updatedRightSideItems));

      fireHandleChange(updatedRightSideItems, handleChange, name);
    }
  };
  // left side event handlers

  // right side event handlers
  const addItemToLeftSide = () => {
    if (rightSideSelectedItems?.length) {
      const updatedRightSideList = resetItems(
        rightSideItems,
        rightSideSelectedItems,
        setRightSideItems,
        setRightSideSelectedItems
      );
      setLeftSideItems(getSortedList([...leftSideItems, ...rightSideSelectedItems]));
      fireHandleChange(updatedRightSideList, handleChange, name);
    }
  };
  const resetSelection = (leftSide = false) => {
    if (leftSide) resetList(setLeftSideItems, setLeftSideSelectedItems, leftSideItems);
    else resetList(setRightSideItems, setRightSideSelectedItems, rightSideItems);
  };

  const resetFormData = () => {
    setRightSideItems([...rightSideItemsClone]);
    setLeftSideItems(options.map((item) => ({ ...item, checked: false })));
    setLeftSideSelectedItems([]);
    setRightSideSelectedItems([]);
    fireHandleChange(rightSideItemsClone, handleChange, name);
  };

  return (
    <div className={parentClass}>
      {componentHeader && (
        <div className="flex items-center gap-[26px] mb-[10px] pt-[20px]">
          <h3>{componentHeader}</h3>
          <div
            className={`reset-wrapper ${
              isResetDisabled(rightSideItems, rightSideItemsClone) ? "disabled" : null
            }`}
          >
            <p
              aria-hidden
              className="flex justify-end gap-x-1 text-[#0A3CA2] text-xs items-center font-medium cursor-pointer pb-[4px] reset-icon-btn"
              onClick={() => {
                if (!isResetDisabled(rightSideItems, rightSideItemsClone)) {
                  resetFormData();
                }
              }}
            >
              <ResetIcon className="w-2 h-2" /> Reset
            </p>
          </div>
        </div>
      )}
      <div className={`customer-linked-acc-wrapper TransferListWrapper ${classList}`}>
        {disabled && <InfoBox message={infoBoxMessage} customClass={infoBoxClass} />}
        <div className="flex items-center justify-between">
          <div
            className={` multi-select ${disabled ? "transferlist-disabled" : ""} ${
              !disabled ? "mavList-height" : ""
            }`}
          >
            {getLeftHeading(leftHeading, leftSideItems, leftListHeader)}
            <MultiSelect
              searchEnabled
              options={leftSideItems}
              selected={leftSideSelectedItems}
              handleChange={(event) => checkItems(event, setLeftSideSelectedItems, "rightSide")}
              name={name}
              resetSelection={() => resetSelection(true)}
              placeholder=""
              searchIconPostion="left"
              isSelectAll={leftSideItems?.length > 1}
              dashboard="transferList"
              noDataText={noDataLabelStructure(noDataLabel, noDataMsgs?.left?.subHeading)}
              noDataLabel={noDataMsgs?.left?.heading}
              noDataIcon={noDataIcon}
              noSearchFoundText="No Results Found"
              disabled={disabled}
              tooltip={tooltip}
            />
          </div>
          <div className="flow-icon-wrapper flex flex-col gap-y-[5px] mx-[5px]">
            {renderFlowIconsV2(
              leftSideSelectedItems,
              addItemToRightSide,
              rightSideSelectedItems,
              addItemToLeftSide
            )}
          </div>
          <div
            className={`multi-select ${disabled ? "transferlist-disabled" : ""} ${
              !disabled ? "mavList-height" : ""
            }`}
          >
            {getLeftHeading(rightHeading, rightSideItems)}
            <MultiSelect
              searchEnabled
              options={rightSideItems}
              selected={rightSideSelectedItems}
              handleChange={(event) => checkItems(event, setRightSideSelectedItems, "leftSide")}
              name={name}
              resetSelection={() => resetSelection()}
              placeholder=""
              searchIconPostion="left"
              isSelectAll={rightSideItems?.length > 1}
              dashboard="transferList"
              noDataText={noDataLabelStructure(noDataLabel, noDataMsgs?.right?.subHeading)}
              noDataLabel={noDataLabelHeading || noDataMsgs?.right?.heading}
              noDataIcon={noDataIcon}
              noSearchFoundText="No Results Found"
              disabled={disabled}
              tooltip={tooltip}
            />
          </div>
        </div>
        {touched && error && <p className="error_message">{error}</p>}
      </div>
    </div>
  );
}
