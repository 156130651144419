import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import { Tooltip } from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { Link } from "react-router-dom";
// import FormInput from "../../../CK-Auto/Common/Form/Input";
import PpaInput from "./PrivatePricingAgreements/ppaInput";
import ListPopup from "../../../Common/ListPopup";
import { ReactComponent as InfoIcon } from "../../../../Assets/images/ic-info.svg";
import BasicSelect from "../../../FormConfig/Form/Components/DropDown";
import { ConvertToBoolean } from "../../../CK-Auto";
import EditIcon from "../../../../Assets/icons/pen.svg";
import EditIconGrey from "../../../../Assets/icons/pen-grey.svg";
import EndDiscount from "../../../../Assets/icons/endDiscount.svg";
import EndDiscountActive from "../../../../Assets/icons/endDiscountActive.svg";
import ReservationsDisabled from "../../../../Assets/icons/ic-end-reservations_disabled.svg";
import ReservationsActive from "../../../../Assets/icons/ic-end-reservations_active.svg";
import { requiredRulesExport } from "../../../FormConfig";
import FlatDiscountTooltip from "./FlatDiscountList/FlatDiscountTooltip";
import DimensionBox from "../../../FormConfig/Form/Components/ListSelector.js/DimensionBox";
import { ReactComponent as DiscountConfigurationIcon } from "../../../../Assets/icons/discountConfigurationIcon.svg";
import EndFlatActive from "../../../../Assets/icons/ic-end-selected.svg";
import EndFlatDisable from "../../../../Assets/icons/ic-end-disc.svg";
import EndPPAActive from "../../../../Assets/icons/end-ppa-enabled.svg";
import EndPPADisable from "../../../../Assets/icons/end-ppa-disabled.svg";
import Loader from "../../../Common/Loader";
import TooltipV2 from "../../../Common/TooltipV2";
import { LinearProgressWithLabel } from "../../../CK-Auto/Common/ProgressBar";
import { renderYear as getRenderYear, noop } from "../../../../Utils/commonUtils";
import Routes from "../../../../Config/Routes";
import { MinusIcon, PlusIcon } from "../../../../Constants/icons";
import { ReactComponent as ActiveArrow } from "../../../../Assets/icons/external-link-dropdown.svg";
import Draggable from "../../../Common/Draggable";
import Button from "../../../FormConfig/Form/Components/Button";
// eslint-disable-next-line import/no-cycle
import FilterTable from "../../../Common/Table/filterTable";
import DialogComponent from "../../../Common/Modal/Dialog";
import SearchBar from "../../../FormConfig/Form/Components/SearchBar";
import DownloadButton from "./downloadbutton";
import FormInput from "../../../CK-Auto/Common/Form/Input";
import { ReactComponent as EyeActiveIcon } from "../../../../Assets/icons/eye-active.svg";
import SupportEditIconBlue from "../../../../Assets/icons/ic-edit.svg";
import SupportEditIconGrey from "../../../../Assets/icons/ic-edit-disabled.svg";
import commonService from "../../../../Services/commonService";
import { formatDate } from "./Credits/service/helper";
import { ReactComponent as MessageIcon } from "./Credits/Icons/ic-message.svg";
import NUllifyIcon from "../../../../Assets/icons/billdesk-icons/ic-nullify.svg";
import NUllifyIconGrey from "../../../../Assets/icons/billdesk-icons/ic-nullify-grey.svg";

export const COUNTRY_LIST = [
  {
    name: "Andorra",
    code: "AD",
    emoji: "🇦🇩",
    unicode: "U+1F1E6 U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    emoji: "🇦🇪",
    unicode: "U+1F1E6 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
  },
  {
    name: "Afghanistan",
    code: "AF",
    emoji: "🇦🇫",
    unicode: "U+1F1E6 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
  },
  {
    name: "Antigua And Barbuda",
    code: "AG",
    emoji: "🇦🇬",
    unicode: "U+1F1E6 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
  },
  {
    name: "Anguilla",
    code: "AI",
    emoji: "🇦🇮",
    unicode: "U+1F1E6 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
  },
  {
    name: "Albania",
    code: "AL",
    emoji: "🇦🇱",
    unicode: "U+1F1E6 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
  },
  {
    name: "Armenia",
    code: "AM",
    emoji: "🇦🇲",
    unicode: "U+1F1E6 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
  },
  {
    name: "Angola",
    code: "AO",
    emoji: "🇦🇴",
    unicode: "U+1F1E6 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
  },
  {
    name: "Antarctica",
    code: "AQ",
    emoji: "🇦🇶",
    unicode: "U+1F1E6 U+1F1F6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
  },
  {
    name: "Argentina",
    code: "AR",
    emoji: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
  },
  {
    name: "American Samoa",
    code: "AS",
    emoji: "🇦🇸",
    unicode: "U+1F1E6 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
  },
  {
    name: "Austria",
    code: "AT",
    emoji: "🇦🇹",
    unicode: "U+1F1E6 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
  },
  {
    name: "Australia",
    code: "AU",
    emoji: "🇦🇺",
    unicode: "U+1F1E6 U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
  },
  {
    name: "Aruba",
    code: "AW",
    emoji: "🇦🇼",
    unicode: "U+1F1E6 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
  },
  {
    name: "Aland Islands",
    code: "AX",
    emoji: "🇦🇽",
    unicode: "U+1F1E6 U+1F1FD",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    emoji: "🇦🇿",
    unicode: "U+1F1E6 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    emoji: "🇧🇦",
    unicode: "U+1F1E7 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
  },
  {
    name: "Barbados",
    code: "BB",
    emoji: "🇧🇧",
    unicode: "U+1F1E7 U+1F1E7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
  },
  {
    name: "Bangladesh",
    code: "BD",
    emoji: "🇧🇩",
    unicode: "U+1F1E7 U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
  },
  {
    name: "Belgium",
    code: "BE",
    emoji: "🇧🇪",
    unicode: "U+1F1E7 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    emoji: "🇧🇫",
    unicode: "U+1F1E7 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
  },
  {
    name: "Bulgaria",
    code: "BG",
    emoji: "🇧🇬",
    unicode: "U+1F1E7 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
  },
  {
    name: "Bahrain",
    code: "BH",
    emoji: "🇧🇭",
    unicode: "U+1F1E7 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
  },
  {
    name: "Burundi",
    code: "BI",
    emoji: "🇧🇮",
    unicode: "U+1F1E7 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
  },
  {
    name: "Benin",
    code: "BJ",
    emoji: "🇧🇯",
    unicode: "U+1F1E7 U+1F1EF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
  },
  {
    name: "Saint-Barthelemy",
    code: "BL",
    emoji: "🇧🇱",
    unicode: "U+1F1E7 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
  },
  {
    name: "Bermuda",
    code: "BM",
    emoji: "🇧🇲",
    unicode: "U+1F1E7 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
  },
  {
    name: "Brunei",
    code: "BN",
    emoji: "🇧🇳",
    unicode: "U+1F1E7 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
  },
  {
    name: "Bolivia",
    code: "BO",
    emoji: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
  },
  {
    name: "Netherlands",
    code: "BQ",
    emoji: "🇧🇶",
    unicode: "U+1F1E7 U+1F1F6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
  },
  {
    name: "Brazil",
    code: "BR",
    emoji: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
  },
  {
    name: "The Bahamas",
    code: "BS",
    emoji: "🇧🇸",
    unicode: "U+1F1E7 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
  },
  {
    name: "Bhutan",
    code: "BT",
    emoji: "🇧🇹",
    unicode: "U+1F1E7 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    emoji: "🇧🇻",
    unicode: "U+1F1E7 U+1F1FB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg",
  },
  {
    name: "Botswana",
    code: "BW",
    emoji: "🇧🇼",
    unicode: "U+1F1E7 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
  },
  {
    name: "Belarus",
    code: "BY",
    emoji: "🇧🇾",
    unicode: "U+1F1E7 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
  },
  {
    name: "Belize",
    code: "BZ",
    emoji: "🇧🇿",
    unicode: "U+1F1E7 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
  },
  {
    name: "Canada",
    code: "CA",
    emoji: "🇨🇦",
    unicode: "U+1F1E8 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    emoji: "🇨🇨",
    unicode: "U+1F1E8 U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
  },
  {
    name: "Democratic Republic of the Congo",
    code: "CD",
    emoji: "🇨🇩",
    unicode: "U+1F1E8 U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
  },
  {
    name: "Central African Republic",
    code: "CF",
    emoji: "🇨🇫",
    unicode: "U+1F1E8 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
  },
  {
    name: "Congo",
    code: "CG",
    emoji: "🇨🇬",
    unicode: "U+1F1E8 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
  },
  {
    name: "Switzerland",
    code: "CH",
    emoji: "🇨🇭",
    unicode: "U+1F1E8 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    code: "CI",
    emoji: "🇨🇮",
    unicode: "U+1F1E8 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
  },
  {
    name: "Cook Islands",
    code: "CK",
    emoji: "🇨🇰",
    unicode: "U+1F1E8 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
  },
  {
    name: "Chile",
    code: "CL",
    emoji: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
  },
  {
    name: "Cameroon",
    code: "CM",
    emoji: "🇨🇲",
    unicode: "U+1F1E8 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
  },
  {
    name: "China",
    code: "CN",
    emoji: "🇨🇳",
    unicode: "U+1F1E8 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
  },
  {
    name: "Colombia",
    code: "CO",
    emoji: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
  },
  // {
  //   name: "Clipperton Island",
  //   code: "CP",
  //   emoji: "🇨🇵",
  //   unicode: "U+1F1E8 U+1F1F5",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CP.svg",
  // },
  {
    name: "Costa Rica",
    code: "CR",
    emoji: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
  },
  {
    name: "Cuba",
    code: "CU",
    emoji: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
  },
  {
    name: "Cape Verde",
    code: "CV",
    emoji: "🇨🇻",
    unicode: "U+1F1E8 U+1F1FB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
  },
  {
    name: "Curaçao",
    code: "CW",
    emoji: "🇨🇼",
    unicode: "U+1F1E8 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
  },
  {
    name: "Christmas Island",
    code: "CX",
    emoji: "🇨🇽",
    unicode: "U+1F1E8 U+1F1FD",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
  },
  {
    name: "Cyprus",
    code: "CY",
    emoji: "🇨🇾",
    unicode: "U+1F1E8 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    emoji: "🇨🇿",
    unicode: "U+1F1E8 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
  },
  {
    name: "Germany",
    code: "DE",
    emoji: "🇩🇪",
    unicode: "U+1F1E9 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
  },
  // {
  //   name: "Diego Garcia",
  //   code: "DG",
  //   emoji: "🇩🇬",
  //   unicode: "U+1F1E9 U+1F1EC",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg",
  // },
  {
    name: "Djibouti",
    code: "DJ",
    emoji: "🇩🇯",
    unicode: "U+1F1E9 U+1F1EF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
  },
  {
    name: "Denmark",
    code: "DK",
    emoji: "🇩🇰",
    unicode: "U+1F1E9 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
  },
  {
    name: "Dominica",
    code: "DM",
    emoji: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    emoji: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
  },
  {
    name: "Algeria",
    code: "DZ",
    emoji: "🇩🇿",
    unicode: "U+1F1E9 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
  },
  // {
  //   name: "Ceuta & Melilla",
  //   code: "EA",
  //   emoji: "🇪🇦",
  //   unicode: "U+1F1EA U+1F1E6",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg",
  // },
  {
    name: "Ecuador",
    code: "EC",
    emoji: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
  },
  {
    name: "Estonia",
    code: "EE",
    emoji: "🇪🇪",
    unicode: "U+1F1EA U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
  },
  {
    name: "Egypt",
    code: "EG",
    emoji: "🇪🇬",
    unicode: "U+1F1EA U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
  },
  {
    name: "Western Sahara",
    code: "EH",
    emoji: "🇪🇭",
    unicode: "U+1F1EA U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg",
  },
  // {
  //   name: "Wallis And Futuna Islands",
  //   code: "WF",
  //   emoji: "🇼🇫",
  //   unicode: "681",
  //   image:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Flag_of_Wallis_and_Futuna.svg/800px-Flag_of_Wallis_and_Futuna.svg.png",
  // },
  {
    name: "Eritrea",
    code: "ER",
    emoji: "🇪🇷",
    unicode: "U+1F1EA U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
  },
  {
    name: "Spain",
    code: "ES",
    emoji: "🇪🇸",
    unicode: "U+1F1EA U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
  },
  {
    name: "Ethiopia",
    code: "ET",
    emoji: "🇪🇹",
    unicode: "U+1F1EA U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
  },
  // {
  //   name: "European Union",
  //   code: "EU",
  //   emoji: "🇪🇺",
  //   unicode: "U+1F1EA U+1F1FA",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg",
  // },
  {
    name: "Finland",
    code: "FI",
    emoji: "🇫🇮",
    unicode: "U+1F1EB U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
  },
  {
    name: "Fiji Islands",
    code: "FJ",
    emoji: "🇫🇯",
    unicode: "U+1F1EB U+1F1EF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
  },
  {
    name: "Falkland Islands",
    code: "FK",
    emoji: "🇫🇰",
    unicode: "U+1F1EB U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
  },
  {
    name: "Micronesia",
    code: "FM",
    emoji: "🇫🇲",
    unicode: "U+1F1EB U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    emoji: "🇫🇴",
    unicode: "U+1F1EB U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
  },
  {
    name: "France",
    code: "FR",
    emoji: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
  },
  {
    name: "Gabon",
    code: "GA",
    emoji: "🇬🇦",
    unicode: "U+1F1EC U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
  },
  {
    name: "United Kingdom",
    code: "GB",
    emoji: "🇬🇧",
    unicode: "U+1F1EC U+1F1E7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
  },
  {
    name: "Grenada",
    code: "GD",
    emoji: "🇬🇩",
    unicode: "U+1F1EC U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
  },
  {
    name: "Georgia",
    code: "GE",
    emoji: "🇬🇪",
    unicode: "U+1F1EC U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
  },
  {
    name: "French Guiana",
    code: "GF",
    emoji: "🇬🇫",
    unicode: "U+1F1EC U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg",
  },
  {
    name: "Guernsey and Alderney",
    code: "GG",
    emoji: "🇬🇬",
    unicode: "U+1F1EC U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
  },
  {
    name: "Ghana",
    code: "GH",
    emoji: "🇬🇭",
    unicode: "U+1F1EC U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
  },
  {
    name: "Gibraltar",
    code: "GI",
    emoji: "🇬🇮",
    unicode: "U+1F1EC U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
  },
  {
    name: "Greenland",
    code: "GL",
    emoji: "🇬🇱",
    unicode: "U+1F1EC U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
  },
  {
    name: "Gambia The",
    code: "GM",
    emoji: "🇬🇲",
    unicode: "U+1F1EC U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
  },
  {
    name: "Guinea",
    code: "GN",
    emoji: "🇬🇳",
    unicode: "U+1F1EC U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    emoji: "🇬🇵",
    unicode: "U+1F1EC U+1F1F5",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    emoji: "🇬🇶",
    unicode: "U+1F1EC U+1F1F6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
  },
  {
    name: "Greece",
    code: "GR",
    emoji: "🇬🇷",
    unicode: "U+1F1EC U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
  },
  {
    name: "South Georgia",
    code: "GS",
    emoji: "🇬🇸",
    unicode: "U+1F1EC U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg",
  },
  {
    name: "Guatemala",
    code: "GT",
    emoji: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
  },
  {
    name: "Guam",
    code: "GU",
    emoji: "🇬🇺",
    unicode: "U+1F1EC U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    emoji: "🇬🇼",
    unicode: "U+1F1EC U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
  },
  {
    name: "Guyana",
    code: "GY",
    emoji: "🇬🇾",
    unicode: "U+1F1EC U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
  },
  {
    name: "Hong Kong S.A.R.",
    code: "HK",
    emoji: "🇭🇰",
    unicode: "U+1F1ED U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    emoji: "🇭🇲",
    unicode: "U+1F1ED U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg",
  },
  {
    name: "Honduras",
    code: "HN",
    emoji: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
  },
  {
    name: "Croatia",
    code: "HR",
    emoji: "🇭🇷",
    unicode: "U+1F1ED U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
  },
  {
    name: "Haiti",
    code: "HT",
    emoji: "🇭🇹",
    unicode: "U+1F1ED U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
  },
  {
    name: "Hungary",
    code: "HU",
    emoji: "🇭🇺",
    unicode: "U+1F1ED U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
  },
  // {
  //   name: "Canary Islands",
  //   code: "IC",
  //   emoji: "🇮🇨",
  //   unicode: "U+1F1EE U+1F1E8",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg",
  // },
  {
    name: "Indonesia",
    code: "ID",
    emoji: "🇮🇩",
    unicode: "U+1F1EE U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
  },
  {
    name: "Ireland",
    code: "IE",
    emoji: "🇮🇪",
    unicode: "U+1F1EE U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
  },
  {
    name: "Israel",
    code: "IL",
    emoji: "🇮🇱",
    unicode: "U+1F1EE U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
  },
  {
    name: "Man (Isle of)",
    code: "IM",
    emoji: "🇮🇲",
    unicode: "U+1F1EE U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
  },
  {
    name: "India",
    code: "IN",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    emoji: "🇮🇴",
    unicode: "U+1F1EE U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
  },
  {
    name: "Iraq",
    code: "IQ",
    emoji: "🇮🇶",
    unicode: "U+1F1EE U+1F1F6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
  },
  {
    name: "Iran",
    code: "IR",
    emoji: "🇮🇷",
    unicode: "U+1F1EE U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
  },
  {
    name: "Iceland",
    code: "IS",
    emoji: "🇮🇸",
    unicode: "U+1F1EE U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
  },
  {
    name: "Italy",
    code: "IT",
    emoji: "🇮🇹",
    unicode: "U+1F1EE U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
  },
  {
    name: "Jersey",
    code: "JE",
    emoji: "🇯🇪",
    unicode: "U+1F1EF U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
  },
  {
    name: "Jamaica",
    code: "JM",
    emoji: "🇯🇲",
    unicode: "U+1F1EF U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
  },
  {
    name: "Jordan",
    code: "JO",
    emoji: "🇯🇴",
    unicode: "U+1F1EF U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
  },
  {
    name: "Japan",
    code: "JP",
    emoji: "🇯🇵",
    unicode: "U+1F1EF U+1F1F5",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
  },
  {
    name: "Kenya",
    code: "KE",
    emoji: "🇰🇪",
    unicode: "U+1F1F0 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    emoji: "🇰🇬",
    unicode: "U+1F1F0 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
  },
  {
    name: "Cambodia",
    code: "KH",
    emoji: "🇰🇭",
    unicode: "U+1F1F0 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
  },
  {
    name: "Kiribati",
    code: "KI",
    emoji: "🇰🇮",
    unicode: "U+1F1F0 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
  },
  {
    name: "Comoros",
    code: "KM",
    emoji: "🇰🇲",
    unicode: "U+1F1F0 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
  },
  {
    name: "Saint Kitts And Nevis",
    code: "KN",
    emoji: "🇰🇳",
    unicode: "U+1F1F0 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
  },
  {
    name: "North Korea",
    code: "KP",
    emoji: "🇰🇵",
    unicode: "U+1F1F0 U+1F1F5",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
  },
  {
    name: "South Korea",
    code: "KR",
    emoji: "🇰🇷",
    unicode: "U+1F1F0 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
  },
  {
    name: "Kuwait",
    code: "KW",
    emoji: "🇰🇼",
    unicode: "U+1F1F0 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    emoji: "🇰🇾",
    unicode: "U+1F1F0 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    emoji: "🇰🇿",
    unicode: "U+1F1F0 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
  },
  {
    name: "Laos",
    code: "LA",
    emoji: "🇱🇦",
    unicode: "U+1F1F1 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
  },
  {
    name: "Lebanon",
    code: "LB",
    emoji: "🇱🇧",
    unicode: "U+1F1F1 U+1F1E7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    emoji: "🇱🇨",
    unicode: "U+1F1F1 U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    emoji: "🇱🇮",
    unicode: "U+1F1F1 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    emoji: "🇱🇰",
    unicode: "U+1F1F1 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
  },
  {
    name: "Liberia",
    code: "LR",
    emoji: "🇱🇷",
    unicode: "U+1F1F1 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
  },
  {
    name: "Lesotho",
    code: "LS",
    emoji: "🇱🇸",
    unicode: "U+1F1F1 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
  },
  {
    name: "Lithuania",
    code: "LT",
    emoji: "🇱🇹",
    unicode: "U+1F1F1 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
  },
  {
    name: "Luxembourg",
    code: "LU",
    emoji: "🇱🇺",
    unicode: "U+1F1F1 U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
  },
  {
    name: "Latvia",
    code: "LV",
    emoji: "🇱🇻",
    unicode: "U+1F1F1 U+1F1FB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
  },
  {
    name: "Libya",
    code: "LY",
    emoji: "🇱🇾",
    unicode: "U+1F1F1 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
  },
  {
    name: "Morocco",
    code: "MA",
    emoji: "🇲🇦",
    unicode: "U+1F1F2 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
  },
  {
    name: "Monaco",
    code: "MC",
    emoji: "🇲🇨",
    unicode: "U+1F1F2 U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
  },
  {
    name: "Moldova",
    code: "MD",
    emoji: "🇲🇩",
    unicode: "U+1F1F2 U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
  },
  {
    name: "Montenegro",
    code: "ME",
    emoji: "🇲🇪",
    unicode: "U+1F1F2 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
  },
  {
    name: "Saint-Martin (French part)",
    code: "MF",
    emoji: "🇲🇫",
    unicode: "U+1F1F2 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
  },
  {
    name: "Madagascar",
    code: "MG",
    emoji: "🇲🇬",
    unicode: "U+1F1F2 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    emoji: "🇲🇭",
    unicode: "U+1F1F2 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
  },
  {
    name: "Macedonia",
    code: "MK",
    emoji: "🇲🇰",
    unicode: "U+1F1F2 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
  },
  {
    name: "Mali",
    code: "ML",
    emoji: "🇲🇱",
    unicode: "U+1F1F2 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
  },
  {
    name: "Myanmar",
    code: "MM",
    emoji: "🇲🇲",
    unicode: "U+1F1F2 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
  },
  {
    name: "Mongolia",
    code: "MN",
    emoji: "🇲🇳",
    unicode: "U+1F1F2 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
  },
  {
    name: "Macau S.A.R.",
    code: "MO",
    emoji: "🇲🇴",
    unicode: "U+1F1F2 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    emoji: "🇲🇵",
    unicode: "U+1F1F2 U+1F1F5",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
  },
  {
    name: "Martinique",
    code: "MQ",
    emoji: "🇲🇶",
    unicode: "U+1F1F2 U+1F1F6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg",
  },
  {
    name: "Mauritania",
    code: "MR",
    emoji: "🇲🇷",
    unicode: "U+1F1F2 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
  },
  {
    name: "Montserrat",
    code: "MS",
    emoji: "🇲🇸",
    unicode: "U+1F1F2 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
  },
  {
    name: "Malta",
    code: "MT",
    emoji: "🇲🇹",
    unicode: "U+1F1F2 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
  },
  {
    name: "Mauritius",
    code: "MU",
    emoji: "🇲🇺",
    unicode: "U+1F1F2 U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
  },
  {
    name: "Maldives",
    code: "MV",
    emoji: "🇲🇻",
    unicode: "U+1F1F2 U+1F1FB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
  },
  {
    name: "Malawi",
    code: "MW",
    emoji: "🇲🇼",
    unicode: "U+1F1F2 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
  },
  {
    name: "Mexico",
    code: "MX",
    emoji: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
  },
  {
    name: "Malaysia",
    code: "MY",
    emoji: "🇲🇾",
    unicode: "U+1F1F2 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
  },
  {
    name: "Mozambique",
    code: "MZ",
    emoji: "🇲🇿",
    unicode: "U+1F1F2 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
  },
  {
    name: "Namibia",
    code: "NA",
    emoji: "🇳🇦",
    unicode: "U+1F1F3 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
  },
  {
    name: "New Caledonia",
    code: "NC",
    emoji: "🇳🇨",
    unicode: "U+1F1F3 U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
  },
  {
    name: "Niger",
    code: "NE",
    emoji: "🇳🇪",
    unicode: "U+1F1F3 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    emoji: "🇳🇫",
    unicode: "U+1F1F3 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg",
  },
  {
    name: "Nigeria",
    code: "NG",
    emoji: "🇳🇬",
    unicode: "U+1F1F3 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
  },
  {
    name: "Nicaragua",
    code: "NI",
    emoji: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "NL",
    emoji: "🇳🇱",
    unicode: "U+1F1F3 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
  },
  {
    name: "Norway",
    code: "NO",
    emoji: "🇳🇴",
    unicode: "U+1F1F3 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
  },
  {
    name: "Nepal",
    code: "NP",
    emoji: "🇳🇵",
    unicode: "U+1F1F3 U+1F1F5",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
  },
  {
    name: "Nauru",
    code: "NR",
    emoji: "🇳🇷",
    unicode: "U+1F1F3 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
  },
  {
    name: "Niue",
    code: "NU",
    emoji: "🇳🇺",
    unicode: "U+1F1F3 U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
  },
  {
    name: "New Zealand",
    code: "NZ",
    emoji: "🇳🇿",
    unicode: "U+1F1F3 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
  },
  {
    name: "Oman",
    code: "OM",
    emoji: "🇴🇲",
    unicode: "U+1F1F4 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
  },
  {
    name: "Panama",
    code: "PA",
    emoji: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
  },
  {
    name: "Peru",
    code: "PE",
    emoji: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
  },
  {
    name: "French Polynesia",
    code: "PF",
    emoji: "🇵🇫",
    unicode: "U+1F1F5 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
  },
  {
    name: "Papua new Guinea",
    code: "PG",
    emoji: "🇵🇬",
    unicode: "U+1F1F5 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
  },
  {
    name: "Philippines",
    code: "PH",
    emoji: "🇵🇭",
    unicode: "U+1F1F5 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
  },
  {
    name: "Pakistan",
    code: "PK",
    emoji: "🇵🇰",
    unicode: "U+1F1F5 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
  },
  {
    name: "Poland",
    code: "PL",
    emoji: "🇵🇱",
    unicode: "U+1F1F5 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    emoji: "🇵🇲",
    unicode: "U+1F1F5 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
  },
  {
    name: "Pitcairn Island",
    code: "PN",
    emoji: "🇵🇳",
    unicode: "U+1F1F5 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    emoji: "🇵🇷",
    unicode: "U+1F1F5 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
  },
  {
    name: "Palestinian Territory Occupied",
    code: "PS",
    emoji: "🇵🇸",
    unicode: "U+1F1F5 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
  },
  {
    name: "Portugal",
    code: "PT",
    emoji: "🇵🇹",
    unicode: "U+1F1F5 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
  },
  {
    name: "Palau",
    code: "PW",
    emoji: "🇵🇼",
    unicode: "U+1F1F5 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
  },
  {
    name: "Paraguay",
    code: "PY",
    emoji: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
  },
  {
    name: "Qatar",
    code: "QA",
    emoji: "🇶🇦",
    unicode: "U+1F1F6 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
  },
  {
    name: "Reunion",
    code: "RE",
    emoji: "🇷🇪",
    unicode: "U+1F1F7 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
  },
  {
    name: "Romania",
    code: "RO",
    emoji: "🇷🇴",
    unicode: "U+1F1F7 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
  },
  {
    name: "Serbia",
    code: "RS",
    emoji: "🇷🇸",
    unicode: "U+1F1F7 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
  },
  {
    name: "Russia",
    code: "RU",
    emoji: "🇷🇺",
    unicode: "U+1F1F7 U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
  },
  {
    name: "Rwanda",
    code: "RW",
    emoji: "🇷🇼",
    unicode: "U+1F1F7 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    emoji: "🇸🇦",
    unicode: "U+1F1F8 U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    emoji: "🇸🇧",
    unicode: "U+1F1F8 U+1F1E7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
  },
  {
    name: "Seychelles",
    code: "SC",
    emoji: "🇸🇨",
    unicode: "U+1F1F8 U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
  },
  {
    name: "Sudan",
    code: "SD",
    emoji: "🇸🇩",
    unicode: "U+1F1F8 U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
  },
  {
    name: "Sweden",
    code: "SE",
    emoji: "🇸🇪",
    unicode: "U+1F1F8 U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
  },
  {
    name: "Singapore",
    code: "SG",
    emoji: "🇸🇬",
    unicode: "U+1F1F8 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
  },
  {
    name: "Saint Helena",
    code: "SH",
    emoji: "🇸🇭",
    unicode: "U+1F1F8 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg",
  },
  {
    name: "Slovenia",
    code: "SI",
    emoji: "🇸🇮",
    unicode: "U+1F1F8 U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
  },
  {
    name: "Svalbard And Jan Mayen Islands",
    code: "SJ",
    emoji: "🇸🇯",
    unicode: "U+1F1F8 U+1F1EF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg",
  },
  {
    name: "Slovakia",
    code: "SK",
    emoji: "🇸🇰",
    unicode: "U+1F1F8 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    emoji: "🇸🇱",
    unicode: "U+1F1F8 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
  },
  {
    name: "San Marino",
    code: "SM",
    emoji: "🇸🇲",
    unicode: "U+1F1F8 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
  },
  {
    name: "Senegal",
    code: "SN",
    emoji: "🇸🇳",
    unicode: "U+1F1F8 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
  },
  {
    name: "Somalia",
    code: "SO",
    emoji: "🇸🇴",
    unicode: "U+1F1F8 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
  },
  {
    name: "Suriname",
    code: "SR",
    emoji: "🇸🇷",
    unicode: "U+1F1F8 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
  },
  {
    name: "South Sudan",
    code: "SS",
    emoji: "🇸🇸",
    unicode: "U+1F1F8 U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    emoji: "🇸🇹",
    unicode: "U+1F1F8 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
  },
  {
    name: "El Salvador",
    code: "SV",
    emoji: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SX",
    emoji: "🇸🇽",
    unicode: "U+1F1F8 U+1F1FD",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
  },
  {
    name: "Syria",
    code: "SY",
    emoji: "🇸🇾",
    unicode: "U+1F1F8 U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
  },
  {
    name: "Swaziland",
    code: "SZ",
    emoji: "🇸🇿",
    unicode: "U+1F1F8 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
  },
  // {
  //   name: "Tristan da Cunha",
  //   code: "TA",
  //   emoji: "🇹🇦",
  //   unicode: "U+1F1F9 U+1F1E6",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg",
  // },
  {
    name: "Turks And Caicos Islands",
    code: "TC",
    emoji: "🇹🇨",
    unicode: "U+1F1F9 U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
  },
  {
    name: "Chad",
    code: "TD",
    emoji: "🇹🇩",
    unicode: "U+1F1F9 U+1F1E9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
  },
  {
    name: "French Southern Territories",
    code: "TF",
    emoji: "🇹🇫",
    unicode: "U+1F1F9 U+1F1EB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg",
  },
  {
    name: "Togo",
    code: "TG",
    emoji: "🇹🇬",
    unicode: "U+1F1F9 U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
  },
  {
    name: "Thailand",
    code: "TH",
    emoji: "🇹🇭",
    unicode: "U+1F1F9 U+1F1ED",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    emoji: "🇹🇯",
    unicode: "U+1F1F9 U+1F1EF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
  },
  {
    name: "Tokelau",
    code: "TK",
    emoji: "🇹🇰",
    unicode: "U+1F1F9 U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
  },
  {
    name: "East Timor",
    code: "TL",
    emoji: "🇹🇱",
    unicode: "U+1F1F9 U+1F1F1",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    emoji: "🇹🇲",
    unicode: "U+1F1F9 U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
  },
  {
    name: "Tunisia",
    code: "TN",
    emoji: "🇹🇳",
    unicode: "U+1F1F9 U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
  },
  {
    name: "Tonga",
    code: "TO",
    emoji: "🇹🇴",
    unicode: "U+1F1F9 U+1F1F4",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
  },
  {
    name: "Turkey",
    code: "TR",
    emoji: "🇹🇷",
    unicode: "U+1F1F9 U+1F1F7",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
  },
  {
    name: "Trinidad And Tobago",
    code: "TT",
    emoji: "🇹🇹",
    unicode: "U+1F1F9 U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
  },
  {
    name: "Tuvalu",
    code: "TV",
    emoji: "🇹🇻",
    unicode: "U+1F1F9 U+1F1FB",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
  },
  {
    name: "Taiwan",
    code: "TW",
    emoji: "🇹🇼",
    unicode: "U+1F1F9 U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
  },
  {
    name: "Tanzania",
    code: "TZ",
    emoji: "🇹🇿",
    unicode: "U+1F1F9 U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
  },
  {
    name: "Ukraine",
    code: "UA",
    emoji: "🇺🇦",
    unicode: "U+1F1FA U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
  },
  {
    name: "Uganda",
    code: "UG",
    emoji: "🇺🇬",
    unicode: "U+1F1FA U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    emoji: "🇺🇲",
    unicode: "U+1F1FA U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg",
  },
  // {
  //   name: "United Nations",
  //   code: "UN",
  //   emoji: "🇺🇳",
  //   unicode: "U+1F1FA U+1F1F3",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg",
  // },
  {
    name: "United States",
    code: "US",
    emoji: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
  },
  {
    name: "Uruguay",
    code: "UY",
    emoji: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    emoji: "🇺🇿",
    unicode: "U+1F1FA U+1F1FF",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
  },
  {
    name: "Vatican City State (Holy See)",
    code: "VA",
    emoji: "🇻🇦",
    unicode: "U+1F1FB U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
  },
  {
    name: "Saint Vincent And The Grenadines",
    code: "VC",
    emoji: "🇻🇨",
    unicode: "U+1F1FB U+1F1E8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
  },
  {
    name: "Venezuela",
    code: "VE",
    emoji: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
  },
  {
    name: "Virgin Islands (British)",
    code: "VG",
    emoji: "🇻🇬",
    unicode: "U+1F1FB U+1F1EC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
  },
  {
    name: "Virgin Islands (US)",
    code: "VI",
    emoji: "🇻🇮",
    unicode: "U+1F1FB U+1F1EE",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
  },
  {
    name: "Vietnam",
    code: "VN",
    emoji: "🇻🇳",
    unicode: "U+1F1FB U+1F1F3",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
  },
  {
    name: "Vanuatu",
    code: "VU",
    emoji: "🇻🇺",
    unicode: "U+1F1FB U+1F1FA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
  },
  {
    name: "Samoa",
    code: "WS",
    emoji: "🇼🇸",
    unicode: "U+1F1FC U+1F1F8",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
  },
  {
    name: "Kosovo",
    code: "XK",
    emoji: "🇽🇰",
    unicode: "U+1F1FD U+1F1F0",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
  },
  {
    name: "Yemen",
    code: "YE",
    emoji: "🇾🇪",
    unicode: "U+1F1FE U+1F1EA",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
  },
  {
    name: "Mayotte",
    code: "YT",
    emoji: "🇾🇹",
    unicode: "U+1F1FE U+1F1F9",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
  },
  {
    name: "South Africa",
    code: "ZA",
    emoji: "🇿🇦",
    unicode: "U+1F1FF U+1F1E6",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
  },
  {
    name: "Zambia",
    code: "ZM",
    emoji: "🇿🇲",
    unicode: "U+1F1FF U+1F1F2",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    emoji: "🇿🇼",
    unicode: "U+1F1FF U+1F1FC",
    image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
  },
  // {
  //   name: "England",
  //   code: "ENGLAND",
  //   emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
  //   unicode: "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg",
  // },
  // {
  //   name: "Scotland",
  //   code: "SCOTLAND",
  //   emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
  //   unicode: "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
  //   image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg",
  // },
];

export const cbuTableData = {
  headers: [
    {
      Header: "Account Id",
      accessor: "accountId",
    },
    {
      Header: "Legal Name",
      accessor: "legalEntityName",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
    },
    {
      Header: "End Date",
      accessor: "endDate",
    },
  ],
  data: [
    {
      accountId: "123489991098",
      legalEntityName: "Tata Sky Pvt. Ltd.",
      startDate: "01 Dec, 2021",
      endDate: "03 Dec, 2022",
    },
    {
      accountId: "123489991098",
      legalEntityName: "App Learn AWS - Disaster",
      startDate: "12 Dec, 2021",
      endDate: "12 Dec, 2022",
    },
    {
      accountId: "123489991098",
      legalEntityName: "Bajaj Capital - All",
      startDate: "30 Nov, 2021",
      endDate: "31 Nov, 2022",
    },
  ],
};

export const supportTypeEnums = {
  AS_IS: "AS-IS",
  ENTERPRISE: "Enterprise Support",
  DEVELOPER: "Developer Support",
  BUSINESS: "Business Support",
  CALCULATE: "Calculate Support",
};

const restructureData = (handleChange, apiResponse, isPartnerRO = false) => {
  const checkedRecord = apiResponse.filter((item) => item.checked);
  const selectedItem = `${checkedRecord?.[0]?.legalEntityNameClone}-${checkedRecord?.[0]?.billingAlias}`;
  apiResponse.forEach((item) => {
    const itemClone = item;
    const currentItem = `${item?.legalEntityNameClone}-${item?.billingAlias}`;
    itemClone.accountIdClone = (
      <div className="text-field">
        {(!item.endDate && !isPartnerRO && (
          <Checkbox
            name="name"
            checked={item.checked}
            onChange={(event) => handleChange(event, "selectRow", item)}
            disabled={checkedRecord?.length && selectedItem !== currentItem}
          />
        )) || <span className="support-empty-checkbox" />}
        <span>{item.accountId}</span>
      </div>
    );

    itemClone.lastUpdateByClone = (
      <span className={`${item.lastUpdateBy === "System Generated" ? "last-update-by" : ""}`}>
        {itemClone.lastUpdateBy || <span className="text-center">- - -</span>}
      </span>
    );
  });
};
const restructureCPPData = (
  handleChange,
  apiResponse,
  hasTooltip /* isLast = true */
  // isPartnerRO = false
) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.name = (
      <div className="text-field cppTextField">
        {/* {(!isPartnerRO && ( */}
        <Checkbox
          name="name"
          checked={item?.checked}
          disabled={
            item?.state !== "Active" ||
            (apiResponseClone?.filter((singleObj) => singleObj?.checked)?.length && !item?.checked)
          }
          className={item?.state !== "Active" && "disabledCheckBox"}
          onChange={(event) => handleChange(event, "selectRow", item)}
        />
        {/* )) || <span className="support-empty-checkbox" />} */}
        <span className={`${item?.state !== "Active" && "text-[#525252]"} `}>{item?.name}</span>
        {hasTooltip && (
          <span className={`cppinfoTooltip ${item?.state !== "Active" && "!text-[#525252]"}`}>
            <InfoIcon /> <div className="hoverDiv">{item?.description}</div>
          </span>
        )}
      </div>
    );
    itemClone.cbu = (
      <span className={`${item?.state !== "Active" ? "text-[#525252]" : ""} `}>{item?.cbu}</span>
    );
    itemClone.startDate = (
      <span className={`${item?.state !== "Active" && "text-[#525252]"} `}>{item?.startDate}</span>
    );

    itemClone.duration = (
      <span
        className={`py-[3px] px-[6px] rounded whitespace-nowrap	 ${
          item?.state !== "Active" ? "text-[#C3C3C3] bg-[#525252]" : "text-white	bg-[#0A3CA2]"
        }`}
      >
        {item?.duration}
      </span>
    );
    itemClone.commitment = (
      <span className={`${item?.state !== "Active" && "text-[#525252]"} `}>{item?.commitment}</span>
    );
    itemClone.endDate = item?.endDate ? (
      <span className={`${item?.state !== "Active" && "text-[#525252]"} `}>{item?.endDate}</span>
    ) : null;
  });
};

const getActiveClass = (currentIndex, activeIndex) => {
  const getActiveIndex = +activeIndex;
  if (currentIndex === getActiveIndex) {
    return "text-[#0A3CA2] !font-medium !bg-[#F0F6FF]";
  }
  if (currentIndex > getActiveIndex) {
    return "text-[#898A90] font-normal !bg-[#FFFFFF]";
  }
  if (currentIndex < getActiveIndex) {
    return "text-[#000000] font-normal !bg-[#FFFFFF]";
  }
  return null;
};

export const renderEdpDescriptionTooltip = (description) => (
  <div className="overflow-y-auto">
    <div>{description || "No Description Available"}</div>
  </div>
);

const termLevelSummaryData = (year) => (
  <span className="termLevelSummaryYear">
    {year || "- - -"}
    <sup>{getRenderYear(year)}</sup>
  </span>
);
const getActiveClassForProgress = (currentIndex, activeIndex) => {
  const getActiveIndex = +activeIndex;
  if (currentIndex === getActiveIndex) {
    return "green";
  }
  if (currentIndex > getActiveIndex) {
    return "disabled";
  }
  if (currentIndex < getActiveIndex) {
    return "grey";
  }
  return null;
};

const renderData = (row, item, index, activeIndex) => {
  if (item.key === "progress") {
    return (
      <LinearProgressWithLabel
        value={Math.random() * 100}
        progressClass={`w-[50px] ${getActiveClassForProgress(index + 1, activeIndex)}`}
      />
    );
  }
  if (item.key === "term") {
    const updateIndex = index + 1;
    // const year = updateIndex > 1 ? " Years" : " Year";
    // return updateIndex + year;
    return (
      <span>
        {updateIndex}
        <sup>{getRenderYear(updateIndex)}</sup> Year
      </span>
    );
  }
  return row[item.key] || "- - -";
};
const renderTermLevelSummary = (tableDataConfig, activeTenure) => (
  <table className="TermLevelSummary_tooltip_style">
    <thead className="TermLevelSummary_tooltip_tableHead">
      <tr className="TermLevelSummary_tooltip_tableRow">
        {tableDataConfig?.headers?.map((item) => (
          <td className="TermLevelSummary_tooltip_tableData">{item.label}</td>
        ))}
      </tr>
    </thead>
    <tbody>
      {tableDataConfig?.data?.map((columnItem, columnIndex) => (
        <tr className="">
          {tableDataConfig?.headers?.map((rowItem) => (
            <td
              className={`TermLevelSummary_tooltip_tableRow ${getActiveClass(
                columnIndex + 1,
                activeTenure
              )}`}
            >
              {renderData(columnItem, rowItem, columnIndex, activeTenure)}
              {/* {columnItem[rowItem.key]} */}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export const restructureRDTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item.state === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRowByUUID", item)}
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
    if (itemClone?.endDate) {
      itemClone.endDate = moment(item?.endDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.startDate) {
      itemClone.startDate = moment(item?.startDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.createdAt) {
      itemClone.createdAt = moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.lastUpdatedAt) {
      itemClone.lastUpdatedAt = moment(item?.lastUpdatedAt).format("DD-MMM-YYYY, HH:mm");
    }
  });
};

export const restructurePPATableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              item.status === "Nullified" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={
              (item.status === "Expired" || item.status === "Nullified") && "disabledCheckBox"
            }
            onChange={(event) => handleChange(event, "selectRow", item)}
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
  });
};

export const restructureVRTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              item.status === "Nullified" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item.state === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRow", item)}
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
    if (itemClone?.endDate) {
      itemClone.endDate = item?.endDate;
    }
    if (itemClone?.startDate) {
      itemClone.startDate = item?.startDate;
    }
    if (itemClone?.autoRenewLastChangedAt) {
      itemClone.autoRenewLastChangedAt = moment(item?.autoRenewLastChangedAt).format(
        "DD-MMM-YYYY, HH:mm"
      );
    }
    if (itemClone?.lastChangedAt) {
      itemClone.lastChangedAt = item?.lastChangedAt;
    }
    if (itemClone?.createdAt) {
      itemClone.createdAt = item?.createdAt;
    }
    // itemClone.autoRenew = itemClone?.autoRenew ? "Yes" : "No";
  });
};

export const EDP_TOOLTIP_DATA_HEADER = [
  {
    label: "Tenure",
    key: "term",
  },
  {
    label: "Commitments($)",
    key: "commitment",
  },
  {
    label: "Discount(%)",
    key: "discount",
  },
  {
    label: "Credit($)",
    key: "credits",
  },
  // {
  //   label: "Year Wise Utilization",
  //   key: "progress",
  // },
];

export const FLAT_DISCOUNT_HEADER = (handlePreview = noop) => [
  {
    Header: "",
    accessor: "checkboxId",
    bodyClass: "whitespace-nowrap normal-case !sticky left-0 z-10 bg-white",
    headerClass: "whitespace-nowrap !sticky left-0 !z-20 top-0",
    props: {
      doNotAddColumnSelector: true,
      hideOnRO: true,
    },
  },
  {
    Header: "CBU Name",
    accessor: "cbuName",
    sortedIcon: true,
    bodyClass:
      "whitespace-nowrap normal-case !sticky left-[30px] z-9999 bg-white !px-[8px] !text-left shadowOnStickyColumn",
    headerClass: "whitespace-nowrap !sticky left-[30px] !z-10 !text-left shadowOnStickyColumn",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },

  {
    Header: "Discount Name",
    accessor: "name",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    component: FlatDiscountTooltip,
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Template Name",
    accessor: "templateName",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
    render: (item) =>
      item?.templateName && (
        <div className="flex">
          {item?.templateName}
          <EyeActiveIcon className="ml-[10px] cursor-pointer" onClick={() => handlePreview(item)} />
        </div>
      ),
  },
  {
    Header: "Discount (%)",
    accessor: "discount",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },

  {
    Header: "State",
    accessor: "state",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },

  {
    Header: "Start Date",
    accessor: "startDate",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "End Date",
    accessor: "endDate",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },

  {
    Header: "Created At",
    accessor: "createdAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Last Updated At",
    accessor: "lastUpdatedAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Last Updated By",
    accessor: "lastUpdatedBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
];

// eslint-disable-next-line max-lines-per-function
export const CHARGES_AND_REFUND_HEADER = (handlePreview = noop) => [
  {
    Header: "",
    accessor: "checkboxId",
    bodyClass: "whitespace-nowrap normal-case !sticky left-0 z-10 bg-white",
    headerClass: "whitespace-nowrap !sticky left-0 !z-20 top-0",
    props: {
      doNotAddColumnSelector: true,
      hideOnRO: true,
    },
  },
  {
    Header: "Billdesk Name",
    accessor: "billdeskName",
    sortedIcon: true,
    bodyClass:
      "whitespace-nowrap normal-case !sticky left-[34px] z-9999 bg-white !px-[8px] !text-left",
    headerClass: "whitespace-nowrap !sticky left-[34px] !z-10 !text-left",
    render: (item) => (
      <TooltipV2
        parentClass="Edp_Tooltip_Style_V2"
        tooltipClass="!justify-start"
        displayText={() => <div className="Edp_TooltipV2_Header">{item?.billdeskName}</div>}
      >
        {renderEdpDescriptionTooltip(item?.billdeskDescription)}
      </TooltipV2>
    ),
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
  },

  {
    Header: "Display Name",
    accessor: "displayName",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    render: (item) => (
      <TooltipV2
        parentClass="Edp_Tooltip_Style_V2"
        displayText={() => <div className="Edp_TooltipV2_Header">{item?.displayName}</div>}
      >
        {renderEdpDescriptionTooltip(item?.displayDescription)}
      </TooltipV2>
    ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Type",
    accessor: "chargeAndRefundType",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Account ID/CBU(s)",
    accessor: "accountOrCbu",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },

  {
    Header: "Frequency",
    accessor: "frequency",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },

  {
    Header: "Calculation Type",
    accessor: "calculationType",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },

  {
    Header: "Template",
    accessor: "discountTemplateName",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    render: (item) =>
      item?.discountTemplateName && (
        <div className="flex">
          {item?.discountTemplateName}
          <EyeActiveIcon className="ml-[10px] cursor-pointer" onClick={() => handlePreview(item)} />
        </div>
      ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "End Date",
    accessor: "endDate",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Last Updated At",
    accessor: "lastUpdatedAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Last Updated By",
    accessor: "lastUpdatedBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
  },
];

// eslint-disable-next-line max-lines-per-function
export const EDP_DISCOUNT_HEADER = (handlePreview = noop) => [
  {
    Header: "",
    accessor: "checkboxId",
    bodyClass: "whitespace-nowrap normal-case !sticky left-0 z-10 bg-white",
    headerClass: "whitespace-nowrap !sticky left-0 !z-20 top-0",
    props: {
      doNotAddColumnSelector: true,
      disabledMenuItem: true,
      hideOnRO: true,
    },
  },
  {
    Header: "Discount Name",
    accessor: "name",
    sortedIcon: true,
    bodyClass:
      "whitespace-nowrap normal-case !sticky left-[30px] bg-white !px-[8px] !text-left discount_name_V2 shadowOnStickyColumn",
    headerClass: "whitespace-nowrap !sticky left-[30px] !z-10  !text-left shadowOnStickyColumn",
    render: (item) => (
      <TooltipV2
        parentClass="Edp_Tooltip_Style_V2"
        displayText={() => (
          <div className="Edp_TooltipV2_Header">
            {item?.name ? item?.name : "No Description Available"}
          </div>
        )}
      >
        {renderEdpDescriptionTooltip(item?.description)}
      </TooltipV2>
    ),
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Template",
    accessor: "discountTemplateName",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case Template template_name_V2",
    headerClass: "whitespace-nowrap",
    render: (item) =>
      item?.discountTemplateName && (
        <div className="flex">
          {item?.discountTemplateName}
          <EyeActiveIcon className="ml-[10px] cursor-pointer" onClick={() => handlePreview(item)} />
        </div>
      ),
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Status",
    accessor: "status",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Tenure",
    accessor: "term",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Term Level Summary",
    accessor: "termLevelSummary",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case summary_active",
    headerClass: "whitespace-nowrap",
    render: (item) => (
      <TooltipV2
        parentClass="Edp_Tooltip_term_level_summary_V2"
        displayText={() => (
          <div className="Edp_TooltipV2_Header">
            {item.termLevelSummary > 0
              ? termLevelSummaryData(item.termLevelSummary, item.status)
              : "- - -"}
            {item.status === "Active" && <span className="Active_Chip_Edp">{item.status}</span>}
          </div>
        )}
      >
        {renderTermLevelSummary(
          {
            data: item.edpCommitmentDTOList,
            headers: EDP_TOOLTIP_DATA_HEADER,
          },
          item.termLevelSummary
        )}
      </TooltipV2>
    ),
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "number",
  },
  {
    Header: "Chargeback Type",
    accessor: "chargeBack",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Chargeback Account ID",
    accessor: "chargeBackAccountId",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "End Date",
    accessor: "endDate",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      disabledMenuItem: true,
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case",
    headerClass: "whitespace-nowrap",
    props: {
      defaultSelectedHeader: true,
    },
    filterType: "string",
  },
];

export const restructureFlatTableTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item.state === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRowByUUID", item)}
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
    if (itemClone?.endDate) {
      itemClone.endDate = moment(item?.endDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.startDate) {
      itemClone.startDate = moment(item?.startDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.lastChangedAt) {
      itemClone.lastChangedAt = moment(item?.lastChangedAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.createdAt) {
      itemClone.createdAt = moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.lastUpdatedAt) {
      const lastUpdateDate = new Date(itemClone?.lastUpdatedAt);
      itemClone.lastUpdatedAt = moment(lastUpdateDate).format("DD-MMM-YYYY, HH:mm");
    }
  });
};

export const restructureChargesAndRefundsTableTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item.status === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRowByUUID", item)}
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
    if (itemClone?.endDate) {
      itemClone.endDate = moment(item?.endDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.startDate) {
      itemClone.startDate = moment(item?.startDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.lastChangedAt) {
      itemClone.lastChangedAt = moment(item?.lastChangedAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.createdAt) {
      itemClone.createdAt = moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.lastUpdatedAt) {
      const lastUpdateDate = new Date(itemClone?.lastUpdatedAt);
      itemClone.lastUpdatedAt = moment(lastUpdateDate).format("DD-MMM-YYYY, HH:mm");
    }
  });
};

const generateCheckbox = (name, checked, disabled, className, onChange) => (
  <div className="text-field cppTextField">
    {(
      <Checkbox
        name={name}
        checked={checked}
        disabled={disabled}
        className={className}
        onChange={onChange}
      />
    ) || <span className="support-empty-checkbox" />}
  </div>
);

const isDisable = (item) =>
  item.status === "Expired" ||
  !!item?.endDate ||
  item.childAccounts.every(
    (childAccount) => childAccount.status !== "Active" || !!childAccount?.endDate
  );

export const restructureSupportConfigTableData = (handleChange, apiResponse) => {
  // const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = generateCheckbox(
      "name",
      item.checked,
      isDisable(item),
      item.status === "Expired" && "disabledCheckBox",
      (event) => handleChange(event, "selectRow", { ...item }, null, true)
    );

    if (itemClone?.endDate) {
      itemClone.endDate = moment(item?.endDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.startDate) {
      itemClone.startDate = moment(item?.startDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.lastUpdatedAt) {
      const lastUpdateDate = new Date(itemClone?.lastUpdatedAt);
      itemClone.lastUpdatedAt = moment(lastUpdateDate).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.childAccounts?.length) {
      itemClone.childAccounts.forEach((config) => {
        const childAccountClone = config;
        childAccountClone.checkboxId = generateCheckbox(
          "name",
          config.checked,
          config.status !== "Active" || !!childAccountClone?.endDate,
          config.status === "Expired" && "disabledCheckBox",
          (event) => handleChange(event, "selectRow", { ...config }, item?.id, true)
        );
      });
    }
  });
};

export const restructureEdpTableTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item.state === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRow", item)}
            disableRipple
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
    if (itemClone?.endDate) {
      itemClone.endDate = moment(item?.endDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.startDate) {
      itemClone.startDate = moment(item?.startDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.lastChangedAt) {
      itemClone.lastChangedAt = moment(item?.lastChangedAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.createdAt) {
      itemClone.createdAt = moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.lastUpdatedAt) {
      const lastUpdateDate = new Date(itemClone?.lastUpdatedAt);
      itemClone.lastUpdatedAt = moment(lastUpdateDate).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.tenure) {
      const year = itemClone.tenure > 1 ? " Years" : " Year";
      itemClone.tenure += year;
    }
  });
};

export const restructureCreditTableData = (handleChange, apiResponse) => {
  const apiResponseClone = cloneDeep(apiResponse);
  apiResponse?.forEach((item) => {
    const itemClone = item;
    itemClone.checkboxId = (
      <div className="text-field cppTextField">
        {(
          <Checkbox
            name="name"
            checked={item.checked}
            disabled={
              item.status === "Expired" ||
              item.status === "Nullified" ||
              (apiResponseClone.filter((singleObj) => singleObj?.checked).length && !item.checked)
            }
            className={item.state === "Expired" && "disabledCheckBox"}
            onChange={(event) => handleChange(event, "selectRow", item)}
            disableRipple
          />
        ) || <span className="support-empty-checkbox" />}
      </div>
    );
    if (itemClone?.endDate) {
      itemClone.endDate = moment(item?.endDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.startDate) {
      itemClone.startDate = moment(item?.startDate).format("DD-MMM-YYYY");
    }
    if (itemClone?.lastChangedAt) {
      itemClone.lastChangedAt = moment(item?.lastChangedAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.createdAt) {
      itemClone.createdAt = moment(item?.createdAt).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.lastUpdatedAt) {
      const lastUpdateDate = new Date(itemClone?.lastUpdatedAt);
      itemClone.lastUpdatedAt = moment(lastUpdateDate).format("DD-MMM-YYYY, HH:mm");
    }
    if (itemClone?.tenure) {
      const year = itemClone.tenure > 1 ? " Years" : " Year";
      itemClone.tenure += year;
    }
  });
};

export const getLegalEntityTableData = (handleChange, apiResponse, isPartnerRO) => {
  restructureData(handleChange, apiResponse, isPartnerRO);

  return {
    headers: [
      {
        Header: (
          <div className="text-field flex items-center">
            {/* <Checkbox
              name="name"
              indeterminate={indeterminate}
              checked={checked}
              onChange={(event) => handleChange(event, "selectAll", apiResponse)}
            /> */}
            <span>Account ID</span>
          </div>
        ),
        accessor: "accountIdClone",
      },
      {
        Header: "Billing Alias",
        accessor: "billingAlias",
      },
      {
        Header: "Legal Name",
        accessor: "legalEntityNameClone",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Last Updated By",
        accessor: "lastUpdateByClone",
      },
    ],
    data: apiResponse,
  };
};

export const getCBUTableData = (handleChange, apiResponse, indeterminate, checked, isPartnerRO) => {
  restructureData(handleChange, apiResponse, isPartnerRO);
  return {
    headers: [
      {
        Header: (
          <div className="text-field flex items-center">
            {!isPartnerRO && (
              <Checkbox
                name="name"
                indeterminate={indeterminate}
                checked={checked}
                onChange={(event) => handleChange(event, "selectAll", apiResponse)}
              />
            )}
            <span>Linked Account ID</span>
          </div>
        ),
        accessor: "accountIdClone",
      },
      {
        Header: "CBU Name",
        accessor: "CBUNameClone",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Last Updated By",
        accessor: "lastUpdateByClone",
      },
    ],
    data: apiResponse,
  };
};

export const getCBUCreateTableData = (handleChange, apiResponse) => ({
  headers: [
    {
      Header: "CBU Name",
      accessor: "name",
      bodyClass: "!text-[13px] !font-medium tracking-[.14px]",
    },
    {
      Header: "Linked Account ID's",
      accessor: "accounts",
      component: ListPopup,
      array: true,
      bodyClass: "!text-[13px] !font-medium tracking-[.14px]",
    },
  ],
  data: apiResponse,
});

const populateActions = (apiResponse) => {
  apiResponse.forEach((item) => {
    const clone = item;

    clone.actions = [
      {
        actionType: "edit",
        class: "edit-icon",
      },
    ];
  });
};

export const getLegalEntityCreateTable = (apiResponse) => {
  populateActions(apiResponse);

  return {
    headers: [
      {
        Header: "Legal Name",
        accessor: "name",
        bodyClass: "!text-[13px] !font-medium tracking-[.14px]",
      },
      {
        Header: "Linked Account ID’s",
        accessor: "accounts",
        component: ListPopup,
        array: true,
        bodyClass: "!text-[13px] !font-medium tracking-[.14px]",
      },
      {
        Header: "Actions",
        accessor: "actions",
        icons: true,
      },
    ],
    data: apiResponse,
  };
};

export const getCPPTableData = (handleChange, apiResponse, isPartnerRO) => {
  restructureCPPData(handleChange, apiResponse, true, isPartnerRO);
  return {
    headers: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "CBU",
        accessor: "cbu",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Commitment",
        accessor: "commitment",
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    data: apiResponse,
  };
};

// export const yearlyCommitmentTableDropDownList = [
export const tableInputTooltip = (
  header,
  data,
  onTableInputChange,
  tableIndex,
  sign,
  isEditable = true,
  handleInputChange = noop
) => (
  <Tooltip
    title={isEditable ? "Change To Private Pricing" : ""}
    arrow
    placement="bottom-start"
    TransitionProps={{ timeout: 400 }}
  >
    <div className="block">
      <PpaInput
        name={header.value}
        value={data[header.value]}
        type="text"
        disabled={!isEditable}
        onGetData={(name, value) => onTableInputChange(name, value, tableIndex, data, header)}
        placeholder=""
        errorClass="errorClass"
        regex={/^(?:\d*(?:\.\d{0,4})?)?$/}
        parentClass={`table-radio-input ${data[header.value] > 0 ? "have_value" : ""}`}
        errorMessage=""
        onChange={(e) => handleInputChange(e, tableIndex)}
        affix={sign.sign}
        affixPosition={sign.position}
        commaFormatting
      />
    </div>
  </Tooltip>
);

export const tableInputTooltipOld = (
  header,
  data,
  onTableInputChange,
  tableIndex,
  className = ""
) => (
  <Tooltip
    title="Change To Private Pricing"
    arrow
    placement="bottom-start"
    TransitionProps={{ timeout: 400 }}
  >
    <div className="inline-block">
      <FormInput
        name={header.value}
        value={data[header.value]}
        type="text"
        onGetData={(name, value) => onTableInputChange(name, value, tableIndex, data, header)}
        placeholder=""
        errorClass="errorClass"
        regex={/^(?=.*[1-9])\d*(?:\.\d+)?$/}
        parentClass={`table-radio-input ${className} ${data[header.value] > 0 ? "have_value" : ""}`}
        errorMessage=""
      />
    </div>
  </Tooltip>
);

export const yearlyCommitmentTableDropDownList = [
  {
    value: "GB_YEAR",
    label: "GB/Year",
  },
  {
    value: "TB_YEAR",
    label: "TB/Year",
  },
];

export const mbConversion = (value, unit) => {
  if (unit === "GB") {
    return value * 1000;
  }
  if (unit === "TB") {
    return value * 1000000;
  }

  return value;
};
export const gbConversion = (value, unit) => {
  if (unit === "TB") {
    return value * 1000;
  }

  return value;
};

export const yearlyCommitmentErrorCheck = (data, tableIndex, value, dropdownValue) => {
  let error = false;
  const dataWithoutError = data.map((item, index) => {
    const obj = { ...item };
    if (tableIndex === index) {
      obj.qty = value;
      obj.valueInMb = mbConversion(Number(value), dropdownValue);
      obj.unit = dropdownValue;
      obj.error = "";
    }
    return obj;
  });
  const updatedData = dataWithoutError.map((item, index) => {
    const obj = { ...item };

    if (dataWithoutError.length > 1) {
      if (obj?.valueInMb < dataWithoutError[index - 1]?.valueInMb) {
        obj.error = "Cannot be less than previous";
        error = true;
      } else if (obj?.valueInMb > dataWithoutError[index + 1]?.valueInMb) {
        obj.error = "Cannot be greater than next";
        error = true;
      } else {
        obj.error = "";
      }
    }
    if (obj?.valueInMb === 0 || obj?.valueInMb < 0) {
      obj.error = "Please Enter Some Positive Value greater then zero";
      error = true;
    }
    return obj;
  });
  return { data: updatedData, error };
};

export const yearlyHeaders = [
  { label: "Tenure", value: "year", className: "" },
  { label: "Commitment ($)", value: "qty", type: "input", className: "mandatory" },
  { label: "", value: "Action", type: "Actions" },
];

export const yearlyHeadersOld = [
  { label: "Contract For", value: "year", className: "" },
  { label: "Data Transfer", value: "qty", type: "input", className: "mandatory" },
  { label: "Quantity Type", value: "unit", type: "dropdown" },
  { label: "", value: "Action", type: "Actions" },
];
export const selectAccountChargebackFormConfig = {
  type: "select",
  name: "selectAccountChargeback",
  placeHolder: "Select Account",
  className: "vri-form-element !mt-0 !mb-2",
  label: "Select an account for applying chargeback",
  enableFilter: { placeHolder: "Search" },
  topLabel: {
    text: "Select an account for applying chargeback",
    fontSize: "small",
    class: "!text-black mandatory whitespace-nowrap",
  },
};

export const getInputCase = (header, data, tableIndex, tableData, onTableInputChange, error) => (
  <PpaInput
    name={header.value}
    value={data[header.value]}
    type="text"
    // disabled={tableIndex !== tableData.length - 1}
    onGetData={(name, value) => onTableInputChange(name, value, tableIndex, tableData)}
    placeholder={`Type ${header.label}`}
    errorClass="errorClass break_space"
    // regex={/^(?=.*[1-9])\d*(?:\.\d+)?$/}
    errorMessage={error}
    focused
    commaFormatting
  />
);

export const getInputCaseOld = (header, data, tableIndex, tableData, onTableInputChange) => (
  <FormInput
    name={header.value}
    value={data[header.value]}
    type="number"
    // disabled={tableIndex !== tableData.length - 1}
    onGetData={(name, value) => onTableInputChange(name, value, tableIndex, tableData)}
    placeholder={`Type ${header.label}`}
    errorClass="errorClass break_space"
    parentClass={data?.error?.length > 0 ? "error_box" : ""}
    // regex={/^(?=.*[1-9])\d*(?:\.\d+)?$/}
    comparisonError={data?.error?.length > 0}
    errorMessage={data.error}
  />
);

export const getActionsCase = (tableIndex, tableData, removeRow, addRow) =>
  tableIndex === tableData.length - 1 && (
    <>
      <span
        onClick={() => removeRow(tableData)}
        className={`add_icon mr-2 ${tableIndex === 0 ? "hidden" : ""}`}
        aria-hidden
      >
        <MinusIcon />
      </span>
      <span
        onClick={() => addRow(tableData, tableIndex)}
        aria-hidden
        className={`add_icon ${tableIndex === 4 ? "hidden" : ""} ${
          yearlyCommitmentErrorCheck(
            tableData,
            tableIndex,
            tableData[tableIndex]?.qty,
            tableData[tableIndex]?.unit
          )?.error
            ? "grey_icon"
            : ""
        }`}
      >
        <PlusIcon />
      </span>
    </>
  );

export const getDefaultCase = (header, data, renderYear) =>
  header?.value === "year" ? (
    <span className="contact_for_content_wrapper">
      {data[header?.value]}
      <sup>{renderYear(Number(data?.[header?.value]))}</sup> Year
    </span>
  ) : (
    data[header.value]
  );

export const getDropDownCase = (
  dropDownConfig,
  data,
  header,
  tableIndex,
  handleChangeDropdown,
  tableData
) => (
  <BasicSelect
    {...dropDownConfig}
    value={{ ...data[header?.value] }}
    handleChange={(e) => handleChangeDropdown(e, tableIndex, tableData)}
  />
);

export const replaceNull = (array) => {
  const modifiedArray = array.map((obj) => {
    const modifiedObj = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (obj[key] === "0" || obj[key] === "") {
        modifiedObj[key] = null;
      } else {
        modifiedObj[key] = obj[key];
      }
    }
    return modifiedObj;
  });
  return modifiedArray;
};

export const filterNonNull = (array, keys) => {
  const updatedArrayList = array.filter((item) => item.groupKey !== "All");
  // Filter out objects that don't have at least one non-null value in specified keys
  const updatedArray = replaceNull(updatedArrayList);
  const filteredArray = updatedArray.filter((obj) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        return true; // Include the object
      }
    }
    return false; // Exclude the object
  });

  return filteredArray;
};

export const filterNewRegion = (array) => {
  const Obj = {};
  array.forEach((item) => {
    if (ConvertToBoolean(item.Awspricing)) {
      Obj[item.key] = null;
    } else {
      Obj[item.key] = item.customPricing;
    }
  });
  return Obj;
};

export const getErrorMessage = (value, maxLength) => {
  if (value?.length === 0) {
    return "This field is required";
  }
  if (value?.length > maxLength) {
    return `Maximum ${maxLength} characters are allowed`;
  }
  return "";
};

export const checkIfValid = (
  name,
  description,
  selectedCbuList,
  yearlyCommitmentTableData,
  aosSize,
  newRegionToggle,
  newPrivatePricing
) => {
  const errorObj = {
    nameError: name?.value?.length === 0 || name?.value?.length > 50,
    descriptionError: description?.value?.length === 0 || description?.value?.length > 200,
    selectedCbuListError: selectedCbuList?.length === 0,
    yearlyCommitmentTableDataError: false,
    newPrivatePricingError: false,
    // aosSizeError: aosSize.length === 0,
  };

  const getYearlyError = yearlyCommitmentErrorCheck(
    yearlyCommitmentTableData,
    yearlyCommitmentTableData.length - 1,
    yearlyCommitmentTableData[yearlyCommitmentTableData.length - 1]?.qty,
    yearlyCommitmentTableData[yearlyCommitmentTableData.length - 1]?.unit
  );

  errorObj.yearlyCommitmentTableDataError = getYearlyError.error;

  if (newRegionToggle === "Yes") {
    newPrivatePricing.forEach((item) => {
      if (!ConvertToBoolean(item?.Awspricing) && item?.customPricing.length === 0) {
        errorObj.newPrivatePricingError = true;
      }
    });
  }

  const isValid = Object.values(errorObj).filter((item) => item === true);
  return isValid?.length > 0;
};
export const createFlatArray = (list, selected) =>
  list.filter((item) => selected.includes(item.id)).map((item) => item.name);

export function returnMaxDecimal(value, pattern, decimal = 5) {
  if (value < 0) {
    return "";
  }
  if (!pattern.test(value)) {
    return Number(value).toFixed(decimal);
  }
  return value;
}
export function NoDataFoundMessage({ className = "" }) {
  return (
    <div className={`no_data ${className}`}>
      <p>No Data Found</p>
    </div>
  );
}

// eslint-disable-next-line max-statements, complexity
export const getSrcBtnText2 = (btnText2, disableButton, addButtonText = "") => {
  if (addButtonText === "End Private Pricing") {
    if (disableButton) {
      return EndPPADisable;
    }
    return EndPPAActive;
  }
  if (addButtonText === "End Flat Discount") {
    if (disableButton) {
      return EndFlatDisable;
    }
    return EndFlatActive;
  }
  if (addButtonText === "Support Configuration") {
    if (disableButton) {
      return SupportEditIconGrey;
    }
    return SupportEditIconBlue;
  }

  if (addButtonText === "End Charge/Refund") {
    if (disableButton) {
      return EndFlatDisable;
    }
    return EndFlatActive;
  }
  if (btnText2.includes("Manage")) {
    if (disableButton) {
      return EditIconGrey;
    }
    return EditIcon;
  }
  if (btnText2.includes("Nullify")) {
    if (disableButton) {
      return NUllifyIconGrey;
    }
    return NUllifyIcon;
  }

  if (addButtonText.includes("CloudFront") || btnText2 === "Nullify Credits") {
    if (disableButton) {
      return EndDiscount;
    }
    return EndDiscountActive;
  }
  if (disableButton) {
    return ReservationsDisabled;
  }
  return ReservationsActive;
};

export const commitmentStartDate = {
  type: "calender",
  name: "commitmentStartDate",
  placeHolder: "End Date",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "Update End Date:",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
};

export const preponeEndDate = {
  type: "calender",
  name: "preponeEndDate",
  placeHolder: "End Date",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "New End Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
};

export const discountEndtDate = {
  type: "calender",
  name: "discountEndtDate",
  placeHolder: "End Date",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "End Date:",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 ",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
};

export const customizedInput = {
  labelText: "New Commitment",
  labelClass: "text-[11px] font-normal -mt-px leading-[1.42857143] mandatory",
  type: "optionInputField",
  name: "customizedInput",
  inputLabel: "Commitment",
  buttonWrapperClass: "flex",
  className: "pr-2.5 singleDateModule mb-[0]",
  options: ["TB", "GB"],
  validations: {
    rules: {
      required: "OptionInputFieldValidation",
    },
    messages: {
      required: "ErrorHere",
    },
  },
};

export const commentsValidation = {
  label: "Comments",
  labelClass: "mandatory credits_mandatory",
  className: "!rounded-md mt-[20px] relative",
  inputFieldClass: "!mt-[5px]",
  maximum: "2000",
  maxLength: "2000",
  name: "comments",
  placeHolder: "Enter Comments",
  rows: "4",
};

export const flatDiscountEndDate = {
  type: "calender",
  name: "flatDiscountEndDate",
  placeHolder: "Discount End Date",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "Discount End Date:",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 ",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
};

export const chargesAndRefundEndDate = {
  type: "calender",
  name: "ChargesAndRefundEndDate",
  placeHolder: "Discount End Date",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "Discount End Date:",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 ",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
};

export const edpDiscountEndDate = {
  type: "calender",
  name: "discountEndDate",
  placeHolder: "Update End Date:",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "Update End Date:",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 ",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
};

export const creditDiscountEndDate = {
  type: "calender",
  name: "discountEndDate",
  placeHolder: "New End Date",
  className: "pr-2.5 singleDateModule mb-[0]",
  singleDatePicker: true,
  openPosition: "right",
  topLabel: {
    text: "New End Date",
    fontSize: "",
    class: "!text-[#151E33] !text-[11px] !m-0 mandatory",
  },
  labelText: "End Date",
  validations: requiredRulesExport,
  collapseState: true,
  mandatory: true,
};

export const VR_TABS = [
  { label: "EC2", value: "ec2", key: "ec2", downloadKey: "ec2" },
  { label: "RDS", value: "rds", key: "rds", downloadKey: "rds" },
  { label: "ElastiCache", value: "elasticache", key: "elasticache", downloadKey: "elasticache" },
  { label: "OpenSearch", value: "opensearch", key: "opensearch", downloadKey: "opensearch" },
  { label: "RedShift", value: "redshift", key: "redshift", downloadKey: "redshift" },
  { label: "Savings Plan", value: "savings-plan", key: "savings-plan", downloadKey: "savingsPlan" }, // need to update vd
  {
    label: "CloudFront Security Savings Bundle (CSSB)",
    value: "cssb",
    key: "cssb",
    downloadKey: "cssb",
  },
];
export const RESERVATION_DISCOUNT_TABS = [
  { label: "All Services", value: "ALL", key: "ALL", downloadKey: "ALL" },
  { label: "EC2", value: "EC2", key: "EC2", downloadKey: "EC2" },
  { label: "RDS", value: "RDS", key: "RDS", downloadKey: "RDS" },
  { label: "ElastiCache", value: "ELASTICACHE", key: "ELASTICACHE", downloadKey: "ELASTICACHE" },
  { label: "OpenSearch", value: "OPENSEARCH", key: "OPENSEARCH", downloadKey: "OPENSEARCH" },
  { label: "Redshift", value: "REDSHIFT", key: "REDSHIFT", downloadKey: "REDSHIFT" },
  { label: "Compute", value: "COMPUTE", key: "COMPUTE", downloadKey: "COMPUTE" },
  {
    label: "CloudFront",
    value: "CLOUD_FRONT",
    key: "CLOUD_FRONT",
    downloadKey: "CLOUD_FRONT",
  },
];

export const PPA_TABS = [
  {
    label: "CloudFront",
    value: "CloudFront",
    key: "CloudFront",
    downloadKey: "CLOUDFRONT",
    name: "cloudfront",
  },
  {
    label: "MediaConnect",
    value: "MediaConnect",
    key: "MediaConnect",
    downloadKey: "MEDIACONNECT",
    name: "media-connect",
  },
  {
    label: "MediaTailor",
    value: "MediaTailor",
    key: "MediaTailor",
    downloadKey: "MEDIATAILOR",
    name: "media-tailor",
  },
  {
    label: "MediaLive",
    value: "MediaLive",
    key: "MediaLive",
    downloadKey: "MEDIALIVE",
    name: "media-live",
  },
  {
    label: "Advanced Shield",
    value: "Advanced Shield",
    key: "Advanced Shield",
    downloadKey: "ADVANCEDSHIELD",
    name: "advanced-shield",
  },
];

export const notShowManageRenewalBtn = ["savings-plan", "cssb"];

export const GETBUTTONTEXT = {
  createTemplate: "Yes, Confirm",
  cloneTemplate: "Clone Template",
  FLAT_DISCOUNT: "Flat Discount",
  GENERIC_DISCOUNT: "Generic",
  Generic: "Generic",

  EDP_DISCOUNT: "EDP Discount",
  Credits: "Credits",
  "Reservation Discount": "Reservation Discount",
  "Enterprise Discount Program (EDP)": "Enterprise Discount Program (EDP)",
  "Flat Discount": "Flat Discount",
  "Charges And Refunds": "Charges And Refunds",
};

export const restructureCBUList = (data) => {
  const list = [];
  data.forEach((item) => {
    const obj = {
      value: item?.name,
      label: item?.name,
      id: item?.id,
      checked: false,
      // accounts: item?.accounts,
    };
    list.push(obj);
  });

  return list;
};

export const flatDiscountModalBodyHTML = (modal, heading = {}) => {
  const { modalName, modalData, isModalLoading } = modal;
  const descriptionText =
    "Note that transitioning to another module will result in the loss of unsaved changes in form.";
  switch (modalName) {
    case "createTemplate":
      return (
        <div className="flex items-center justify-center flex-col px-[30px] mt-[35px]">
          <div className="mb-[32px]">
            <h3 className="text-[22px] font-bold">Are you sure?</h3>
          </div>
          <div className="mb-[14px]">
            <DiscountConfigurationIcon />
          </div>
          <div className="text-center	mb-[33px]">
            <p className="text-[15px] font-medium	!text-black">{descriptionText || ""}</p>
          </div>
        </div>
      );
    case "cloneTemplate":
      return (
        <div className="modalWrapperFlatDiscount min-w-[1000px] min-h-[150px]">
          {isModalLoading ? (
            <Loader />
          ) : (
            <>
              <div className="templateInfo">
                <div className="styleTemplateInfo">
                  <div className="templateSub">Template Type:</div>
                  <p className="templateValue">{`${
                    GETBUTTONTEXT[modalData?.templateType || ""] || ""
                  }`}</p>
                </div>
                {modalData?.serviceType ? (
                  <div className="styleTemplateInfo">
                    <div className="templateSub">{heading?.serviceType || "Service Type:"}</div>
                    <p className="templateValue">{`${modalData?.serviceType || ""}`}</p>
                  </div>
                ) : null}
                <div className="styleTemplateInfo">
                  <div className="templateSub">Template Name:</div>
                  <p className="templateValue">{`${modalData?.name || ""}`}</p>
                </div>
              </div>
              <div className="configFlatDiscountDescription">
                <div className="styleDescription">Description:</div>
                <p className="descriptionValue overflow-y-auto max-h-[90px]">{`${
                  modalData?.description || ""
                }`}</p>
              </div>
              <div className="styleSelectedConfig mt-[19px]">
                <div className="selectedConfigHeader">Selected Configurations</div>
                <div className="selectedConfigValues">
                  {modalData && Object.keys(modalData?.transformConfig)?.length
                    ? Object.keys(modalData?.transformConfig)?.map((item) => (
                        <DimensionBox
                          deleteConfigGroup={() => {}}
                          dimenssionName={item} // Use displayName as dimensionName
                          selectedConfiguration={modalData?.transformConfig}
                          deleteConfig={() => {}}
                          readOnly
                          CHIPS_THRESHOLD={15}
                          includeSelectionFilter
                        />
                      ))
                    : null}
                </div>
              </div>
            </>
          )}
        </div>
      );
    default:
      return null;
  }
};

export const previewDiscountModalBodyHTML = (modal) => {
  const { modalData, isModalLoading } = modal;
  return (
    <div className="modalWrapperFlatDiscount min-w-[1000px] min-h-[150px]">
      {isModalLoading ? (
        <Loader />
      ) : (
        <>
          <div className="templateInfo">
            <div className="styleTemplateInfo mr-[24px]">
              <div className="templateSub">Template Type:</div>
              <p className="templateValue">{`${
                GETBUTTONTEXT[modalData?.templateType || ""] || ""
              }`}</p>
            </div>
            {modalData?.serviceType ? (
              <div className="styleTemplateInfo">
                <div className="templateSub">Service Type:</div>
                <p className="templateValue">{`${modalData?.serviceType || ""}`}</p>
              </div>
            ) : null}
            <div className="styleTemplateInfo">
              <div className="templateSub">Template Name:</div>
              <p className="templateValue">{`${modalData?.name || ""}`}</p>
            </div>
          </div>
          <div className="configFlatDiscountDescription">
            <div className="styleDescription">Description:</div>
            <p className="descriptionValue overflow-y-auto max-h-[90px]">{`${
              modalData?.description || ""
            }`}</p>
          </div>
          <div className="styleSelectedConfig mt-[19px]">
            <div className="selectedConfigHeader">Selected Configurations</div>
            <div className="selectedConfigValues">
              {modalData &&
              modalData?.transformConfig &&
              Object.keys(modalData?.transformConfig)?.length
                ? Object.keys(modalData?.transformConfig)?.map((item) => (
                    <DimensionBox
                      deleteConfigGroup={() => {}}
                      dimenssionName={item} // Use displayName as dimensionName
                      selectedConfiguration={modalData?.transformConfig}
                      deleteConfig={() => {}}
                      readOnly
                      CHIPS_THRESHOLD={15}
                      includeSelectionFilter
                    />
                  ))
                : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const previewTemplateModalFooter = (modalCloseHandler, isModalLoading) => (
  <div className="flex justify-end p-[15px] bg-blue-50 border-t border-solid border-[#E8E6E6] end-reservation-buttons rounded-b-[6px]">
    <Button
      text="Cancel"
      variant="primary"
      className="rounded-[4px] !font-bold ml-[10px] icon_btn"
      disabled={isModalLoading}
      handleClick={modalCloseHandler}
    />
  </div>
);

export const getPreviewTemplateModalData = async (id, modalObj = {}) => {
  let modalObjClone = cloneDeep(modalObj);
  try {
    const getModalData = await commonService.getTemplateModalData(id);
    const {
      data: { data },
    } = getModalData;
    const transformConfig = {};
    data?.config?.forEach((item) => {
      transformConfig[item?.displayName] = {
        value: item?.items || [],
        included: !item?.excludeSelected || false,
      };
    });
    modalObjClone.modalData = { ...data, transformConfig };
    modalObjClone.isModalLoading = false;
  } catch (e) {
    modalObjClone = {};
  }
  return modalObjClone;
};

export const NEW_TEMPLATE = [
  {
    label: "New Template",
    class: "button primary medium  rounded mr-[12px]",
    variant: "primary",
    link: Routes.CREATETEMPLATE,
  },
];
export const sortByState = (data = [], sortBy = "") =>
  data
    .reduce(
      (sortedItems, item) => {
        const state = item?.[sortBy]?.toLowerCase();
        if (state === "active") sortedItems[0].push(item);
        else if (state === "queued") sortedItems[1].push(item);
        else sortedItems[2].push(item);
        return sortedItems;
      },
      [[], [], []] // Initialize arrays for active, queued, and expired items
    )
    .flat(); // Merge arrays and return

export const updateAccountsList = (arr, element) => {
  const index = arr?.indexOf?.(element);
  if (index > -1) {
    arr?.splice(index, 1); // Remove the element from its current position
    arr?.unshift(element); // Add it to the beginning of the array
  }
  return arr;
};

export const getSearchBar = (searchTerm, searchHandler, searchBarClass = "") => (
  <SearchBar
    searchTerm={searchTerm}
    onChangeHandler={(term) => searchHandler(term)}
    placeholder="..."
    styleSearch=" !mt-0 !mx-0"
    searchBarClass={searchBarClass}
  />
);

export const getDownloadXLSButton = (tableData, downloadUrl, className = "") => (
  <DownloadButton
    disableDownload={!tableData?.data?.length}
    downloadUrl={downloadUrl}
    downloadBtnClass="customer-edit-btn"
    className={className}
  />
);

const renderCreditName = (item) => (
  <TooltipV2
    parentClass="Edp_Tooltip_Style_V2"
    displayText={() => (
      <div className="Edp_TooltipV2_Header">
        {item?.name ? item?.name : "No Description Available"}
      </div>
    )}
  >
    {renderEdpDescriptionTooltip(item?.description)}
  </TooltipV2>
);

const checkboxHeaderComponent = () => ({
  Header: "",
  accessor: "checkboxId",
  bodyClass: "whitespace-nowrap normal-case !sticky left-0 z-10 bg-white",
  headerClass: "whitespace-nowrap !sticky left-0 !z-20 top-0",
  props: {
    doNotAddColumnSelector: true,
    disabledMenuItem: true,
    hideOnRO: true,
  },
});

const renderAccountIds = () => ({
  Header: "Account ID(s)",
  accessor: "accountIds",
  component: ListPopup,
  array: true,
  props: {
    dataCount: 2,
    shouldSort: false,
    defaultSelectedHeader: true,
    popupClassName: "credits-list-popup-tooltip",
  },
  bodyClass: "whitespace-nowrap normal-case !text-left",
  headerClass: "whitespace-nowrap !text-left",
  searchable: true,
});

const renderName = () => ({
  Header: "Credit Name",
  accessor: "name",
  sortedIcon: true,
  bodyClass:
    "whitespace-nowrap normal-case bg-white !px-[8px] !sticky left-[20px] !text-left discount_name_V2",
  headerClass: "whitespace-nowrap !z-10 !sticky left-[20px] !text-left",
  render: (item) => renderCreditName(item),
  props: {
    disabledMenuItem: true,
    defaultSelectedHeader: true,
  },
  searchable: true,
});

const renderCreditAmount = () => ({
  Header: "Total Credit Amount ($)",
  accessor: "totalAmount",
  sortedIcon: true,
  bodyClass: "whitespace-nowrap normal-case summary_active !text-left",
  headerClass: "whitespace-nowrap !text-left",
  props: {
    defaultSelectedHeader: true,
  },
  searchable: true,
});

const renderRepresentation = () => ({
  Header: "Representation",
  accessor: "representation",
  sortedIcon: true,
  bodyClass: "whitespace-nowrap normal-case !text-left",
  headerClass: "whitespace-nowrap !text-left",
  props: {
    defaultSelectedHeader: true,
  },
  searchable: true,
});

export const CREDITS_HEADER = (
  handlePreview = noop,
  handleOrderingModal = noop,
  getCommentHistory = noop
) => [
  {
    ...checkboxHeaderComponent(),
  },
  {
    ...renderName(),
  },
  {
    Header: "Template Name",
    accessor: "discountTemplateName",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case Template template_name_V2 !text-left",
    headerClass: "whitespace-nowrap !text-left",
    searchable: true,
    render: (item) =>
      item?.discountTemplateName && (
        <div className="flex">
          {item?.discountTemplateName}
          <EyeActiveIcon
            className="ml-[10px] w-[14px] h-[14px] cursor-pointer"
            onClick={() => handlePreview(item)}
          />
        </div>
      ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    ...renderAccountIds(),
  },
  {
    Header: "Ordering",
    accessor: "ordering",
    bodyClass: "whitespace-nowrap normal-case bg-white !px-[8px] !text-left discount_name_V2",
    headerClass: "whitespace-nowrap  !text-left",
    searchable: true,
    render: (item) =>
      item?.ordering?.length ? (
        <button type="button" className="w-full" onClick={() => handleOrderingModal(item)}>
          <EyeActiveIcon className="text-[#0A3CA2] w-[14px] h-[14px]" />
        </button>
      ) : (
        <div className="w-full text-center">- - -</div>
      ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    ...renderCreditAmount(),
  },
  { ...renderRepresentation() },
  {
    Header: "Comments",
    accessor: "comments",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    searchable: true,
    render: (item) =>
      item?.commentsAvailable ? (
        <button type="button" className="w-full" onClick={() => getCommentHistory(item)}>
          <MessageIcon />
        </button>
      ) : (
        <div className="w-full text-center">- - -</div>
      ),
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Frequency",
    accessor: "frequency",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Start Month",
    accessor: "startDate",
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    render: (item) => <div>{formatDate(item.startDate, "MMM-YY")}</div>,
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "End Month",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    render: (item) =>
      item?.endDate ? (
        <div>{formatDate(item.endDate, "MMM-YY")}</div>
      ) : (
        <div className="w-full">- - -</div>
      ),
  },
  {
    Header: "Last Updated At",
    accessor: "lastUpdatedAt",
    searchable: true,
    dateFormat: "DD-MMM-YYYY HH:mm",
    sortedIcon: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
  {
    Header: "Last Updated By",
    accessor: "lastUpdatedBy",
    sortedIcon: true,
    searchable: true,
    bodyClass: "whitespace-nowrap normal-case !text-left",
    headerClass: "whitespace-nowrap !text-left",
    props: {
      defaultSelectedHeader: true,
    },
  },
];

export const getFilteredHeaderList = (headerConstant, permission) => {
  if (permission) {
    return headerConstant?.filter((item) => !item?.props?.hideOnRO);
  }
  return headerConstant;
};

export const createTemplateList = (data, templateListConfig, onIconClick, EyeIcon) => {
  const templateData = templateListConfig;
  const optionsArr = [];

  data.forEach((item) => {
    const itemClone = {
      id: item.id,
      value: item.name,
      label: item.name,
      postImage: {
        icon: EyeIcon,
        onIconClick,
        props: {
          activeModal: "preview",
          id: item?.id,
          name: item?.name,
          createdAt: item?.createdAt,
          createdBy: item?.createdBy,
          ordering: item?.ordering,
          default: item?.default,
          onAccountCreate: "true",
        },
      },
    };
    optionsArr.push(itemClone);
  });
  templateData.options = [...optionsArr];
  templateData.footer = (
    <div className="bg-[#F0F6FF] py-[10px] px-[10px] flex rounded-b-[3px] items-center justify-end border-t border-solid border-t-[#DDDFE8]">
      <Link
        to={{
          pathname: Routes.PARTNERSETTINGS,
          search: "?tabId=3",
        }}
        target="_blank"
        className="text-[#0A3CA2] text-xs whitespace-nowrap flex items-center font-semibold"
        rel="noreferrer"
      >
        Manage Ordering Templates <ActiveArrow className="ml-[10px]" />
      </Link>
    </div>
  );
  return templateData;
};

export function CONSTRUCT_ORDERING_HEADERS(apiResponse, activeModal) {
  switch (activeModal) {
    case "customerType":
      return [
        {
          Header: "Customer Type",
          accessor: "type",
          sortedIcon: true,
          bodyClass:
            "whitespace-nowrap normal-case  bg-white !px-[8px] !text-left discount_name_V2",
          headerClass: "whitespace-nowrap  !text-left",
          searchable: true,
          render: (item) => (
            <div className="flex items-center justify-between">
              <span>{item?.type}</span>
              {item?.applied ? (
                <span className="default !text-[10px] !font-medium !text-[#337AB7] !px-[5px] !py-[2px] !bg-[#edf5ff] !border-[0.7px] !border-[solid] !border-[#bddafb] !rounded-[2px] !ml-auto">
                  Applied
                </span>
              ) : null}
            </div>
          ),
        },
        {
          Header: "Effective Date",
          accessor: "startDate",
          bodyClass: "whitespace-nowrap",
          searchable: true,
          sortedIcon: true,
          // hideFilters: true,
          render: (item) => <>{moment(item?.startDate).format("DD-MMM-YYYY, HH:mm")}</>,
        },
        {
          Header: "End Date",
          accessor: "endDate",
          bodyClass: "whitespace-nowrap",
          searchable: true,
          sortedIcon: true,
          hideFilters: true,
          render: (item) =>
            item?.endDate ? moment(item?.endDate).format("DD-MMM-YYYY, HH:mm") : null,
        },
        {
          Header: "Updated By",
          accessor: "lastUpdatedBy",
          bodyClass: "whitespace-nowrap",
          sortedIcon: true,
          searchable: true,
        },
        {
          Header: "Updated At",
          accessor: "lastUpdatedAt",
          bodyClass: "whitespace-nowrap",
          hideFilters: true,
          sortedIcon: true,
          searchable: true,
          render: (item) =>
            item?.lastUpdatedAt ? moment(item?.lastUpdatedAt).format("DD-MMM-YYYY, HH:mm") : null,
        },
      ];
    case "orderingTemplate":
      return [
        {
          Header: "Template Name",
          accessor: "name",
          sortedIcon: true,
          bodyClass:
            "whitespace-nowrap normal-case  bg-white !px-[8px] !text-left discount_name_V2",
          headerClass: "whitespace-nowrap  !text-left",
          searchable: true,
          render: (item) => (
            <div className="flex items-center justify-between">
              <span>{item?.name}</span>
              {item?.default ? (
                <span className="default !text-[10px] !font-medium !text-[#337AB7] !px-[5px] !py-[2px] !bg-[#edf5ff] !border-[0.7px] !border-[solid] !border-[#bddafb] !rounded-[2px] !ml-auto">
                  Default
                </span>
              ) : null}
            </div>
          ),
        },
        {
          Header: "Applied Date",
          accessor: "startDate",
          bodyClass: "whitespace-nowrap",
          searchable: true,
          sortedIcon: true,
          render: (item) => <>{moment(item?.startDate).format("DD-MMM-YYYY, HH:mm")} </>,
        },
        {
          Header: "End Date",
          accessor: "endDate",
          bodyClass: "whitespace-nowrap",
          searchable: true,
          sortedIcon: true,
          hideFilters: true,
          render: (item) =>
            item?.endDate ? moment(item?.endDate).format("DD-MMM-YYYY, HH:mm") : null,
        },
        {
          Header: "Updated By",
          accessor: "lastUpdatedBy",
          bodyClass: "whitespace-nowrap",
          sortedIcon: true,
          searchable: true,
        },
        {
          Header: "Actions",
          accessor: "actions",
          icons: true,
          overwritePermissions: true,
        },
      ];
    default:
      return null;
  }
}

export const getConstructOrderingTable = (apiResponse, activeModal, onActionClickHandler) => {
  apiResponse.map((item) => {
    const itemClone = item;
    itemClone.changeHandler = onActionClickHandler;
    if (itemClone.lastUpdatedAt) itemClone.lastUpdatedAt = moment(itemClone.lastUpdatedAt);
    else {
      itemClone.lastUpdatedAt = "";
    }
    if (itemClone?.startDate) itemClone.startDate = moment(itemClone?.startDate);
    else {
      itemClone.startDate = "";
    }
    if (itemClone?.lastUpdatedAt) itemClone.lastUpdatedAt = moment(itemClone?.lastUpdatedAt);
    else {
      itemClone.lastUpdatedAt = "";
    }
    if (itemClone?.endDate) itemClone.endDate = moment(itemClone?.endDate);
    else {
      itemClone.endDate = "";
    }
    itemClone.actions = [
      {
        actionType: "preview",
        class: "preview-icon",
        overwritePermissions: true,
      },
    ];
    return itemClone;
  });

  return {
    onIconClick: onActionClickHandler,
    headers: CONSTRUCT_ORDERING_HEADERS(apiResponse, activeModal),
    data: apiResponse,
  };
};

const dialogBodyHTML = (previewData, handleCloseModal) => (
  <div className="modalWrapperCustomerAccountModal p-[20px]">
    <div className="modalWrapperBody pt-[14px] px-[15px] pb-[13px]">
      <div className="templateInfo">
        <div className="styleTemplateInfo !mb-[15px] !mt-[10px]">
          <div className="templateSub mb-[2px] text-[11px] font-normal">Template Name:</div>
          <p className="templateValue !text-[14px] !font-medium !text-[#000000] !mt-0 relative">
            {previewData?.name || ""}
            {previewData?.default && (
              <span className="default !ml-[7px] !text-[10px] !font-medium !text-[#337AB7] !px-[5px] !py-[2px] !bg-[#edf5ff] !border-[0.7px] !border-[solid] !border-[#bddafb] !rounded-[2px]">
                Default
              </span>
            )}
          </p>
        </div>
      </div>
      <Draggable listOrder={previewData?.ordering} readOnly />
    </div>
    <div className="flex justify-end p-[15px] bg-blue-50 border-t border-solid border-[#E8E6E6] end-reservation-buttons rounded-b-[6px]">
      <Button
        text="Close"
        className="rounded-[4px] primary !font-semibold ml-[10px] icon_btn border border-solid border-[#0A3CA2] bg-white cancel "
        handleClick={handleCloseModal}
      />
    </div>
  </div>
);

export const existingCustomerBodyHTML = (
  modal,
  handleCloseActiveModal,
  handleCloseModal,
  onActionClickHandler,
  actionModal,
  customerName = ""
) => {
  const { activeModal, isModalLoading, previewData, templateData } = modal;
  switch (activeModal) {
    case "preview":
      return (
        <div className="modalWrapperCustomerAccountModal p-[20px]">
          {isModalLoading ? (
            <Loader />
          ) : (
            <>
              <div className="modalWrapperBody pt-[14px] px-[15px] pb-[13px]">
                <div className="templateInfo">
                  <div className="styleTemplateInfo !mb-[15px] !mt-[10px]">
                    <div className="templateSub mb-[2px] text-[11px] font-normal">
                      Template Name:
                    </div>
                    <p className="templateValue !text-[14px] !font-medium !text-[#000000] !mt-0 relative">
                      {previewData?.name || ""}
                      {previewData?.default && (
                        <span className="default !ml-[7px] !text-[10px] !font-medium !text-[#337AB7] !px-[5px] !py-[2px] !bg-[#edf5ff] !border-[0.7px] !border-[solid] !border-[#bddafb] !rounded-[2px]">
                          Default
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <Draggable listOrder={previewData?.ordering} readOnly />
              </div>
              <div className="flex justify-end p-[15px] bg-blue-50 border-t border-solid border-[#E8E6E6] end-reservation-buttons rounded-b-[6px]">
                <Button
                  text="Close"
                  className="rounded-[4px] primary !font-semibold ml-[10px] icon_btn border border-solid border-[#0A3CA2] bg-white cancel "
                  handleClick={handleCloseModal}
                />
              </div>
            </>
          )}
        </div>
      );
    case "customerType":
      return (
        <>
          <div className="text-[16px] font-medium text-[#000000] pt-[16.5px] px-[20px] pb-0">
            <h3>Customer Type Change History</h3>
          </div>
          <FilterTable config={getConstructOrderingTable(templateData, activeModal)} V2 />
          <div className="flex justify-end p-[15px] bg-blue-50 border-t border-solid border-[#E8E6E6] end-reservation-buttons rounded-b-[6px]">
            <Button
              text="Close"
              className="rounded-[4px] primary !font-semibold ml-[10px] icon_btn border border-solid border-[#0A3CA2] bg-white cancel "
              handleClick={handleCloseModal}
            />
          </div>
        </>
      );
    case "orderingTemplate":
      return (
        <>
          <div className="text-[16px] font-medium text-[#000000] pt-[16.5px] px-[20px] pb-0">
            <h3>{`Construct Ordering History for ${customerName || ""}`}</h3>
          </div>
          <FilterTable
            config={getConstructOrderingTable(templateData, activeModal, onActionClickHandler)}
            V2
          />
          {actionModal?.show && (
            <DialogComponent
              className="modalWrapper-customerAaccount"
              id=""
              open={actionModal?.show}
              handleClose={handleCloseActiveModal}
              bodyClass="!border-0"
            >
              {dialogBodyHTML(actionModal?.data, handleCloseActiveModal)}
            </DialogComponent>
          )}
          <div className="flex justify-end p-[15px] bg-blue-50 border-t border-solid border-[#E8E6E6] end-reservation-buttons rounded-b-[6px]">
            <Button
              text="Close"
              className="rounded-[4px] primary !font-semibold ml-[10px] icon_btn border border-solid border-[#0A3CA2] bg-white cancel "
              handleClick={handleCloseModal}
            />
          </div>
        </>
      );
    default:
      return null;
  }
};

export const renderFilterTable = ({ modalData }) => (
  <div>
    <FilterTable config={modalData} tableWrapperClass="discount_config_table" V2 />
  </div>
);

export const returnClassNameCommitmentTable = (isEditable, readOnly) =>
  `${isEditable ? "editable" : "Non_editable"} commitment-section ${
    readOnly ? "read_only_commitment" : ""
  }`;

export const updatedAccountConfig = (configuration, readOnly) =>
  readOnly
    ? {
        ...configuration,
        label: "Account Chargeback:",
        topLabel: {
          ...configuration.topLabel,
          text: "Account Chargeback:",
        },
      }
    : { ...configuration };

export const updateHeadingOnreadOnly = (heading, readOnly) => {
  if (readOnly) {
    return heading?.replace("Configure ", "");
  }
  return heading;
};

export const getUpdatedYearlyHeaders = (units, headers) =>
  units
    ? [
        { label: "Tenure", value: "year", className: "" },
        { label: "Data Transfer", value: "qty", type: "input", className: "mandatory" },
        { label: "Unit", value: "unit", type: "dropdown", className: "mandatory" },
        { label: "", value: "Action", type: "Actions" },
      ]
    : [...headers];
