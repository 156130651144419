export default {
  password: /^(?!.*\s)(?=.*[a-zA-Z])(?=.*\d).{8,20}$/,
  email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/,
  onlyAlphabets: "^[a-zA-Z0-9._-]+$",
  customEmail: "^\\w+([\\.-]?\\w+)@\\w+([\\.-]?\\w+)(\\.\\w{2,3})+$",
  domain:
    "^[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$",
  nameRegex: "^[A-Za-z\\s]{1,20}$",
  partnerNameRegex: "^[A-Za-z()-\\s]{1,30}$",
  numberOnly: "^[0-9]+$",
  numberGreaterThanZero: /^(?!0+(\.0+)?$)(\d+(\.\d+)?|0?\.\d+)$/,
  positiveNumberUptoTwoDecimal: /^\d+(\.\d{1,2})?$/,
  companyNameRegex:
    /^(?=.*[a-zA-Z])[a-zA-Z0-9\s.&@#$!()¥&€£-]*[a-zA-Z0-9][a-zA-Z0-9\s.&@#$!()¥&€£-]*$/,
  domainRegex:
    /^(?!:\/\/)(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?).)+(?:[a-zA-Z0-9]{1,}|xn--[a-zA-Z0-9]{2,})$/,
  dateRegexMMDDYYYY: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[0-1])\/\d{4}$/,
  positiveNumberLessThen100:
    /^(?!100(\.0{1,2})?$|0+(\.0{1,2})?$)(\d{1,2}(\.\d{1,2})?|0?\.\d{1,2})$/,
  commaSeparatedEmails:
    /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})(?:\s*,\s*([\w.%+-]+)@([\w-]+\.)+([\w]{2,})){0,4}$/,
  emailCommaSpaceSeperated:
    /^[\s]*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[\s]*(?:,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[\s]*)*$/,
  firstOrLastNameRegex: "^[A-Za-z](?:[A-Za-z\\s]{0,18}[A-Za-z])?$",
  decimalLimit4: /^(?=.*[1-9])\d*\.?\d{0,4}$/,
  only128underscoreCapitalRegex: /^[a-zA-Z0-9-_]{1,128}$/,
  alphaNumericSpecialCharacters: /^[a-zA-Z0-9!\-_.*'()]+$/,
  s3BucketNameValidation: /(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/,
  s3PathPrefixRegex: /^(?=.{1,1024}$)[^/]+(\/[^/]+)*$/,
  bucketAccountRegex: /^\d{12}$/,
  decimalLimit5: /^(\d{1,8}(\.\d{0,5})?|\.\d{1,5})?$/,
  number10Decimal4: /^(\d{1,10}(\.\d{0,4})?|\.\d{1,4})?$/,
  customerTypeStatus: /^(?!.*[- ]{2})[a-zA-Z0-9_-\s]+$/,
  arnRegex: /^arn:aws:iam::\d{12}:role\/[^/]+$/,
  CUSTOMER_NAME_REGEX: /^(?! )[A-Za-z0-9!@#$%^&*(),.?":{}_-|<> ]{2,}$/,
};
