import React from "react";
import "./style.scss";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as HomeOutlinedIcon } from "../../../../Assets/icons/others/iconHomeV2.svg";
import { ReactComponent as ExcelIcon } from "../../../../Assets/icons/others/excel-icon.svg";
import { ReactComponent as DownloadWhite } from "../../../../Assets/icons/others/download-outlineWhite.svg";
import { ReactComponent as ExcelLoader } from "../../../../Assets/icons/others/excel-loader.svg";
import SeeMoreText from "../../../Common/SeeMoreText";
import actions from "../../../../Actions";

function ComponentHeader(props) {
  const {
    breadcrumsData = [],
    headerName,
    selectedUrl,
    downloadDropdown = false,
    DownlaodTabs = [],
    toggleDropdown,
    dropDownToggler,
    downlaodHandler,
    downloadList,
    innerRef,
    hasData,
    isLoading,
    excelLoading,
    groupDetails,
    customClass = "",
    headerTextFont = "",
    defaultPath,
  } = props;

  const handleChange = (e) => {
    groupDetails(e.target.value);
  };

  return (
    <div className={`Component_header_wrapper ${customClass}`}>
      <div>
        <div className="breadcrums_wrapper">
          <Breadcrumbs separator={<ChevronRightIcon />}>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              to={`/dashboard/${defaultPath}`}
            >
              <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            </Link>
            {!!breadcrumsData.length &&
              breadcrumsData.map((item) => (
                <Link
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={selectedUrl}
                >
                  <p className="small-text-breadcrum ">
                    <SeeMoreText text={item?.replace("%20", " ")} component="Breadcrumbs" />
                  </p>
                </Link>
              ))}
          </Breadcrumbs>
        </div>
        <h3 className={`header_text ${headerTextFont}`}>{headerName}</h3>
      </div>
      <div>
        {downloadDropdown && (
          <div className="download_Wrapper" ref={innerRef}>
            <Tooltip title="Download xlsx" arrow placement="top">
              <Button
                variant="outlined"
                onClick={() => dropDownToggler()}
                id="download"
                disabled={isLoading || !hasData || excelLoading}
                className="downloadxBtn"
                data-testid="download_toggler"
              >
                <span className="xcelIcon">
                  <ExcelIcon />
                </span>
                <span className="xcelDownloadIc">
                  {excelLoading ? <ExcelLoader className="excel-loading-gif" /> : <DownloadWhite />}
                </span>
              </Button>
            </Tooltip>
            {toggleDropdown && (
              <div className="options_list">
                {DownlaodTabs.map((item) => (
                  <p>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item.value}
                          id={item.value}
                          data-testid={`FormControlLabel_Checkbox_${item.value}`}
                          onClick={(e) => handleChange(e)}
                          size="small"
                          checked={
                            item.value === "ALL"
                              ? downloadList.length === DownlaodTabs.length - 1
                              : downloadList.includes(item.value)
                          }
                        />
                      }
                      label={item.label}
                    />{" "}
                  </p>
                ))}
                <Button
                  variant="contained"
                  className="w-100"
                  onClick={() => downlaodHandler()}
                  id="download_now"
                >
                  Download Now
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  defaultPath: state.DefaultPathReducer?.path,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentHeader);
