import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../style.scss";
import NotFoundImage from "../../../../Assets/icons/no-recipient.svg";

export default function ChipMultiSelect({
  value,
  name,
  options = [],
  handleChange,
  selected,
  children,
  // clearAllFilter,
  // filterSelected,
  // setFilterSelected,
  wrapperWidthClass,
  noDataFound,
  formatedLabel = false,
  searchedText,
}) {
  const [searchResult, setSerachResult] = useState(options);
  const unSelectedItems = searchResult?.length
    ? searchResult.filter((option) => !option.checked)
    : [];

  const getSeletedItems = (e, option) =>
    e.target.checked
      ? [...selected, { ...option, checked: e.target.checked }]
      : selected.filter((ele) => ele.value !== option.value);

  const handleMultiSelectChange = (e, option) => {
    const selectedItems = getSeletedItems(e, option);

    const optionClone = option;
    optionClone.checked = e.target.checked;

    handleChange({ ...e, target: { ...e.target, value: selectedItems, name: e.target.name } });
  };

  const selectAll = (e) => {
    const selectedItems = searchResult.filter((option) => {
      const optionClone = option;
      optionClone.checked = e.target.checked;
      return optionClone.checked;
    });

    handleChange({ ...e, target: { ...e.target, value: selectedItems, name: e.target.name } });
  };

  const renderCheckBoxes = () =>
    searchResult.length ? (
      <>
        {searchResult.map((option) => (
          <div className="option items-center justify-between optionHoverCss" key={option.id}>
            <label
              className="multiSelectLabel"
              onChange={(e) => handleMultiSelectChange(e, option)}
            >
              <input
                type="checkbox"
                name={name}
                checked={option?.checked}
                className="multiSelectCheck"
              />
              {formatedLabel ? option?.label(option?.value) : option?.label}
            </label>
          </div>
        ))}
      </>
    ) : (
      <span className="text-[10px] no_data_found py-[20px]">No Data Found</span>
    );

  // const onSelectChange = (e) => {
  //   if (e.target.value === "Clear") {
  //     clearAllFilter();
  //   }
  //   setFilterSelected(e.target.value);
  // };

  useEffect(() => {
    if (options) {
      setSerachResult(options);
    }
  }, [options?.length]);

  return (
    <div
      className={`multi-select-wrapper ${!!selected?.length && "selected"} ${wrapperWidthClass}`}
    >
      {!searchedText && searchResult?.length ? (
        <div className="ChipSelectorHeader">
          <div className="option flex items-center justify-between">
            <FormControlLabel
              control={
                <Checkbox
                  className="chipOptionCheckbox"
                  name={name}
                  id={value}
                  disableRipple
                  checked={options?.length ? !unSelectedItems?.length : false}
                  onChange={(e) => selectAll(e)}
                  disabled={!options?.length}
                />
              }
              label={<div className="chip-selector-selector-all">Select All</div>}
            />
            {/* <div className="select_box_container">
            {searchResult.length ? (
              <select
                onChange={(e) => {
                  onSelectChange(e);
                }}
                value={filterSelected}
                // disabled={options.length === filterSelected[0]?.value.length}
              >
                <option value="include">Include Only</option>
                <option value="exclude">Exclude Only</option>
                <option value="Clear">Clear All</option>
              </select>
            ) : null}
          </div> */}
          </div>
        </div>
      ) : null}
      <div
        className={`multi-select-dropdown flex flex-col ${
          !searchResult?.length && "justify-center items-center relative"
        }`}
      >
        {searchResult?.length ? (
          renderCheckBoxes()
        ) : (
          <div className=" noDataWrapper display_flex gap-y-1 flex-col w-[100%] m-auto no-item-selected-list">
            <img src={NotFoundImage} alt="warning-icon" className="noDataIcon" />
            <p className="no-data-label">
              {noDataFound?.noDataText
                ? noDataFound?.noDataText
                : "No slack group matched your search"}
            </p>
            <p className="no-data-label">
              {noDataFound?.noDataLabel
                ? noDataFound?.noDataLabel
                : "Try another search, or add them to the space"}
            </p>
          </div>
        )}
      </div>

      {children}
    </div>
  );
}
