import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover } from "@material-ui/core";
// import SearchBar from "../../FormConfig/Form/Components/SearchBar";
import "./style.scss";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
  },
}));

function ListPopup(props) {
  const {
    data = [],
    dataCount = 2,
    showTags = false,
    shouldSort = true,
    endDate = "",
    popupClassName,
    defaultDisplayText = "- - -",
    method,
    defaultAccountId = false,
  } = props;
  let listData = shouldSort ? data?.sort() : data;
  if (method) {
    listData = method(listData);
  }
  // const [list, setList] = useState(listData);
  let list = listData;
  // const [searchTerm, setSearchTerm] = useState("");
  const displayText = listData.slice(0, dataCount);
  list = list?.length > dataCount ? list.slice(dataCount, list.length) : list;
  const showMore = useRef();

  const [openedPopover, setOpenedPopover] = useState(false);
  const [verticalOrigin, setVerticalOrigin] = useState("top");

  const popoverAnchor = useRef(null);

  const popoverEnter = (e) => {
    const cursorY = e.clientY;
    const windowHeight = window.innerHeight;
    const bottomThreshold = windowHeight * 0.5;
    if (cursorY >= bottomThreshold) {
      setVerticalOrigin("bottom");
    }

    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const classes = useStyles();

  // const listCopy = [...listData];

  // const onSearchHandler = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   const filteredList = listCopy.filter((item) => item.includes(value));
  //   setList(filteredList);
  //   setSearchTerm(value);
  // };

  const getDisplayText = () => {
    if (displayText?.length > 0) {
      return defaultAccountId ? (
        <>
          {displayText?.[0] || null}
          <i className="text-[#898A90] font-medium">(Default)</i>
          {displayText?.length > 1 && `, ${displayText?.slice(1, displayText?.length)?.join(", ")}`}
        </>
      ) : (
        displayText?.join(", ")
      );
    }

    return "- - -";
  };

  const showMoreStr = `+${listData.length - dataCount} ${showTags ? " More" : ""}`;
  return (
    <div className="list-popup-wrapper">
      {listData.length ? (
        <>
          {showTags ? (
            <>
              {displayText.map((item) => (
                <p className="tags">
                  <span title={item}>{item}</span>
                </p>
              ))}
            </>
          ) : (
            <span className="display-text">{getDisplayText()}</span>
          )}
          <div className="pop-up">
            <span
              ref={popoverAnchor}
              aria-owns="mouse-over-popover"
              aria-haspopup="true"
              onMouseEnter={popoverEnter}
              onMouseLeave={popoverLeave}
            >
              {listData?.length > dataCount && (
                <span
                  ref={showMore}
                  className={`show-more ${endDate && "!bg-[#525252]"} ${
                    showTags && "show-more-revamp"
                  }`}
                >
                  {showMoreStr}
                </span>
              )}
            </span>
            <Popover
              id="mouse-over-popover"
              className={`${classes.popover} ${popupClassName} ${
                verticalOrigin === "bottom" ? "bottom_pop" : ""
              }`}
              classes={{
                paper: classes.popoverContent,
              }}
              open={openedPopover}
              anchorEl={popoverAnchor.current}
              anchorOrigin={{
                vertical: verticalOrigin,
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: verticalOrigin,
                horizontal: "left",
              }}
              PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
            >
              <div className="py-1.5">
                {/* <SearchBar searchTerm={searchTerm} onChangeHandler={onSearchHandler} placeholder="" /> */}
                <ul className="account-list mx-2 px-2.5">
                  {list
                    .map((item, index) => {
                      if (index > 1) {
                        return <li className="my-2 text-xs">{item}</li>;
                      }
                      // return searchTerm ? <li className="my-2 text-xs font-semibold">{item}</li> : null;
                      return <li className="my-2 text-xs">{item}</li>;
                    })
                    .filter((item) => item)}
                </ul>
              </div>
            </Popover>
          </div>
        </>
      ) : (
        <span className="ml-[2px] text-[11px] font-normal">{defaultDisplayText}</span>
      )}
    </div>
  );
}

export default ListPopup;
