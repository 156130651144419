import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import calendarIcon from "../../../../../Assets/icons/calender.svg";
import calendarSelectedIcon from "../../../../../Assets/icons/date-selected.svg";
import "./style.scss";
import "bootstrap-daterangepicker/daterangepicker.css";
import Text from "../Text";
import useDetectOutsideClick from "../../../../../CustomHooks/useDetectOutsideClick";
import { noop } from "../../../../../Utils/commonUtils";
import "../../../../CK-Lens/Common/dateRangePicker/dateRangePickerV2.scss";
import "../../../../CK-Lens/Dashboard/CostExplorer/style.scss";

function DateTime({
  toggleHandler,
  value,
  topLabel,
  error,
  className,
  placeHolder,
  handleChange,
  name,
  collapseState,
  touched,
  onBlurHandler = noop,
  icon,
  disabled = false,
  openPosition = "left",
  dropsProps = "down",
  buttonClass = "",
  minDate,
  maxDate,
}) {
  const [close, setClose] = useState(collapseState);
  const dateRef = useRef();

  useEffect(() => {
    dateRef?.current?.setStartDate(moment(value));
    dateRef?.current?.setEndDate(moment(value));
  }, [value]);

  const ref = useRef();

  const toggleCalenderDropdown = () => {
    setClose(!close);
  };

  const handleEvent = (event, picker) => {
    handleChange({ target: { value: moment(picker.startDate._d), name } });
    toggleCalenderDropdown();
  };

  const onCancelHandler = () => {
    setClose(true);
  };

  useDetectOutsideClick(ref, (e) => {
    e.stopPropagation();
    if (!close) {
      setClose(true);
      onBlurHandler({ target: { value, name } });
    }
  });

  const getIconSrc = () => {
    if (icon) {
      return icon;
    }
    return value && !disabled ? calendarSelectedIcon : calendarIcon;
  };

  const returnParent = () => `.singleDateModule_${Math.floor(Math.random() * 10)}`;

  const returnMaxDate = () => {
    if (maxDate) {
      return moment(maxDate);
    }
    return moment();
  };

  const returnMinDate = () => {
    if (minDate) {
      return moment(minDate);
    }
    return moment();
  };

  return (
    <div className={`calender-wrapper ${className}`} id="cld_datepicker" ref={ref}>
      <DateRangePicker
        ref={dateRef}
        initialSettings={{
          singleDatePicker: true,
          startDate: { value },
          showDropdowns: true,
          opens: openPosition,
          locale: {
            daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            monthNames: moment.months(),
          },
          alwaysShowCalendars: true,
          linkedCalendars: false,
          parentEl: returnParent(),
          autoApply: false,
          drops: dropsProps,
          timePicker: true,
          timePicker24Hour: true,
          timePickerSeconds: false,
          maxDate: returnMaxDate(),
          minDate: returnMinDate(),
        }}
        onApply={handleEvent}
        onCancel={onCancelHandler}
        onEvent={noop}
        onCallback={noop}
      >
        <div className={`calender ${disabled ? "disabled" : ""}`}>
          {topLabel && (
            <Text
              text={topLabel.text}
              size={topLabel.fontSize}
              className={topLabel.class}
              type="primary"
            />
          )}

          <div
            onClick={toggleCalenderDropdown}
            aria-hidden="true"
            className={`header ${buttonClass}  ${touched && error && !disabled && "error_box"} ${
              value && "filled"
            } ${!close && "dropdown"}`}
          >
            <span>{disabled ? placeHolder : value || placeHolder}</span>
            <img
              aria-hidden="true"
              onClick={toggleHandler}
              src={getIconSrc()}
              alt={value && !disabled ? "calender selected icon" : "calender icon"}
            />
          </div>
        </div>
      </DateRangePicker>
    </div>
  );
}

export default DateTime;
