import React from "react";
import "./style.scss";
// import serviceUnavailableLogo from "../../../Assets/icons/ic-503.png";
import { strapiBasePath } from "../../../Services/Strapi/urls";

function InternalError503(props) {
  const { strapiData } = props;
  return (
    <div className="page-not-found errorPageNotFound">
      <div className="blurbg" />
      <div className="not-foundIcon">
        <img src={`${strapiBasePath}${strapiData?.logo?.data?.attributes?.url}`} alt="logo" />
      </div>
      <div className="internalError-heading">
        <h3 className="error-heading">{strapiData?.description}</h3>
      </div>
      <div className="usefullLinks-container">
        <div className="internalError-texts">
          <p>{strapiData?.subdescription}</p>
        </div>
        {/* <div className="error-button-wrapper">
          <a href="/dashboard/billingSummary" className="internalError-button">
            Back to home
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default InternalError503;
