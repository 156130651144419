import React from "react";
import { NO_ACCESS_MESSAGE } from "../../../Constants/permissions";
import ComponentHeader from "../../CK-Lens/Common/ComponentHeader";
import "./style.scss";

function AccessDeniedMessage(props) {
  const { breadcrumsData = [], selectedUrl = "", headerName = "" } = props;
  return (
    <>
      {!!breadcrumsData?.length && (
        <ComponentHeader
          breadcrumsData={breadcrumsData}
          selectedUrl={selectedUrl}
          customClass="!border-b-0 !pb-[0px]"
          headerName={headerName}
        />
      )}
      <div className="flex justify-center items-center sticky top-1/2 no-access-message">
        <span className="flex justify-center">{NO_ACCESS_MESSAGE}</span>
      </div>
    </>
  );
}

export default AccessDeniedMessage;
