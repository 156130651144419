/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, Fragment } from "react";
import "./style.scss";
import { useNavigate } from "react-router";
import cloneDeep from "lodash.clonedeep";
import { Checkbox } from "@mui/material";
import { noop, isBilldeskModule } from "../../../Utils/commonUtils";
import { ReactComponent as SortedIcon } from "../../../Assets/icons/sortingIcon.svg";
import { ReactComponent as InfoIcon } from "../../../Assets/images/ic-info.svg";
import Loader from "../Loader";
import TableFilter from "../FilterMultiselect/TableFilter";
import SearchBar from "../../FormConfig/Form/Components/SearchBar";
import Button from "../../FormConfig/Form/Components/Button";
import {
  applyFilters,
  cancelFilterHandler,
  createTableFiltersUpdated,
  disableHandling,
  handleFilters,
  OnIncludeChange,
  onRangeChange,
  sortedData,
  onDateChangeFilter,
} from "../../../Utils/filterUtils";
import { ResetIcon } from "../../../Constants/icons";
import {
  getSortIcon,
  returnAddCartCase,
  returnAddCase,
  returnAgreementCase,
  returnApplyCase,
  returnCalenderCase,
  returnCancelCase,
  returnChangePlanCase,
  returnCloneCase,
  returnDeleteCase,
  returnEditCase,
  returnEditRevampCase,
  returnEndCase,
  returnInputCase,
  returnPreviewCase,
  returnPromoteCase,
  returnPromoteRevampCase,
  returnRefreshCase,
  returnResendVerification,
  returnResendVerificationRevampCase,
  returnSaveCase,
  returnToggleCase,
  returnCopyCase,
  getClassName,
  returnDateTimeCase,
} from "./utils";
import ColumnSelector from "../Multiselect/columnSelector";
import { addDataToDb, getDataFromDb, openDB } from "../../../Utils/indexedDBUtils";
import { PERMISSIONS_ENUM, TABLE_ICONS_PERMISSIONS } from "../../../Constants/permissions";
import AccessDeniedMessage from "../AccessDeniedMessage";
// eslint-disable-next-line import/no-cycle
import { getDownloadXLSButton } from "../../Dashboard/CustomerManagement/CreateCustomer/utils";
import BasicSelect from "../../FormConfig/Form/Components/DropDown";

function FilterTable(props) {
  const navigate = useNavigate();
  const {
    config: { onIconClick, currentParent = {} },
    config,
    hasPermission = noop,
    disableFunctionality = false,
    disableResetButton,
    isLoading,
    searchEnabled,
    searchHandler,
    searchTerm,
    removeTablePadding = false,
    tableStyle,
    wrapperClass,
    heading,
    btnArray,
    // hasUserPermission,
    renderTooltipHTML,
    onAddCart,
    onInputChange,
    filterSorting = true,
    disableHeadersClass = "",
    noDataHTML = null,
    asChild,
    addDataToDB,
    appliedFilters,
    deleteData,
    updateParentFilterHandler,
    tableWrapperClass = "",
    columnSelector = false,
    min = Number.NEGATIVE_INFINITY,
    selectedTab = "ec2",
    columnSelectorKey,
    minDate,
    showFooter = false,
    footerConfig,
    V2 = false,
    filterRefClass = "filter_div",
    tableHeading = "",
    tableHeadingStyle = "",
    TopRightPlacementComponent = null,
    searchPlaceHolder = "",
    handleChange,
    createFiltersFromWebWorker = false,
    rowRenderKey = "",
    tableHeadingId = "",
    allowCalendarFilter = false,
    mouseOverFilter = false,
    showClearFilterSeparately = false,
    viewportAwareDatePopover = false,
    stickyHeader = "",
    maxTableHeight = "",
    filterTableConfiguration = null,
    showReset = true,
    singleLevelSelectAll = false,
    dntShowSelector = false,
    filterWrapperClass = "",
  } = props;

  const hasReadPermission = hasPermission(PERMISSIONS_ENUM.READ);
  const hasWritePermission = hasPermission(PERMISSIONS_ENUM.WRITE);
  const hasDeletePermission = hasPermission(PERMISSIONS_ENUM.DELETE);

  const [upadteConfig, setUpdatedConfig] = useState(cloneDeep(config));
  const [filters, setFilters] = useState([]);
  const [ascending, setAscending] = useState(true);

  const [filtersCopy, setFiltersCopy] = useState([]);
  const [filterSelected, setFilterSelected] = useState([]);
  const [data, setData] = useState(config.data);
  const [dataCopy, setDataCopy] = useState([]);
  const [selectedSortHeader, setSelectedSortHeader] = useState("");
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [resetClicked, setResetClicked] = useState(false);

  const filterData = (arrayObj) => {
    const newData = applyFilters(dataCopy, filtersCopy, arrayObj || filterSelected);
    const updatedUserList = { ...data };
    updatedUserList.data = newData?.filteredData;
    setData(updatedUserList.data);
    setFilterSelected(newData?.filters);
    setFilters(newData?.filters);
    if (addDataToDB) {
      addDataToDB(newData?.filters);
    }
    if (updateParentFilterHandler) {
      updateParentFilterHandler(newData?.filters);
    }
  };

  const onIncludeChange = (name, value) => {
    const includeChangeValue = OnIncludeChange(name, value, filterSelected, filters);
    setFilterSelected([...includeChangeValue]);
    if (updateParentFilterHandler) {
      updateParentFilterHandler([...includeChangeValue]);
    }
  };

  const createFilters = async (updatedData) => {
    try {
      const updatedFilters = createFiltersFromWebWorker
        ? await createTableFiltersUpdated(
            updatedData,
            createFiltersFromWebWorker,
            allowCalendarFilter
          )
        : createTableFiltersUpdated(updatedData, createFiltersFromWebWorker, allowCalendarFilter);

      setFilters(updatedFilters);
      setFiltersCopy(updatedFilters);
      setFilterSelected(updatedFilters);
    } catch (error) {
      console.error("Error creating filters:", error);
    }
  };

  const getFilteredHeadersForColumnSelector = (headers, selectedIndexDbHeaders = []) => {
    const updatedHeaders = headers.filter(
      (item) =>
        selectedIndexDbHeaders.includes(item.accessor) ||
        item?.props?.doNotAddColumnSelector ||
        item?.props?.disabledMenuItem
    );
    return updatedHeaders;
  };

  const restructureHeaders = (configurationHeaders, configureChildHeaders) => {
    const updatedColumnHeaders = [];
    const dbOpen = openDB();
    if (dbOpen) {
      getDataFromDb(columnSelectorKey, (d) => {
        const obj = { ...d };
        configurationHeaders.forEach((item) => {
          if (!item?.props?.doNotAddColumnSelector) {
            updatedColumnHeaders.push({
              id: item.accessor,
              name: item.Header,
              checked: obj[selectedTab]?.columHeaders?.includes(item.accessor) || false,
              disabledMenuItem: item?.props?.disabledMenuItem || false,
            });
          }
        });
        if (configureChildHeaders) {
          configureChildHeaders.forEach((item) => {
            if (!item?.props?.doNotAddColumnSelector) {
              updatedColumnHeaders.push({
                id: item.accessor,
                name: item.Header,
                checked: obj[selectedTab]?.columHeaders?.includes(item.accessor) || false,
                disabledMenuItem: item?.props?.disabledMenuItem || false,
              });
            }
          });
        }
        const configClone = cloneDeep(config);
        setUpdatedConfig({
          ...config,
          headers: getFilteredHeadersForColumnSelector(
            configClone.headers,
            obj[selectedTab]?.columHeaders
          ),
          ...(configClone.innerChildHeaders && {
            innerChildHeaders: getFilteredHeadersForColumnSelector(
              configClone.innerChildHeaders,
              obj[selectedTab]?.columHeaders
            ),
          }),
        });
        setColumnHeaders(updatedColumnHeaders);
      });
    }
  };

  useEffect(() => {
    createFilters(config.data);
    setDataCopy(config.data);
    setData(config.data);
    if (columnSelector) {
      restructureHeaders(config.headers);
      if (Object.keys(currentParent).length === 0) {
        restructureHeaders(config?.headers, config?.innerChildHeaders);
      }
    } else {
      setUpdatedConfig(config);
    }
    if (appliedFilters?.length > 0) {
      const newData = applyFilters(config.data, appliedFilters, appliedFilters);
      const updatedUserList = { ...data };
      updatedUserList.data = newData?.filteredData;
      setData(updatedUserList.data);
      setFilterSelected(appliedFilters);
      setFilters(appliedFilters);
    }
    // restructureHeaders(config.headers);
  }, [config.data, config.headers, resetClicked]);

  const handleFiltersTable = (value, name) => {
    const updatedFilterSelected = handleFilters(value, name, filterSelected, filters);
    setFilterSelected(updatedFilterSelected);
  };

  const onRangeChangeTable = (value, name) => {
    const arrayObj = onRangeChange(value, name, filterSelected);
    setFilterSelected(arrayObj);
    filterData(arrayObj);
  };

  const onDateChangeTable = (startDate, endDate, name) => {
    const arrayObj = onDateChangeFilter(startDate, endDate, name, filterSelected);
    setFilterSelected(arrayObj);
    filterData(arrayObj);
  };

  const cancelFilter = (selectedList) => {
    const newFilterSelected = cancelFilterHandler(filterSelected, selectedList);
    setFilterSelected(newFilterSelected);
    setFilters(newFilterSelected);
  };

  const sortHandler = (sortBy, dateFormat = "DD/MM/YYYY HH:mm A", item = "") => {
    const updatedData = sortedData(sortBy, data, ascending, dateFormat, item);
    setData(updatedData);
    setSelectedSortHeader(sortBy);
    setAscending(!ascending);
  };

  const tooltipTop = {
    "& .MuiTooltip-tooltip": {
      padding: "4px",
      fontSize: "9px",
      backgroundColor: "grey",
    },
    "& .MuiTooltip-arrow": {
      "&:before": {
        backgroundColor: "grey",
      },
    },
  };
  const showSortIcon = (arr, key) => {
    const firstEle = arr[0];
    return arr.every((v) => {
      const value = v[key];
      return value === firstEle[key];
    });
  };

  const renderTableFilters = (item) => (
    <TableFilter
      filters={appliedFilters || filters}
      heading={item.accessor}
      filterType={item.filterType}
      disableResetButton={disableResetButton}
      filterSelected={filterSelected}
      handleFilters={handleFiltersTable}
      onIncludeChange={onIncludeChange}
      filterData={filterData}
      cancelFilter={cancelFilter}
      onRangeChange={onRangeChangeTable}
      showSortIcon={!showSortIcon(data, item.accessor)}
      filterRefClass={filterRefClass}
      onDateChange={onDateChangeTable}
      showClearFilterSeparately={showClearFilterSeparately}
      viewportAwareDatePopover={viewportAwareDatePopover}
    />
  );
  const renderSortFilter = (item) =>
    V2 ? (
      <>
        <span className="sorting-data">{item.Header}</span>
        <span
          className={`mr-[4px] !mb-[3px] absolute right-0 inline-flex ${
            mouseOverFilter ? "ck--filter-block" : ""
          }`}
        >
          {!item.hideFilters && renderTableFilters(item)}
          <span
            onClick={() => sortHandler(item.sortKey || item.accessor, item?.dateFormat, item)}
            aria-hidden
            className="cursor-pointer"
          >
            {item.sortedIcon &&
              getSortIcon(
                item?.sortKey === selectedSortHeader || item?.accessor === selectedSortHeader,
                ascending
              )}
          </span>
        </span>
      </>
    ) : (
      <span id="header_name">
        <span className="sorting-data">{item.Header === "Email" ? "Email ID" : item.Header}</span>
        {item.sortedIcon && filterSorting && (
          <span className={`show-sort-filter ${disableHeadersClass}`}>
            {item.sortedIcon && !showSortIcon(data, item.accessor) && (
              <SortedIcon
                onClick={() => sortHandler(item.sortKey || item.accessor, item?.dateFormat, item)}
              />
            )}
            {item.Header !== "Last Login" &&
              item.Header !== "Sign Up Date" &&
              item.Header !== "Last Month Bill" &&
              item.Header !== "Last 3 Month Average" &&
              item.Header !== "Account Creation DateTime" &&
              renderTableFilters(item)}
          </span>
        )}
      </span>
    );

  const getIconsDefaultCase = (icon, tableBody, index) => {
    let component = null;
    switch (icon.actionType) {
      case "changePlan":
        component = returnChangePlanCase(icon, tooltipTop, tableBody);
        break;
      case "showAgreement":
        component = returnAgreementCase(icon, tooltipTop, tableBody, onIconClick);
        break;
      case "refresh":
        component = returnRefreshCase(tableBody, icon, onIconClick);
        break;
      case "editRevamp":
        component = returnEditRevampCase(icon, tooltipTop, tableBody, index, onIconClick);
        break;
      case "promoteRevamp":
        component = returnPromoteRevampCase(icon, tableBody, onIconClick, tooltipTop);
        break;
      case "resendVerificationRevamp":
        component = returnResendVerificationRevampCase(tableBody, icon, onIconClick, tooltipTop);
        break;
      case "apply":
        component = returnApplyCase(tableBody, icon, onIconClick, tooltipTop);
        break;
      default:
        break;
    }
    return component;
  };

  const getIcons = (icon, tableBody, index, permissions) => {
    let component = null;
    if (!permissions[TABLE_ICONS_PERMISSIONS[icon?.actionType]]) {
      return component;
    }
    switch (icon.actionType) {
      case "toggle":
        component = returnToggleCase(icon, hasWritePermission, tooltipTop, tableBody, onIconClick);
        break;
      case "delete":
        component = returnDeleteCase(icon, tooltipTop, tableBody, onIconClick, index);
        break;
      case "edit":
        component = returnEditCase(icon, tooltipTop, tableBody, index, onIconClick);
        break;
      case "cancel":
        component = returnCancelCase(icon, tooltipTop, tableBody, onIconClick);
        break;
      case "save":
        component = returnSaveCase(icon, tooltipTop, tableBody, onIconClick);
        break;
      case "add":
        component = returnAddCase(icon, tooltipTop, tableBody, onIconClick);
        break;
      case "end":
        component = returnEndCase(icon, tooltipTop, tableBody, onIconClick);
        break;
      case "promote":
        component = returnPromoteCase(icon, tableBody, onIconClick);
        break;
      case "resendVerification":
        component = returnResendVerification(tableBody, icon, onIconClick, tooltipTop);
        break;
      case "input":
        component = returnInputCase(onInputChange, tableBody, min, true);
        break;
      case "addCart":
        component = returnAddCartCase(icon, tableBody, onInputChange, onAddCart);
        break;
      case "clone":
        component = returnCloneCase(tableBody, tooltipTop, icon, onIconClick);
        break;
      case "preview":
        component = returnPreviewCase(tableBody, tooltipTop, icon, onIconClick);
        break;
      case "calender":
        component = returnCalenderCase(tableBody, onIconClick, icon, minDate);
        break;
      case "date_time_calender":
        component = returnDateTimeCase(tableBody, onIconClick, icon, minDate);
        break;
      case "copy":
        component = returnCopyCase(tableBody, tooltipTop, onIconClick, icon);
        break;
      default:
        component = getIconsDefaultCase(icon, tableBody, index);
        break;
    }
    return component;
  };

  const updateData = (updatedRow, id) => {
    const updatedData = data.map((row) => {
      if (row.id === id) {
        return updatedRow;
      }
      return row;
    });
    setData(updatedData);
  };

  const renderBodyIcons = (tableBody, icons, index) => {
    const permissionsMap = {
      WRITE: hasWritePermission,
      READ: hasReadPermission,
      DELETE: hasDeletePermission,
    };
    const iconList = icons?.map((icon) => getIcons(icon, tableBody, index, permissionsMap));
    return iconList;
  };

  const tableHeaders = upadteConfig.headers?.filter((header) => {
    if (header.accessor === "actions") {
      if (header?.overwritePermissions) {
        return true;
      }
      if (!hasWritePermission && !hasDeletePermission) {
        const firstRow = upadteConfig?.data?.[0];
        // check for show or not
        if (isBilldeskModule() && firstRow) {
          const actionArray = renderBodyIcons(firstRow, firstRow?.[header.accessor], 0);
          const allNull = actionArray?.every((value) => value === null);
          return !allNull;
        }
        return false;
      }
    }
    return true;
  });

  const checkChecked = () => {
    const checked = data.every(
      (item) => dataCopy?.find((row) => row?.id === item?.id)?.checked === true
    );
    return checked;
  };

  const handleAll = (event) => {
    const {
      target: { checked },
    } = event;

    const updatedDataCopy = dataCopy.map((item) => {
      if (data.find((row) => row.id === item.id)) {
        return { ...item, checked };
      }
      return item;
    });
    setDataCopy(updatedDataCopy);
  };

  const renderHeader = () => {
    const calcCheck = () => {
      if (currentParent && currentParent.childAccounts) {
        return currentParent.childAccounts
          .filter((item) => item.status === "Active" && !item.endDate)
          .every((item) => item.checked === true);
      }

      if (!data || data.length === 0) {
        return false;
      }

      const filteredData = data.filter((item) =>
        item?.childAccounts
          ? item?.childAccounts.some((child) => child?.status === "Active" && !child?.endDate)
          : item?.status === "Active"
      );

      if (filteredData.length === 0) {
        return false;
      }
      return filteredData.every((item) => item.checked === true);
    };

    const headers = [];
    if (Array.isArray(tableHeaders) && tableHeaders.length) {
      for (let i = 0; i < tableHeaders.length; i += 1) {
        const item = tableHeaders[i];
        if (!item.noRender) {
          headers.push(
            <th
              key={item.accessor + item.icons}
              className={`text-center ${item.headerClass || ""} ${
                item.Header === "Actions" ? "w-[10%]" : ""
              } ${item?.customClass} `}
            >
              {item.accessor === "checkboxId" && item?.isSelectAll === true ? (
                <div className="text-field flex items-center select-all-checkbox">
                  <Checkbox
                    name="selectAll"
                    className={singleLevelSelectAll ? "table_checkbox" : ""}
                    checked={singleLevelSelectAll ? checkChecked() : calcCheck()}
                    disabled={currentParent?.childAccounts?.every(
                      (child) => child?.endDate || child?.status !== "Active"
                    )}
                    size="small"
                    onChange={(event) => {
                      if (singleLevelSelectAll) {
                        handleAll(event);
                      } else {
                        handleChange(
                          event,
                          "selectAll",
                          currentParent?.childAccounts || data,
                          currentParent?.id,
                          true,
                          true
                        );
                      }
                    }}
                  />
                </div>
              ) : (
                <>
                  {item?.subLegend && (
                    <div className={`sub-legends-wrapper ${item?.childClass}`}>
                      <div className="sub-legends-content-wrapper">
                        <span>{item?.subLegend}</span>
                        <div className="horizontal-line" />
                      </div>
                    </div>
                  )}
                  {renderSortFilter(item)}
                </>
              )}
            </th>
          );
        }
      }
    }
    return headers;
  };

  const renderSearchBar = () => (
    <SearchBar
      searchTerm={searchTerm}
      onChangeHandler={searchHandler}
      placeholder={searchPlaceHolder}
      styleSearch=" !mt-0 !mx-0"
    />
  );

  const renderCellValue = (tableHeader, tableBody) => {
    if (tableHeader?.tooltip) {
      return (
        <span className="filterTable_tooltip">
          {tableBody[tableHeader.accessor]}
          <div className="infoTooltip">
            <span>
              <InfoIcon />
            </span>
            {renderTooltipHTML && renderTooltipHTML(tableBody)}
          </div>
        </span>
      );
    }

    if (tableHeader?.checkBox) {
      return (
        <Checkbox
          name="name"
          className="table_checkbox"
          checked={dataCopy?.find((item) => tableBody?.id === item?.id)?.checked}
          size="small"
          onChange={() => {
            setDataCopy((prevList) => {
              const updatedItems = prevList.map((item) =>
                item.id === tableBody?.id ? { ...item, checked: !item?.checked } : item
              );
              return [...updatedItems];
            });
          }}
        />
      );
    }
    if (tableHeader?.render) {
      return tableHeader?.render(tableBody);
    }
    return tableBody[tableHeader.accessor];
  };

  const renderValue = (tableHeader, tableBody) =>
    renderCellValue(tableHeader, tableBody) === 0
      ? 0
      : renderCellValue(tableHeader, tableBody) || <span className="text-center">- - -</span>;

  const renderComponent = (tableHeader, tableBody) =>
    tableHeader.component ? (
      <tableHeader.component
        data={tableBody[tableHeader.accessor]}
        tableRow={tableBody}
        header={tableHeader.Header}
        handleEditclick={(value) => onIconClick("cell_edit", value, tableBody)}
        updateData={updateData}
        id={tableBody.id}
        {...(tableHeader?.props || {})}
      />
    ) : (
      renderValue(tableHeader, tableBody)
    );
  const getColumnData = (tableHeader, tableBody) =>
    tableHeader?.customComponent ? (
      <tableHeader.component
        data={tableBody[tableHeader.accessor]}
        tableRow={tableBody}
        header={tableHeader.Header}
        updateData={updateData}
        id={tableBody?.id}
        // handleEditclick={(value) => onIconClick("cell_edit", value, tableBody)}
        {...(tableHeader?.props || {})}
      />
    ) : (
      renderComponent(tableHeader, tableBody)
    );

  const renderTableCells = (tableBody, index) => {
    const tableCells = [];
    if (Array.isArray(tableHeaders) && tableHeaders.length) {
      for (let i = 0; i < tableHeaders.length; i += 1) {
        const tableHeader = tableHeaders[i];
        if (!tableHeader?.noRender) {
          tableCells.push(
            <td
              key={tableHeader.accessor + tableHeader.icons}
              className={`text-center ${tableHeader.bodyClass || ""}`}
            >
              {tableHeader.icons ? (
                <div className="tableBodyIcons">
                  {renderBodyIcons(tableBody, tableBody[tableHeader.accessor], index)}
                </div>
              ) : (
                getColumnData(tableHeader, tableBody)
              )}
            </td>
          );
        }
      }
    }
    return tableCells;
  };

  const handleNestedFilterChange = (updatedFilters) => {
    setFilterSelected(updatedFilters);
  };

  const getTableRowTitle = (tableBody) => {
    if (
      isBilldeskModule() &&
      typeof tableBody?.status === "string" &&
      tableBody?.status?.toLowerCase() !== "active"
    ) {
      return "This record is inactive";
    }
    return "";
  };

  const renderBody = () =>
    data?.map((tableBody, index) => {
      let tableRowKey;
      if (rowRenderKey) {
        tableRowKey = `${tableBody[rowRenderKey]}`;
      } else {
        tableRowKey = `${tableBody?.id}_random_${Math.random() * 1000}`;
      }
      return (
        <>
          <tr
            title={getTableRowTitle(tableBody)}
            key={tableRowKey}
            className={getClassName(tableBody, disableFunctionality)}
          >
            {renderTableCells(tableBody, index)}
          </tr>
          {Array.isArray(tableBody.childAccounts) && tableBody?.expanded && (
            <tr>
              <td colSpan={tableHeaders.length} className="inner-table">
                {config?.additionalContent(tableBody)}
                <FilterTable
                  config={{
                    data: tableBody.childAccounts,
                    headers: upadteConfig.innerChildHeaders,
                    currentParent: tableBody,
                  }}
                  // hasPermission={hasPermission}
                  // selectedTab={selectedTab?.value}
                  handleChange={handleChange}
                  updateParentFilterHandler={handleNestedFilterChange}
                  filterRefClass="filter_div_1"
                />
              </td>
            </tr>
          )}
        </>
      );
    });

  const resetButton = () => {
    if (deleteData) {
      deleteData();
    }
    if (updateParentFilterHandler) {
      updateParentFilterHandler();
    }
    setData(dataCopy);
    createFilters(dataCopy);
    setResetClicked(!resetClicked);
  };

  // Function to set IndexDB column selector
  const setIndexDbColumnSelector = (updatedColumnHeaders) => {
    const dbOpen = openDB();
    if (dbOpen) {
      // Step 1: Retrieve data from IndexDB using columnSelectorKey
      getDataFromDb(columnSelectorKey, (d) => {
        // Step 2: Filter updatedColumnHeaders to get selected headers from column selector dropdown
        const selectedHeaders = updatedColumnHeaders
          .filter((item) => item?.checked)
          ?.map((item) => item.id);

        // Step 3: Create a copy of the retrieved data to avoid mutation and update with selected headers
        const obj = {
          ...d,
          [selectedTab]: { columHeaders: selectedHeaders },
        };

        // Step 4: Add updated data back to IndexDB with key "virtualRiColumnSelector"
        addDataToDb(columnSelectorKey, obj);
      });
    }
  };

  const setUpdatedHeaders = (list) => {
    const checkedIds = list.filter((item) => item.checked).map((item) => item.id);
    const checkedItems = config?.headers.filter(
      (item) =>
        item?.props?.doNotAddColumnSelector ||
        item?.props?.disabledMenuItem ||
        checkedIds.includes(item.accessor)
    );
    const newConfig = {
      ...config,
      headers: checkedItems,
      ...(config?.innerChildHeaders && {
        innerChildHeaders: config?.innerChildHeaders.filter(
          (item) =>
            item?.props?.doNotAddColumnSelector ||
            item?.props?.disabledMenuItem ||
            checkedIds.includes(item.accessor)
        ),
      }),
    };
    setIndexDbColumnSelector(list);
    setUpdatedConfig(newConfig);
    setColumnHeaders(list);
  };

  const onReset = () => {
    const dbOpen = openDB();
    if (dbOpen) {
      // Step 1: Retrieve data from IndexDB
      getDataFromDb(columnSelectorKey, (d) => {
        // Step 2: Get selected headers from table config
        const selectedHeaders = config?.headers
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor);

        const selectedInnerHeaders = config.headers
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor);

        // Step 3: Create an updated object with selected headers
        const obj = {
          ...d,
          [selectedTab]: { columHeaders: selectedHeaders },
        };

        // Step 4: Generate updated column headers
        const updatedColumnHeaders = [];
        config.headers.forEach((item) => {
          if (!item?.props?.doNotAddColumnSelector) {
            updatedColumnHeaders.push({
              id: item.accessor,
              name: item.Header,
              checked: obj[selectedTab]?.columHeaders?.includes(item.accessor) || false,
              disabledMenuItem: item?.props?.disabledMenuItem || false,
            });
          }
        });

        if (config.innerChildHeaders) {
          config?.innerChildHeaders?.forEach((item) => {
            if (!item?.props?.doNotAddColumnSelector) {
              updatedColumnHeaders.push({
                id: item?.accessor,
                name: item?.Header,
                checked: obj?.[selectedTab]?.innerChildHeaders?.includes(item?.accessor) || false,
                disabledMenuItem: item?.props?.disabledMenuItem || false,
              });
            }
          });
        }

        // Step 5: Update data in IndexDB
        addDataToDb(columnSelectorKey, obj);

        // Step 6: Update config with filtered headers for table
        setUpdatedConfig({
          ...config,
          headers: getFilteredHeadersForColumnSelector(config?.headers, selectedHeaders),
          ...(config?.innerChildHeaders && {
            innerChildHeaders: getFilteredHeadersForColumnSelector(
              config?.innerChildHeaders,
              selectedInnerHeaders
            ),
          }),
        });
        // Step 7: Set column headers state for column manager
        setColumnHeaders(updatedColumnHeaders);
      });
    }
  };

  const checkResetEnable = () => {
    const checkedColumnHeaderIds = columnHeaders
      .filter((item) => item.checked)
      .map((item) => item.id);

    const checkedConfigHeaderIds = config?.headers
      ? config?.headers
          .filter((item) => item?.props?.defaultSelectedHeader)
          ?.map((item) => item?.accessor)
      : [];

    return checkedColumnHeaderIds.sort().toString() === checkedConfigHeaderIds.sort().toString();
  };

  const renderColumnSelector = (showColumnSelector, show) => {
    if (showColumnSelector && show) {
      return (
        <ColumnSelector
          data={columnHeaders}
          searchable
          handleEditclick={setUpdatedHeaders}
          deleteChip={false}
          name="columnSelector"
          noDataMessage="No Record Found"
          placeholder="Column"
          columnManager
          onReset={onReset}
          resetEnable={checkResetEnable()}
        />
      );
    }
    return null;
  };

  const getFooter = () => {
    if (!(showFooter && footerConfig)) {
      return "";
    }
    const footerData = footerConfig(data);
    return (
      <tr className="table-body-row">
        {tableHeaders?.map((internalItem) => (
          <td className={`${footerData?.align || "!text-center"} !font-bold`}>
            {footerData?.[internalItem?.accessor] ? footerData?.[internalItem?.accessor] : ""}
          </td>
        ))}
      </tr>
    );
  };

  const renderReset = (showResetButton, configuration) =>
    showResetButton &&
    Object.keys(currentParent)?.length === 0 && (
      <span
        aria-hidden="true"
        className={`flex justify-end ${
          configuration?.class
        } gap-x-1 text-[#0A3CA2] text-xs items-center font-medium cursor-pointer pb-[4px] reset-icon-btn ${
          disableHandling(filterSelected) && "text-[#7c7d7e] pointer-events-none"
        }`}
        onClick={() => resetButton()}
      >
        <ResetIcon className="w-2 h-2" /> Reset Filters
      </span>
    );

  const renderButton = (configuration) => {
    let isDisabled = false;
    if (configuration?.isDisabledHandler) {
      isDisabled = configuration?.isDisabledHandler(dataCopy);
    }
    if (!configuration?.noRender) {
      return (
        <button
          type="button"
          className={`${configuration?.class} ${isDisabled && "disabled"}`}
          disabled={isDisabled}
          onClick={() => configuration?.onClick(configuration, dataCopy)}
        >
          {configuration.label}
        </button>
      );
    }
    return null;
  };

  const renderDropDown = (configuration) => {
    const { value, options, name, className, onChange = noop, placeHolder } = configuration;

    return (
      <BasicSelect
        name={name}
        value={value}
        className={className}
        options={options}
        handleChange={(e) => onChange(e)}
        placeHolder={placeHolder}
      />
    );
  };

  const renderComponentsFromType = (configuration) => {
    switch (configuration.type) {
      case "reset":
        return renderReset(configuration?.showReset, configuration);
      case "columnManager":
        return renderColumnSelector(configuration?.config?.columnSelector, true);
      case "downloadButton":
        return getDownloadXLSButton(config, configuration.url, configuration.class);
      case "button":
        return renderButton(configuration);
      case "dropdown":
        return renderDropDown(configuration);
      default:
        return null;
    }
  };

  return (
    <div className={`${V2 ? "table-wrapper-V2" : ""} ${filterWrapperClass}`}>
      {filterTableConfiguration && (
        <div className="table_configuration_wrapper">
          <div className="flex items-center">
            {filterTableConfiguration?.leftConfig?.map((item) => (
              <div className="flex items-center">{renderComponentsFromType(item)}</div>
            ))}
          </div>

          <div className="flex items-center">
            {filterTableConfiguration?.rightConfig?.map((item) => (
              <div className="flex">{renderComponentsFromType(item)}</div>
            ))}
          </div>
        </div>
      )}

      <div className="flex gap-x-2 reset-icon item-center">
        {hasWritePermission &&
          !asChild &&
          btnArray?.map((item) => (
            <Button
              variant={item?.variant}
              className={item.class}
              image={item?.image}
              text={item?.label}
              disabled={item?.disabled || false}
              handleClick={() => {
                if (item?.link) {
                  navigate(item?.link);
                } else {
                  item.handleClick();
                }
              }}
            />
          ))}
        {!!tableHeading && (
          <div className="relative pb-[6px]">
            <span className={(!!tableHeadingStyle && tableHeadingStyle) || ""} id={tableHeadingId}>
              {tableHeading}
            </span>
            <div className="absolute top-0 right-0 h-[20px] w-[1px] bg-gray-300" />
          </div>
        )}
        {filterSorting && hasReadPermission && !asChild && (
          <>
            {renderReset(showReset)}
            {TopRightPlacementComponent && hasDeletePermission && hasWritePermission && (
              <TopRightPlacementComponent />
            )}
          </>
        )}
        {renderColumnSelector(columnSelector, !dntShowSelector)}
      </div>
      {!hasReadPermission ? (
        <>
          {" "}
          <AccessDeniedMessage />
        </>
      ) : (
        <>
          {" "}
          {isLoading ? (
            <div className="relative mt-9 h-16">
              <Loader />
            </div>
          ) : (
            <div
              className={`table-container bg-white cld-Table ${
                removeTablePadding && "!p-0 !border-none"
              } ${
                tableStyle === "table-grid-ui style-dashboard-grid" ? "table-scrollbar" : ""
              } ${wrapperClass}`}
            >
              <p className="heading">{heading}</p>
              {searchEnabled && (
                <div className={`display_flex_between ${!removeTablePadding && "mb-2"}`}>
                  <div className="search-wrapper w-[45%]">{renderSearchBar()}</div>
                </div>
              )}
              <div
                className={`${tableWrapperClass} ${V2 ? "Striped-Table" : ""}`}
                style={
                  maxTableHeight
                    ? {
                        maxHeight: maxTableHeight,
                        overflow: "auto",
                        maxWidth: "fit-content",
                      }
                    : {}
                }
              >
                <table
                  className={` ${mouseOverFilter ? "ck-table--mouse-over-filter" : ""} table ${
                    removeTablePadding && "!rounded-none"
                  } ${tableStyle && tableStyle}`}
                >
                  <thead className={`${stickyHeader ? "ck-table-header-sticky" : ""}`}>
                    <tr className="table-header">{renderHeader()}</tr>
                  </thead>
                  {data?.length ? (
                    <>
                      <tbody>{renderBody()}</tbody>
                      {getFooter()}
                    </>
                  ) : (
                    <tbody className="relative h-48 w-20">
                      {noDataHTML ? (
                        noDataHTML()
                      ) : (
                        <div className="no_data_found">No Data Found</div>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default FilterTable;
