/* eslint-disable import/prefer-default-export */
export const rateConfigMapper = {
  public: "Private Rate",
  discount: "Discount on Public Rate",
};

export const tableHeaderMapper = {
  "Ad Insertion": "(Per 1000 ad)",
  "Channel Assembly - Basic": "(Per Hour)",
  "Channel Assembly - Standard": "(Per Hour)",
  "Ad Delivery": "(Per GB)",
  "HTTP Requests": "Per 10,000",
  "HTTPS Requests": "Per 10,000",
  "Data Transfer Out to Internet": "(Per GB)",
};

export const getFormConfig = (elementList, tabName) => {
  const elementCopy = [...elementList];
  if (tabName === "CloudFront") {
    const configurationObj = elementCopy?.find((item) => item?.name === "configure");
    configurationObj.enableAos = true;
    configurationObj.handleUpcomingRegions = true;
    const commitmentObj = elementCopy?.find((item) => item?.name === "configureCommitments");
    commitmentObj.units = true;
    return elementCopy;
  }

  const configurationObj = elementCopy?.find((item) => item?.name === "configure");
  configurationObj.enableAos = false;
  configurationObj.handleUpcomingRegions = false;
  const commitmentObj = elementCopy?.find((item) => item?.name === "configureCommitments");
  commitmentObj.units = false;

  if (tabName === "MediaLive") {
    return elementList?.map((item) => {
      if (item?.type === "configurableTable") {
        return {
          ...item,
          type: "configurableTableWithTabs",
          headers: [],
          tableData: [],
          showNoData: false,
          validations: {
            rules: {
              required: true,
            },
            messages: {
              required: "This field is required",
            },
          },
        };
      }
      return item;
    });
  }

  return [...elementList];
};
