import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import usePermission from "../Permissions";

const withRouter = (Component) => {
  function Wrapper(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [hasPermission, ignoreRules, setPermission] = usePermission();

    return (
      <Component
        navigate={navigate}
        hasPermission={hasPermission}
        ignoreRules={ignoreRules}
        state={location.state}
        location={location}
        setPermission={setPermission}
        {...props}
      />
    );
  }

  return Wrapper;
};

export default withRouter;
