export const combinedCheck = (formElement, value, values) => {
  let isEnabled = null;
  const targetIds = formElement?.enableDisable?.targetIds;
  const targetIdsLength = targetIds?.length;
  const combinedValue = [];

  if (targetIdsLength) {
    for (let i = 0; i < targetIdsLength; i += 1) {
      const currentTarget = targetIds[i];
      const formElemName = formElement?.name;
      const localValue = formElemName === currentTarget ? value : values[currentTarget];
      combinedValue.push(...(localValue?.length ? localValue : []));
    }
    isEnabled = combinedValue.length
      ? !combinedValue.some((fieldVale) => fieldVale[formElement.enableDisable.valueKey] === false)
      : false;
  } else {
    const isKeyExists = value.every((_) =>
      Object.hasOwnProperty.call(_, formElement.enableDisable.valueKey)
    );

    if (isKeyExists) {
      isEnabled = value?.length && value.every((_) => _[formElement.enableDisable.valueKey]);
    }
  }

  return isEnabled;
};

export const a = "sfsdffd";
